import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
  Autocomplete,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const EditInbound = ({
  open,
  setModalOpen,
  data,
  refetch,
  chemicals,
  suppliers,
}) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const initialDate = moment(data?.date).format("YYYY-MM-DD");
  const [date, setDate] = useState("");
  const [editedDate, setEditedDate] = useState("");
  const [chemical_name, setChemical_name] = useState("");
  const [supplier, setSupplier] = useState("");
  const [quantity, setQuantity] = useState("");
  const [disposition, setDisposition] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("0");

  // console.log(data);

  const selectDate = (date) => {
    // console.log(date);
    // const formattedDate = moment(date).format("DD/MM/YYYY");
    const selectedDate = moment(date).format("YYYY-MM-DD");
    setDate(selectedDate);
    setEditedDate(moment(date).format("DD/MM/YYYY"));
  };

  const handleClose = () => {
    setModalOpen(false);
    setDate("");
  };

  const handleSubmit = async () => {
    const editedData = {
      item_name: chemical_name !== "" ? chemical_name : data?.item_name,
      supplier: supplier !== "" ? supplier : data?.supplier._id,
      quantity: quantity !== "" ? quantity : data?.quantity,
      date: editedDate !== "" ? editedDate : data?.date,
      disposition: disposition !== "" ? disposition : data?.disposition,
      weight: weight !== "" ? weight : data?.weight,
      price: price !== "" ? price : data?.price,
      status: true,
    };

    await axiosInstance
      .put(`/api/inbounds/${data?._id}`, editedData, authentication)
      .then((res) => {
        // console.log(res);
        toast.success("Inbound data updated!");
        refetch();
        setModalOpen(false);
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong. Please try again.");
        console.log(error);
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => handleClose()}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Edit Inbound Data
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    id="date"
                    type="date"
                    label={"Production date"}
                    value={date !== "" ? date : initialDate}
                    onChange={(e) => selectDate(e.target.value)}
                    placeholder="Input the job order date"
                    InputProps={{
                      inputProps: { min: "2023-12-01", max: "2050-12-30" },
                    }}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="disposition"
                    label="Disposition"
                    placeholder={data?.disposition}
                    onChange={(e) => setDisposition(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={suppliers?.map((sup) =>
                      sup.name !== "" ? `${sup.name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      suppliers.filter((sup) =>
                        `${sup.name}` === newValue
                          ? setSupplier(sup._id)
                          : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data?.supplier?.name}`}
                        label="Supplier"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={chemicals?.map((cm) =>
                      cm.chemical_name !== "" ? `${cm.chemical_name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      chemicals.filter((cm) =>
                        `${cm.chemical_name}` === newValue
                          ? setChemical_name(cm.chemical_name)
                          : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data?.item_name}`}
                        label="Chemical Name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="qty"
                    label="Quantity"
                    type="text"
                    placeholder={data?.quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="weight"
                    label="Weight (kg)"
                    type="number"
                    placeholder={data?.weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <CustomTextField
                    name="total"
                    label="Total Weight (kg)"
                    type="text"
                    // placeholder={data?.quantity * data?.weight}
                    placeholder={
                      quantity !== "" && weight === ""
                        ? quantity * data?.weight
                        : quantity === "" && weight !== ""
                        ? data?.quantity * weight
                        : quantity !== "" && weight !== ""
                        ? quantity * weight
                        : data?.quantity * data?.weight
                    }
                    disabled
                    // onChange={(e) => setQuantity(e.target.value)}
                  />
                </Grid>
              </Stack>
              {/* <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <CustomTextField
                    name="price"
                    label="Price (Rp)"
                    type="text"
                    placeholder={data?.price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                onClick={() => handleSubmit()}
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default EditInbound;
