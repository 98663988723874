import axios from "axios";
import API_URL from "./base";

const axiosInstance = axios.create({ baseURL: API_URL, timeout: 30000 });

axiosInstance.interceptors.request.use((config) => {
  return config;
});

axiosInstance.interceptors.response.use((config) => {
  return config;
});

export default axiosInstance;
