import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Stack
      sx={{ background: "#FFF" }}
      color={"#222"}
      fontSize={1}
      justifyItems={"center"}
      textAlign={"center"}
      paddingBottom={1}
    >
      <Divider />
      <Typography fontSize={10} mt={1}>
        &copy; 2023 PT Perajutan Sahabat. All Rights Reserved.
      </Typography>
    </Stack>
  );
};

export default Footer;
