import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import CustomNoRowsOverlay from "../../components/NoRows";
import AddChemical from "./_components/AddChemical";
import ChemicalDetails from "./_components/ChemicalDetails";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "chemical_name",
    headerClassName: "header",
    headerName: "Chemical Name",
    width: 150,
    flex: 2,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "packaging",
    headerClassName: "header",
    headerName: "Packaging",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"uppercase"}>
        {value}
      </Typography>
    ),
  },
  {
    field: "shape",
    headerClassName: "header",
    headerName: "Form",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "type",
    headerClassName: "header",
    headerName: "Type",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "amount",
    headerClassName: "header",
    headerName: "Weight (kg)",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <button
          style={{
            color: "#21573D",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #B8DBCA",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Active
        </button>
      ) : (
        <button
          style={{
            color: "#444",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Non-active
        </button>
      ),
  },
  {
    field: "_id",
    headerClassName: "header",
    headerName: "Action",
    width: 100,
    flex: 1,
    renderCell: () => (
      <button
        style={{
          color: "#fff",
          // fontFamily: "Murecho",
          fontWeight: "600",
          fontSize: "12px",
          border: "1px solid #222",
          borderRadius: "4px",
          backgroundColor: "#222",
          padding: "5px",
          cursor: "pointer",
        }}
      >
        See Details
      </button>
    ),
  },
];

const aux = [
  {
    field: "chemical_name",
    headerClassName: "header",
    headerName: "Chemical Name",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "packaging",
    headerClassName: "header",
    headerName: "Packaging",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"uppercase"}>
        {value}
      </Typography>
    ),
  },
  {
    field: "shape",
    headerClassName: "header",
    headerName: "Form",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "amount",
    headerClassName: "header",
    headerName: "Weight (kg)",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <button
          style={{
            color: "#21573D",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #B8DBCA",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Active
        </button>
      ) : (
        <button
          style={{
            color: "#444",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Non-active
        </button>
      ),
  },
  {
    field: "_id",
    headerClassName: "header",
    headerName: "Action",
    width: 100,
    flex: 1,
    renderCell: () => (
      <button
        style={{
          color: "#fff",
          // fontFamily: "Murecho",
          fontWeight: "600",
          fontSize: "12px",
          border: "1px solid #222",
          borderRadius: "4px",
          backgroundColor: "#222",
          padding: "5px",
          cursor: "pointer",
        }}
      >
        See Details
      </button>
    ),
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChemicalList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [chemicalItem, setChemicalItem] = useState(null);
  // const navigate = useNavigate();
  // const [customers, setCustomers] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [value, setValue] = useState(0);
  const [dyestuff, setDyestuff] = useState([]);
  const [auxiliaries, setAuxiliaries] = useState([]);

  const separateChemical = (chemicals) => {
    setDyestuff(chemicals.filter((c) => c.category === "Dyestuff"));
    setAuxiliaries(chemicals.filter((c) => c.category === "Auxiliaries"));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(chemicals.data);

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        // console.log(res.data);
        separateChemical(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getChemicals();
  }, []);

  const onDetail = (dt) => {
    setChemicalItem(dt);
    setOpenModal(true);
  };

  return (
    <Box flex="1" sx={{ backgroundColor: "#f6f6f6" }}>
      <AddChemical
        open={modalOpen}
        setModalOpen={setModalOpen}
        getChemicals={getChemicals}
      />
      <ChemicalDetails
        open={openModal}
        setModalOpen={setOpenModal}
        data={chemicalItem}
        getChemicals={getChemicals}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Chemicals
        </Typography>
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Chemical Data
          </Button>
        )}
        {user.role === "Admin Warehouse" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Chemical Data
          </Button>
        )}
      </Stack>
      <Box sx={{ padding: 3 }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Dyestuffs"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
            <Tab
              label="Auxiliaries"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={dyestuff}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(rows) => onDetail(rows.row)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={auxiliaries}
                columns={aux}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(rows) => onDetail(rows.row)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ChemicalList;
