import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const AddCustomer = ({ open, setModalOpen, getCustomers }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const successEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      email: email,
      phone_number: phone,
      address: address,
      city: city,
    };

    axiosInstance
      .post("/api/customers", data, authentication)
      .then(() => {
        // console.log(res.data);
        successEffect.play();
        toast.success("New customer data added!");
        getCustomers();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Customer
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setModalOpen(false)}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        <form onSubmit={handleSubmit}>
          <Box flex="1">
            <Stack paddingX={2} paddingY={2}>
              <Stack gap={2}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      placeholder="Input a full name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="email"
                      label="Email"
                      placeholder="Input an email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="phone_number"
                      label="Phone"
                      placeholder="Input phone number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="city"
                      label="City"
                      placeholder="Input a city name"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Grid item xs={12}>
                  <CustomTextField
                    name="address"
                    label="Address"
                    type="text"
                    multiline
                    rows={4}
                    placeholder="Input an address"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: "#f6f6f6" }}>
            <Box
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "6px",
                    backgroundColor: "#FFF",
                    color: "#222",
                    border: "1px solid #222",
                    ":hover": {
                      backgroundColor: "#FFF",
                      border: "1px solid #222",
                    },
                  }}
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  disabled={
                    name === "" ||
                    email === "" ||
                    phone === "" ||
                    address === ""
                      ? true
                      : false
                  }
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      </ModalCustom>
    </>
  );
};

export default AddCustomer;
