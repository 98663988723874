import home from "./home";
import dashboard from "./dashboard";
import admin from "./admin";
import master from "./master";
import recipes from "./recipes";
import productions from "./productions";
import warehouse from "./warehouse";

const routes = [
  ...home,
  ...dashboard,
  ...admin,
  ...master,
  ...recipes,
  ...productions,
  ...warehouse,
];

export default routes;
