import { Alert, Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import successSound from "../assets/audio/success.mp3";
import errorSound from "../assets/audio/error.mp3";
import Loader from "./Loader";
import InternalLoader from "./Loader/internalLoader";

const FileUploader = ({
  open,
  setOpen,
  //   action,
  title,
  message,
  actionTitle,
  isWarning,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
    borderRadius: "5px",
    color: "#222",
    background: "#FFF",
    zIndex: "50",
    textAlign: "left",
    width: "350px",
  };

  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const errorEffect = new Audio(errorSound);
  const successEffect = new Audio(successSound);

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    setFile(null);
    setOpen(false);
  };

  const handleUpload = () => {
    setIsLoading(true);
    const data = new FormData();
    data.append("file", file);

    axiosInstance
      .post("/api/stocks/upload-file", data, authentication)
      .then(() => {
        // console.log(res);
        setIsLoading(false);
        successEffect.play();
        toast.success("Your file has been successfully uploaded!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        errorEffect.play();
        toast.error("Oops! Something's wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          {isLoading ? (
            <InternalLoader />
          ) : (
            <>
              <Stack height={60} p={2}>
                <Typography sx={{ mb: 1, fontWeight: 600 }}>{title}</Typography>
                <Typography fontSize={12}>{message}</Typography>
              </Stack>
              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <label htmlFor="profile_picture">
                    <input
                      style={{ display: "none" }}
                      id="profile_picture"
                      onChange={(e) => setFile(e.target.files[0])}
                      name="profile_picture"
                      type="file"
                      accept=".xlsx"
                    />
                    <Box
                      padding={3}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #6D7688",
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        color: "#6D7688",
                        marginBottom: "20px",
                        width: "500px",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#6D7688",
                          display: `${file != null ? "none" : null}`,
                          fontSize: "12px",
                        }}
                      >
                        Choose file
                      </Typography>
                      {file !== null ? (
                        <Stack
                          sx={{
                            padding: 1,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          gap={1}
                        >
                          <Typography fontSize="16px" fontWeight="500">
                            {file.name}
                          </Typography>
                          <Button
                            sx={{
                              backgroundColor: "#CC3A30",
                              color: "#fff",
                              textTransform: "capitalize",
                              height: "30px",
                              borderRadius: "5px",
                              fontSize: "12px",
                              ":hover": {
                                backgroundColor: "#444",
                              },
                            }}
                            onClick={() => onCancel()}
                            startIcon={<ClearIcon />}
                            //   disabled={file !== null ? false : true}
                          >
                            Remove file
                          </Button>
                        </Stack>
                      ) : null}
                    </Box>
                  </label>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  width: "full",
                  justifyContent: "flex-end",
                  display: "flex",
                  backgroundColor: "#f7f7f7",
                  padding: "10px 15px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#fff",
                    color: "#222",
                    textTransform: "capitalize",
                    height: "30px",
                    borderRadius: "8px",
                    border: "1px solid #222",
                    fontSize: "12px",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#fff",
                      border: "1px solid #222",
                    },
                  }}
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: isWarning ? "#CC3A30" : "#222",
                    color: "#fff",
                    textTransform: "capitalize",
                    height: "30px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    border: `1px solid ${isWarning ? "#CC3A30" : "#222"} `,
                    ":hover": {
                      backgroundColor: "#444",
                      border: "1px solid #444",
                    },
                  }}
                  onClick={() => handleUpload()}
                  //   disabled={file !== null ? false : true}
                >
                  {actionTitle}
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default FileUploader;
