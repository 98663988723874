import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "./routes/main";
import React, { useState, useEffect } from "react";
import LoginPage from "../src/pages/Login";
import moment from "moment";

function App() {
  const [login, setLogin] = useState({
    token: JSON.parse(localStorage.getItem("userToken")),
  });

  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
  });

  function checkStorage() {
    const loginDate = JSON.parse(localStorage.getItem("timeStamp"));
    const currentDate = new Date();
    const normalDate = moment(currentDate).format("LL");
    if (normalDate !== loginDate) {
      localStorage.removeItem("timeStamp");
      localStorage.removeItem("userToken");
    }
    return loginDate;
  }

  useEffect(() => {
    checkStorage();
    setLogin({ token: JSON.parse(localStorage.getItem("userToken")) });
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {login.token ? <Main /> : <LoginPage />}
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
