import React, {
  useState, // useState,
  // useEffect
} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const AddMachine = ({ open, setModalOpen, getMachines }) => {
  const [name, setName] = useState("");
  const [temperature, setTemperature] = useState("");
  const [model, setModel] = useState("");
  const [chamber, setChamber] = useState("");
  const [capacity, setCapacity] = useState("");
  const [year, setYear] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [dailyTarget, setDailyTarget] = useState("");
  const [maximumProduction, setMaximumProduction] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const handleClose = () => {
    setName("");
    setTemperature("");
    setModel("");
    setChamber("");
    setCapacity("");
    setYear("");
    setManufacturer("");
    setDailyTarget("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      capacity: capacity,
      chamber: chamber,
      machine_type: model,
      brand: manufacturer,
      name: name,
      daily_target: dailyTarget,
      objective: maximumProduction,
      temperature_max: temperature,
      year: year,
      status: true,
    };

    axiosInstance
      .post("/api/machines", data, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("New machine data added!");
        getMachines();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Machine
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setModalOpen(false)}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        <form onSubmit={handleSubmit}>
          <Box flex="1">
            <Stack paddingX={2} paddingY={2}>
              <Stack gap={2}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="name"
                      label="Machine name"
                      placeholder="Input the name of the machine"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="temperature"
                      label="Max. temperature"
                      placeholder="Input the max. temperature"
                      onChange={(e) => setTemperature(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="model"
                      label="Model"
                      placeholder="Input the model of the machine"
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="chamber"
                      label="Chamber"
                      placeholder="Input the quantity of the chamber"
                      onChange={(e) => setChamber(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="capacity"
                      label="Capacity"
                      placeholder="Input the capacity of the machine"
                      onChange={(e) => setCapacity(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="daily_target"
                      label="Daily target"
                      placeholder="Input the amount of daily target (kg)"
                      onChange={(e) => setDailyTarget(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="maximum_production"
                      label="Maximum production"
                      placeholder="Input the amount of daily target (kg)"
                      onChange={(e) => setMaximumProduction(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="manufacturer"
                      label="Manufacturer"
                      placeholder="Input the maximum production of the machine"
                      onChange={(e) => setManufacturer(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="year"
                      label="Year"
                      placeholder="Input the purchasing year"
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: "#f6f6f6" }}>
            <Box
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "6px",
                    backgroundColor: "#FFF",
                    color: "#222",
                    border: "1px solid #222",
                    ":hover": {
                      backgroundColor: "#FFF",
                      border: "1px solid #222",
                    },
                  }}
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  disabled={
                    name === "" ||
                    temperature === "" ||
                    model === "" ||
                    chamber === "" ||
                    capacity === "" ||
                    year === "" ||
                    manufacturer === ""
                      ? true
                      : false
                  }
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      </ModalCustom>
    </>
  );
};

export default AddMachine;
