import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
// import AddCustomer from "./_components/AddCustomer";
import CustomNoRowsOverlay from "../../components/NoRows";
import AddSupplier from "./_components/AddSupplier";
import SupplierDetail from "./_components/SupplierDetail";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "name",
    headerClassName: "header",
    headerName: "Name",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Email",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "phone_number",
    headerClassName: "header",
    headerName: "Phone",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "address",
    headerClassName: "header",
    headerName: "Address",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "city",
    headerClassName: "header",
    headerName: "City",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "category",
    headerClassName: "header",
    headerName: "Category",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <button
          style={{
            color: "#21573D",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #B8DBCA",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Active
        </button>
      ) : (
        <button
          style={{
            color: "#444",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Non-active
        </button>
      ),
  },
  {
    field: "_id",
    headerClassName: "header",
    headerName: "Action",
    width: 100,
    flex: 1,
    renderCell: () => (
      <button
        style={{
          color: "#fff",
          // fontFamily: "Murecho",
          fontWeight: "600",
          fontSize: "12px",
          border: "1px solid #222",
          borderRadius: "4px",
          backgroundColor: "#222",
          padding: "5px",
          cursor: "pointer",
        }}
      >
        See Details
      </button>
    ),
  },
];

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ paddingY: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const SupplierList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [item, setItem] = useState(null);
  // const [auxiliariesList, setAuxiliariesList] = useState([]);
  // const [dyestuffList, setDyestuffList] = useState([]);
  // const navigate = useNavigate();
  // const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const getSuppliers = async () => {
    await axiosInstance
      .get("/api/suppliers", authentication)
      .then((res) => {
        setSuppliers(res.data);
        // setAuxiliariesList(
        //   res?.data.filter((spl) => spl.category === "Auxiliaries"),
        // );
        // setDyestuffList(res?.data.filter((spl) => spl.category === "Dyestuff"));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOpenDetail = (dt) => {
    setItem(dt);
    setOpenDetail(true);
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <Box flex="1" sx={{ backgroundColor: "#f6f6f6" }}>
      <AddSupplier
        open={modalOpen}
        setModalOpen={setModalOpen}
        getSuppliers={getSuppliers}
      />
      <SupplierDetail
        open={openDetail}
        setOpenDetail={setOpenDetail}
        data={item}
        getSuppliers={getSuppliers}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Suppliers
        </Typography>
        {user.role === "Admin Warehouse" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Suppplier Data
          </Button>
        )}
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Suppplier Data
          </Button>
        )}
      </Stack>
      <Box sx={{ paddingX: 3 }}>
        {/* <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Dyestuffs"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
            <Tab
              label="Auxiliaries"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
          </Tabs>
        </Box> */}
        {/* <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={dyestuffList}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(rows) => onOpenDetail(rows.row)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={auxiliariesList}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(rows) => onOpenDetail(rows.row)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel> */}
      </Box>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#FFF",
              color: "#222",
            },
            "& .header-font": {
              backgroundColor: "#F6F6F6",
              color: "#F6F6F6",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={suppliers}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(rows) => onOpenDetail(rows.row)}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "none",
              color: "#535252",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default SupplierList;
