import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Autocomplete,
  FormControl,
  Chip,
  Checkbox,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosInstance from "utils/api/api";
import CustomTextField from "components/TextField";
import toast, { Toaster } from "react-hot-toast";
import ConfirmationModal from "components/ConfirmationModal";
// import moment from "moment";
import confirmationSound from "../../../assets/audio/confirmation.mp3";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import StatusSwitch from "components/Switch";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import addedSound from "../../../assets/audio/added.mp3";
import updateSound from "../../../assets/audio/update.mp3";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RecipeDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log(user);
  const [isScouring, setIsScouring] = useState(true);
  const [isFdye, setIsFdye] = useState(true);
  const [isFdip, setIsFdip] = useState(true);
  const [isIsSdye, setIsSdye] = useState(true);
  const [isSdip, setIsSdip] = useState(true);
  const [isWash, setIsWash] = useState(true);
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updatedScouring, setupdatedScouring] = useState([]);
  const [updatedFdye, setUpdatedFdye] = useState([]);
  const [updatedFdip, setUpdatedFdip] = useState([]);
  const [updatedSdye, setUpdatedSdye] = useState([]);
  const [updatedSdip, setUpdatedSdip] = useState([]);
  const [updatedWash, setUpdatedWash] = useState([]);
  const [updatedValue, setUpdatedValue] = useState("");
  const [updatedHexCode, setUpdatedHexCode] = useState("");
  // const [updatedColor, setUpdatedColor] = useState("");
  const [updatedPantone, setUpdatedPantone] = useState("");
  const confirmationEffect = new Audio(confirmationSound);
  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);
  const addedEffect = new Audio(addedSound);
  const updateEffect = new Audio(updateSound);
  const [processes, setProcesses] = useState([]);
  const [process, setProcess] = useState("Choose a process");
  // const [selectedProcess, setSelectedProcess] = useState("");
  const [processId, setProcessId] = useState("");
  const [fullDate, setFullDate] = useState("");
  const [isProcess, setIsProcess] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [dyestuff, setDyestuff] = useState([]);
  const [auxiliaries, setAuxiliaries] = useState([]);
  const [newScouringChemical, setNewScouringChemical] = useState([]);
  const [newFirstDyeChemical, setNewFirstDyeChemical] = useState([]);
  const [newFirstDipChemical, setNewFirstDipChemical] = useState([]);
  const [newSecondDyeChemical, setNewSecondDyeChemical] = useState([]);
  const [newSecondDipChemical, setNewSecondDipChemical] = useState([]);
  const [newWashChemical, setNewWashChemical] = useState([]);
  const [amount, setAmount] = useState("");
  // const [resetScouring, setResetScouring] = useState(false);
  // const [resetScouringArray, setResetScouringArray] = useState([]);
  // const [resetFdyeArray, setResetFdyeArray] = useState(false);

  // All new chemicals will be stored here

  const [scouringArray, setScouringArray] = useState([]);
  const [firstDyeArray, setFirstDyeArray] = useState([]);
  const [secondDyeArray, setSecondDyeArray] = useState([]);
  const [firstDipArray, setFirstDipArray] = useState([]);
  const [secondDipArray, setSecondDipArray] = useState([]);
  const [washingArray, setWashingArray] = useState([]);

  // end.

  // These arrays are used to store selected chemicals at each process respectively
  const [selectedScouringChemical, setSelectedScouringChemical] = useState([]);
  const [selectedFdyeChemical, setSelectedFdyeChemical] = useState([]);
  const [selectedFdipChemical, setSelectedFdipChemical] = useState([]);
  const [selectedSdyeChemical, setSelectedSdyeChemical] = useState([]);
  const [selectedSdipChemical, setSelectedSdipChemical] = useState([]);
  const [selectedWashChemical, setSelectedWashChemical] = useState([]);

  // end.

  const handleBack = () => {
    navigate("/recipes/list");
  };

  // console.log(data);

  const getRecipe = async () => {
    await axiosInstance
      .get(`/api/recipes/${id}`, authentication)
      .then((res) => {
        // console.log(res.data);
        setData(res?.data);
        // setScouringArray(res?.data?.scoring);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProcesses = () => {
    axiosInstance
      .get("/api/processes", authentication)
      .then((res) => {
        // console.log(res.data);
        setProcesses(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const separateChemical = (chemicals) => {
    setDyestuff(chemicals.filter((c) => c.category === "Dyestuff"));
    setAuxiliaries(chemicals.filter((c) => c.category === "Auxiliaries"));
  };

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        // console.log(res.data);
        separateChemical(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatus = async () => {
    const number = data?.code_recipe.slice(3, 6);
    const unique = data?.code_recipe.slice(6);
    const editedData = {
      code_recipe: data?.code_recipe,
      color: data?.color,
      pantone: data?.pantone,
      year: data?.year,
      process: data?.process?._id,
      scoring: data?.scoring,
      dyestuff_first: data?.dyestuff_first,
      chemical_first_dip: data?.chemical_first_dip,
      dyestuff_second: data?.dyestuff_second,
      chemical_second_dip: data?.chemical_second_dip,
      neutralization: data?.neutralization,
      serial_number: number,
      hex_code: data?.hex_code,
      status: !data?.status,
      special_code: unique,
    };
    await axiosInstance
      .put(`/api/recipes/${id}`, editedData, authentication)
      .then(() => {
        successEffect.play();
        toast.success("Recipe status changed!");
        getRecipe();
      })
      .catch(() => {
        errorEffect.play();
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  const updateRecipe = async () => {
    const number = data?.code_recipe.slice(3, 6);
    const unique = data?.code_recipe.slice(6);
    const newScouring = updatedScouring.concat(newScouringChemical);
    const newFirstDye = updatedFdye.concat(newFirstDyeChemical);
    const newFirstDip = updatedFdip.concat(newFirstDipChemical);
    const newSecondDye = updatedSdye.concat(newSecondDyeChemical);
    const newSecondDip = updatedSdip.concat(newSecondDipChemical);
    const newWashing = updatedWash.concat(newWashChemical);

    const editedData = {
      code_recipe: data?.code_recipe,
      color: data?.color,
      pantone: updatedPantone !== "" ? updatedPantone : data?.pantone,
      year: fullDate !== "" ? fullDate : data?.year,
      process: processId !== "" ? processId : data?.process?._id,
      scoring: newScouringChemical.length !== 0 ? newScouring : updatedScouring,
      dyestuff_first:
        newFirstDyeChemical.length !== 0 ? newFirstDye : updatedFdye,
      chemical_first_dip:
        newFirstDipChemical.length !== 0 ? newFirstDip : updatedFdip,
      dyestuff_second:
        newSecondDyeChemical.length !== 0 ? newSecondDye : updatedSdye,
      chemical_second_dip:
        newSecondDipChemical.length !== 0 ? newSecondDip : updatedSdip,
      neutralization: newWashChemical.length !== 0 ? newWashing : updatedWash,
      serial_number: number,
      hex_code: updatedHexCode !== "" ? updatedHexCode : data?.hex_code,
      status: data?.status,
      special_code: unique,
      author: user?.full_name,
    };
    await axiosInstance
      .put(`/api/recipes/${id}`, editedData, authentication)
      .then(() => {
        successEffect.play();
        toast.success("Recipe updated successfully!");
        getRecipe();
        resetAll();
      })
      .catch((error) => {
        // console.log(error);
        // console.log(editedData);
        errorEffect.play();
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  const resetAll = () => {
    setIsEdit(false);
    setOpenModal(false);
    setIsAdding(false);
    setIsProcess("");
    setScouringArray([]);
    setSelectedScouringChemical([]);
    setNewScouringChemical([]);
    setFirstDyeArray([]);
    setSelectedFdyeChemical([]);
    setNewFirstDyeChemical([]);
    setFirstDipArray([]);
    setSelectedFdipChemical([]);
    setNewFirstDipChemical([]);
    setSecondDyeArray([]);
    setSelectedSdyeChemical([]);
    setNewSecondDyeChemical([]);
    setSecondDipArray([]);
    setSelectedSdipChemical([]);
    setNewSecondDipChemical([]);
    setWashingArray([]);
    setSelectedWashChemical([]);
    setNewWashChemical([]);
  };

  const initiateEdit = () => {
    setIsEdit(true);
    setupdatedScouring(data?.scoring);
    setUpdatedFdye(data?.dyestuff_first);
    setUpdatedFdip(data?.chemical_first_dip);
    setUpdatedSdye(data?.dyestuff_second);
    setUpdatedSdip(data?.chemical_second_dip);
    setUpdatedWash(data?.neutralization);
  };

  const updatingScouring = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedScouring.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const updatingFdye = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedFdye.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const updatingFdip = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedFdip.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const updatingSdye = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedSdye.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const updatingSdip = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedSdip.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const updatingWash = (item) => {
    const obj = {
      chemical: item.chemical,
      persentase: updatedValue,
      _id: item._id,
    };
    try {
      updatedWash.map((chem) => {
        if (chem.chemical === item.chemical) chem.persentase = updatedValue;
        return obj;
      });
      toast.success(`${item.chemical} amount is updated!`);
    } catch {
      toast.success("Something's wrong. Please try again!");
    }
  };

  const onUpdate = () => {
    confirmationEffect.play();
    setOpenModal(true);
  };

  useEffect(() => {
    getRecipe();
    getProcesses();
    getChemicals();
  }, []);

  const onProcess = (dt) => {
    setProcessId(dt._id);
  };

  const onDate = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    const pickedDate = moment(date).format("YYYY-MM-DD");
    setFullDate(formattedDate);
    setSelectedDate(pickedDate);
  };

  // Functions made to remove a specific chemical from the chemical list

  const onRemoveScouring = (id) => {
    if (scouringArray.length !== 0) {
      const arr = scouringArray.filter((sar) => sar._id !== id);
      setScouringArray(arr);
      setupdatedScouring(arr);
    } else {
      const arr = data?.scoring.filter((sar) => sar._id !== id);
      setScouringArray(arr);
      setupdatedScouring(arr);
    }
  };

  const onRemoveFdye = (id) => {
    if (firstDyeArray.length !== 0) {
      const arr = firstDyeArray.filter((sar) => sar._id !== id);
      setFirstDyeArray(arr);
      setUpdatedFdye(arr);
    } else {
      const arr = data?.dyestuff_first.filter((sar) => sar._id !== id);
      setFirstDyeArray(arr);
      setUpdatedFdye(arr);
    }
  };

  const onRemoveFdip = (id) => {
    if (firstDipArray.length !== 0) {
      const arr = firstDipArray.filter((sar) => sar._id !== id);
      setFirstDipArray(arr);
      setUpdatedFdip(arr);
    } else {
      const arr = data?.chemical_first_dip.filter((sar) => sar._id !== id);
      setFirstDipArray(arr);
      setUpdatedFdip(arr);
    }
  };

  const onRemoveSdye = (id) => {
    if (secondDyeArray.length !== 0) {
      const arr = secondDyeArray.filter((sar) => sar._id !== id);
      setSecondDyeArray(arr);
      setUpdatedSdye(arr);
    } else {
      const arr = data?.dyestuff_second.filter((sar) => sar._id !== id);
      setSecondDyeArray(arr);
      setUpdatedSdye(arr);
    }
  };

  const onRemoveSdip = (id) => {
    if (secondDipArray.length !== 0) {
      const arr = secondDipArray.filter((sar) => sar._id !== id);
      setSecondDipArray(arr);
      setUpdatedSdip(arr);
    } else {
      const arr = data?.chemical_second_dip.filter((sar) => sar._id !== id);
      setSecondDipArray(arr);
      setUpdatedSdip(arr);
    }
  };

  const onRemoveWash = (id) => {
    if (washingArray.length !== 0) {
      const arr = washingArray.filter((sar) => sar._id !== id);
      setWashingArray(arr);
      setUpdatedWash(arr);
    } else {
      const arr = data?.neutralization.filter((sar) => sar._id !== id);
      setWashingArray(arr);
      setUpdatedWash(arr);
    }
  };

  // end.

  const handleAdd = (prc) => {
    setIsProcess(prc);
    setIsAdding(true);
  };

  // Functions made to add a amount for an added chemical

  const amountFunction = (c, newProcessChemical) => {
    const obj = { chemical: c.chemical_name, persentase: amount, _id: c._id };
    function doCheck() {
      let res = newProcessChemical.some(({ chemical }) => chemical === c);
      return res;
    }
    if (doCheck() === true) {
      newProcessChemical.map((obj) => {
        if (obj.chemical === c) obj.persentase = amount;
        return obj;
      });
      updateEffect.play();
      toast.success(`${c.chemical_name} amount is updated!`);
    } else {
      addedEffect.play();
      newProcessChemical.push(obj);
      toast.success(`${c.chemical_name} has been added successfully!`);
    }
  };

  const addAmount = (c) => {
    isProcess === "Scouring"
      ? amountFunction(c, newScouringChemical)
      : isProcess === "First Dye"
      ? amountFunction(c, newFirstDyeChemical)
      : isProcess === "First Dip"
      ? amountFunction(c, newFirstDipChemical)
      : isProcess === "Second Dye"
      ? amountFunction(c, newSecondDyeChemical)
      : isProcess === "Second Dip"
      ? amountFunction(c, newSecondDipChemical)
      : amountFunction(c, newWashChemical);
  };

  // end.

  const initialDate = moment(data?.createdAt).format("YYYY-MM-DD");

  return (
    <>
      <ConfirmationModal
        title={"Proceed?"}
        message={"This recipe data will be updated. Proceed?"}
        action={() => updateRecipe()}
        actionTitle={"Proceed"}
        open={openModal}
        setOpen={setOpenModal}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <Box flex="1" sx={{ backgroundColor: "#F6F6F6" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={2}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={2}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            {data?.code_recipe}
          </Typography>
          {user.role === "SuperAdmin" || user.role === "Admin Product" ? (
            <Stack justifyContent={"flex-end"} direction={"row"} gap={1}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  backgroundColor: "#222",
                  color: "#FFF",
                  ":hover": {
                    backgroundColor: "#444",
                    color: "#FFF",
                  },
                }}
                variant="contained"
                onClick={() => (isEdit ? setIsEdit(false) : initiateEdit())}
              >
                {isEdit ? "Cancel" : "Edit"}
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  backgroundColor: "#222",
                  display: isEdit ? "block" : "none",
                  color: "#FFF",
                  ":hover": {
                    backgroundColor: "#444",
                    color: "#FFF",
                  },
                }}
                variant="contained"
                onClick={() => onUpdate()}
              >
                Save
              </Button>
            </Stack>
          ) : null}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          paddingX={2}
          gap={2}
        >
          <Stack
            padding={2}
            width={"50%"}
            gap={2}
            backgroundColor={"#FFF"}
            sx={{ borderRadius: "15px" }}
            direction={"row"}
          >
            <Stack width={"50%"} gap={2}>
              <Stack>
                {isEdit ? (
                  <>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"#535252"}
                      mb={0.5}
                    >
                      Process
                    </Typography>
                    <CustomTextField
                      name="name"
                      // label="Process"
                      placeholder="Choose a process"
                      select
                      value={process}
                      onChange={(e) => setProcess(e.target.value)}
                      disabled
                    >
                      <MenuItem value={"Choose a process"}>
                        {data?.process?.process_name}
                      </MenuItem>
                      {processes.map((dt) =>
                        dt.status ? (
                          <MenuItem
                            key={dt._id}
                            value={dt.process_name}
                            onClick={() => onProcess(dt)}
                          >
                            {dt.process_name}
                          </MenuItem>
                        ) : null,
                      )}
                    </CustomTextField>
                  </>
                ) : (
                  <>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"#535252"}
                    >
                      Process
                    </Typography>
                    <Typography fontSize={14}>
                      {data?.process?.process_name}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack>
                {isEdit ? (
                  <>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"#535252"}
                      mb={0.5}
                    >
                      Date
                    </Typography>
                    <CustomTextField
                      type="date"
                      value={
                        fullDate !== ""
                          ? selectedDate
                          : moment(data?.year).format("YYYY-DD-MM")
                      }
                      onChange={(e) => onDate(e.target.value)}
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={"#535252"}
                    >
                      Date
                    </Typography>
                    <Typography fontSize={14}>{data?.year}</Typography>
                  </>
                )}
              </Stack>
            </Stack>
            {user.role === "Manager" ? null : (
              <Stack>
                <Typography fontSize={12} color={"#444"} fontWeight={500}>
                  Status
                </Typography>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <StatusSwitch
                    status={data?.status}
                    changeStatus={() => handleStatus()}
                  />
                  {data?.status ? (
                    <button
                      style={{
                        color: "#21573D",
                        fontFamily: "Murecho",
                        fontWeight: "600",
                        fontSize: "12px",
                        border: "1px solid #B8DBCA",
                        borderRadius: "5px",
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      Active
                    </button>
                  ) : (
                    <button
                      style={{
                        color: "#222",
                        fontFamily: "Murecho",
                        fontWeight: "600",
                        fontSize: "12px",
                        border: "1px solid #222",
                        borderRadius: "5px",
                        backgroundColor: "#FFF",
                      }}
                    >
                      Non-active
                    </button>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack
            padding={2}
            width={"50%"}
            gap={2}
            backgroundColor={"#FFF"}
            sx={{ borderRadius: "15px" }}
          >
            <Stack direction={"row"} gap={2}>
              <Stack width={"50%"}>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"#535252"}
                  mb={0.5}
                >
                  Color Name
                </Typography>
                {isEdit ? (
                  <CustomTextField
                    sx={{
                      marginRight: "10px",
                      height: "30px",
                      textTransform: "uppercase",
                    }}
                    placeholder={data?.color}
                    disabled
                    // onChange={(e) => setUpdatedColor(e.target.value)}
                  />
                ) : (
                  <Typography fontSize={14} textTransform={"uppercase"}>
                    {data?.color}
                  </Typography>
                )}
              </Stack>
              <Stack width={"50%"}>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"#535252"}
                  mb={0.5}
                >
                  Pantone
                </Typography>
                {isEdit ? (
                  <CustomTextField
                    placeholder={data?.pantone}
                    onChange={(e) =>
                      setUpdatedPantone(e.target.value?.toUpperCase())
                    }
                  />
                ) : (
                  <Typography fontSize={14} textTransform={"uppercase"}>
                    {data?.pantone}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <Stack direction={"row"} gap={2}>
              <Stack width={"50%"}>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Color Hex Code
                </Typography>
                {isEdit ? (
                  <input
                    style={{
                      // marginRight: "10px",
                      height: "35px",
                      paddingLeft: "10px",
                      borderRadius: "5px",
                      fontSize: "16px",
                      border: "1px solid #c4c4c4",
                      outline: "none",
                      marginTop: "5px",
                      fontWeight: 400,
                      // width: "75%",
                    }}
                    placeholder={data?.hex_code}
                    onChange={(e) => setUpdatedHexCode(e.target.value)}
                  />
                ) : (
                  <Typography fontSize={14} textTransform={"uppercase"}>
                    {data?.hex_code ? data?.hex_code : "-"}
                  </Typography>
                )}
              </Stack>
              <Stack width={"50%"}>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={"#535252"}
                  mb={0.5}
                >
                  Input Person
                </Typography>
                {isEdit ? (
                  <CustomTextField
                    sx={{ marginRight: "10px", height: "30px" }}
                    placeholder={data?.author?.full_name}
                    disabled
                    // onChange={(e) => setUpdatedColor(e.target.value)}
                  />
                ) : (
                  <Typography fontSize={14}>
                    {data?.author?.full_name}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          paddingY={1}
          sx={{ borderRadius: "15px" }}
          backgroundColor={"#FFF"}
          margin={2}
        >
          {/* SCOURING TABS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsScouring(!isScouring)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                Scouring
              </Typography>
              <Typography>
                {isScouring ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Typography>
            </Stack>
            {isScouring && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("Scouring")}
                      id="Scouring"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "Scouring" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={auxiliaries}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedScouringChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedScouringChemical?.length !== 0 &&
                      selectedScouringChemical?.map((c, i) => (
                        <Stack key={i}>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>gr/L</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                              key={i}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}
                <Stack gap={2}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack
                      width={"50%"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      direction={"row"}
                    >
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack
                      width={"50%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {scouringArray.length !== 0
                    ? scouringArray.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingScouring(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveScouring(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.scoring?.length !== 0
                    ? data?.scoring?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingScouring(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveScouring(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* SCOURING TABS - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

          {/* FIRST DYE TABS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsFdye(!isFdye)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                First Dyestuff
              </Typography>
              <Typography>
                {isFdye ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isFdye && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("First Dye")}
                      id="First Dye"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "First Dye" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={dyestuff}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedFdyeChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedFdyeChemical?.length !== 0 &&
                      selectedFdyeChemical?.map((c, i) => (
                        <Stack>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>%</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Percentage (%)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {firstDyeArray?.length !== 0
                    ? firstDyeArray?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingFdye(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveFdye(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.dyestuff_first?.length !== 0
                    ? data?.dyestuff_first?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingFdye(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveFdye(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* FIRST DYE TABS - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

          {/* FIRST DIP TABS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsFdip(!isFdip)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                First Chemical Dip
              </Typography>
              <Typography>
                {isFdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isFdip && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("First Dip")}
                      id="First Dip"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "First Dip" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={auxiliaries}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedFdipChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedFdipChemical?.length !== 0 &&
                      selectedFdipChemical?.map((c, i) => (
                        <Stack>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>gr/L</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {firstDipArray.length !== 0
                    ? firstDipArray.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingFdip(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveFdip(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.chemical_first_dip?.length !== 0
                    ? data?.chemical_first_dip?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingFdip(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveFdip(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* FIRST DIP TABS - END */}

          {/* SECOND DYE TABS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsSdye(!isIsSdye)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                Second Dyestuff
              </Typography>
              <Typography>
                {isIsSdye ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isIsSdye && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("Second Dye")}
                      id="Second Dye"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "Second Dye" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={dyestuff}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedSdyeChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedSdyeChemical?.length !== 0 &&
                      selectedSdyeChemical?.map((c, i) => (
                        <Stack>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>%</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}

                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Percentage (%)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {secondDyeArray?.length !== 0
                    ? secondDyeArray?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingSdye(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveSdye(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.dyestuff_second?.length !== 0
                    ? data?.dyestuff_second?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingSdye(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveSdye(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* SECOND DYE TABS - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

          {/* SECOND DIP TABS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsSdip(!isSdip)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                Second Chemical Dip
              </Typography>
              <Typography>
                {isSdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isSdip && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("Second Dip")}
                      id="Second Dip"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "Second Dip" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={auxiliaries}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedSdipChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedSdipChemical?.length !== 0 &&
                      selectedSdipChemical?.map((c, i) => (
                        <Stack>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>gr/L</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}

                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {secondDipArray?.length !== 0
                    ? secondDipArray?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingSdip(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveSdip(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.chemical_second_dip?.length !== 0
                    ? data?.chemical_second_dip?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingSdip(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveSdip(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* SECOND DIP TABS - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

          {/* WASH TAB >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
              onClick={() => setIsWash(!isWash)}
              sx={{ cursor: "pointer" }}
            >
              <Typography fontSize={20} fontWeight={500}>
                Washing / Neutralization
              </Typography>
              <Typography>
                {isWash ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isWash && (
              <>
                <Stack justifyContent={"flex-end"} direction={"row"}>
                  {isEdit && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        backgroundColor: "#222",
                        width: "fit-content",
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      variant="contained"
                      onClick={() => handleAdd("Wash")}
                      id="Wash"
                    >
                      <AddIcon />
                    </Button>
                  )}
                </Stack>
                {isProcess === "Wash" && isAdding === true ? (
                  <>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={auxiliaries}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          const objv = newValue.map((v) => v);
                          setSelectedWashChemical(objv);
                        }}
                        getOptionLabel={(option) => option.chemical_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.chemical_name}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                // border: "1px solid #222",
                                color: "#666",
                                fontWeight: 600,
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#222" : "#fff",
                              color: selected ? "#fff" : "#222",
                              borderBottom: "1px solid #fff",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: selected ? "#fff" : "#222",
                              }}
                              checked={selected}
                            />
                            {option.chemical_name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chemicals"
                            placeholder="Select chemical"
                            style={{ outline: "none" }}
                          />
                        )}
                      />
                    </FormControl>
                    {selectedWashChemical?.length !== 0 &&
                      selectedWashChemical?.map((c, i) => (
                        <Stack>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            key={i}
                          >
                            <CustomTextField value={c.chemical_name} disabled />
                            <CustomTextField
                              key={c}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                            <Typography>gr/L</Typography>
                            <Button
                              sx={{
                                background: "#222",
                                color: "#FFF",
                                padding: "5px 10px 5px 10px",
                                borderRadius: "3px",
                                border: "none",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#666",
                                },
                              }}
                              variant="contained"
                              onClick={() => addAmount(c)}
                            >
                              Add
                            </Button>
                          </Stack>
                        </Stack>
                      ))}
                  </>
                ) : null}

                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"50%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {washingArray?.length !== 0
                    ? washingArray?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingWash(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveWash(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : data?.neutralization?.length !== 0
                    ? data?.neutralization?.map((item) => (
                        <>
                          <Stack
                            direction={"rows"}
                            gap={2}
                            key={item?._id}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Stack width={"50%"}>
                              <Typography fontWeight={500} fontSize={14}>
                                {item?.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              {isEdit ? (
                                <Stack
                                  justifyContent={"space-between"}
                                  gap={1}
                                  direction={"row"}
                                >
                                  <CustomTextField
                                    key={item?._id}
                                    placeholder={item?.persentase}
                                    sx={{ height: "30px" }}
                                    onChange={(e) =>
                                      setUpdatedValue(e.target.value)
                                    }
                                  />
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      backgroundColor: "#222",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => updatingWash(item)}
                                    key={item?._id}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "5px",
                                      backgroundColor: "#CC3A30",
                                      color: "#FFF",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    variant="contained"
                                    onClick={() => onRemoveWash(item?._id)}
                                    key={item?._id}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              ) : (
                                <Typography fontSize={14}>
                                  {item?.persentase}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              </>
            )}
          </Stack>
          {/* WASH TAB - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        </Stack>
      </Box>
    </>
  );
};

export default RecipeDetails;
