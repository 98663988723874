import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const ProcessDetails = ({ open, setModalOpen, data, getProcesses }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isEdit, setIsEdit] = useState(false);
  const [processName, setProcessName] = useState("");
  const [processCode, setProcessCode] = useState("");
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);

  const handleChange = () => {
    const edited = {
      process_name: data?.process_name,
      process_code: data?.process_code,
      status: !data?.status,
    };

    axiosInstance
      .put(`/api/processes/${data._id}`, edited, authentication)
      .then(() => {
        succesEffect.play();
        toast.success("Chemical status updated!");
        getProcesses();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleSubmit = () => {
    const edited = {
      process_name: processName !== "" ? processName : data?.process_name,
      process_code: processCode !== "" ? processCode : data?.process_code,
      status: data?.status,
    };

    axiosInstance
      .put(`/api/processes/${data._id}`, edited, authentication)
      .then(() => {
        succesEffect.play();
        toast.success("Process data has been updated!");
        getProcesses();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/processes/${data._id}`, authentication)
      .then(() => {
        succesEffect.play();
        toast.success("Process data has been deleted!");
        getProcesses();
        setModalOpen(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  const onBack = () => {
    setIsEdit(false);
    setModalOpen(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        title={"Delete ?"}
        action={handleDelete}
        message={"This process data is about to be deleted. Are you sure?"}
        actionTitle={"Delete"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Process Details
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="process_name"
                      label="Process Name"
                      placeholder={data?.process_name}
                      onChange={(e) => setProcessName(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"100%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Process Name
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.process_name}
                    </Typography>
                  </Grid>
                )}

                {user.role === "Manager" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleChange()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="process_code"
                      label="Kode process"
                      placeholder={data?.process_code}
                      onChange={(e) => setProcessCode(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"100%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Process Code
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.process_code}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
              {user.role === "Admin Product" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default ProcessDetails;
