import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import ConfirmationModal from "components/ConfirmationModal";
import EditInbound from "./EditInbound";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/api/api";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const InboundDetails = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [data, setData] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const getInbound = async () => {
    await axiosInstance
      .get(`/api/inbounds/${id}`, authentication)
      .then((res) => {
        // console.log(res.data);
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSuppliers = () => {
    axiosInstance
      .get("/api/suppliers", authentication)
      .then((res) => {
        // console.log(res.data);
        setSuppliers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        setChemicals(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    navigate("/warehouse/inbound");
    // setIsReview(false);
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  const deleteInbound = async () => {
    await axiosInstance
      .delete(`/api/inbounds/${id}`, authentication)
      .then(() => {
        setOpenModal(false);
        toast.success("Inbound data has been deleted!");
        setTimeout(() => {
          handleBack();
        }, 2000);
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  useEffect(() => {
    getInbound();
    getChemicals();
    getSuppliers();
  }, []);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Box flex="1" sx={{ backgroundColor: "#F6F6F6" }}>
        <EditInbound
          open={isEdit}
          setModalOpen={setIsEdit}
          data={data}
          // getInbound={getInbound()}
          refetch={getInbound}
          chemicals={chemicals}
          suppliers={suppliers}
        />
        <ConfirmationModal
          open={openModal}
          setOpen={setOpenModal}
          title={"Delete?"}
          message={"You're about to delete this inbound data. Proceed?"}
          actionTitle={"Proceed"}
          action={() => deleteInbound()}
          isWarning={true}
        />
        <Stack sx={{ padding: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ backgroundColor: "#F6F6F6" }}
          >
            <Button
              startIcon={<WestIcon />}
              sx={{
                textTransform: "capitalize",
                color: "#6D7688",
                fontWeight: "400",
              }}
              onClick={() => handleBack()}
            >
              Back
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            // paddingX={3}
            sx={{ backgroundColor: "#F6F6F6" }}
            paddingY={2}
          >
            <Typography variant="h4" fontWeight="500">
              Inbound Details
            </Typography>
            {user.role === "SuperAdmin" || user.role === "Admin Warehouse" ? (
              <Stack direction={"row"} gap={1}>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "11px",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#FFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => onDelete()}
                >
                  Delete Inbound
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "11px",
                    backgroundColor: "#222",
                    color: "#FFF",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#FFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsEdit(true)}
                >
                  Edit Inbound
                </Button>
              </Stack>
            ) : null}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={2}
            mt={2}
            sx={{ backgroundColor: "#FFF", borderRadius: "25px" }}
          >
            <Stack
              padding={2}
              width={"50%"}
              gap={2}
              backgroundColor={"#FFF"}
              sx={{ borderRadius: "10px" }}
            >
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Disposition
                </Typography>
                <Typography fontSize={14}>{data?.disposition}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Item Name
                </Typography>
                <Typography fontSize={14}>{data?.item_name}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Weight (kg)
                </Typography>
                <Typography fontSize={14}>{data?.weight}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Date
                </Typography>
                <Typography fontSize={14}>
                  {moment(data?.date).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
              {/* <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Total (gr)
                </Typography>
                <Typography fontSize={14}>
                  {(
                    parseInt(data?.weight) *
                    parseInt(data?.quantity) *
                    1000
                  ).toLocaleString("id-ID")}
                </Typography>
              </Stack> */}
            </Stack>
            <Stack
              padding={2}
              width={"50%"}
              gap={2}
              backgroundColor={"#FFF"}
              sx={{ borderRadius: "10px" }}
            >
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Supplier
                </Typography>
                <Typography fontSize={14}>
                  {/* {data?.supplier?.supplier_name} */}
                  {data?.supplier?.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Qty
                </Typography>
                <Typography fontSize={14}>{data?.quantity}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Total (kg)
                </Typography>
                <Typography fontSize={14}>
                  {/* {parseInt(data?.total_weight).toLocaleString("id-ID")} */}
                  {(
                    parseFloat(data?.weight) * parseFloat(data?.quantity)
                  ).toLocaleString("id-ID")}
                </Typography>
              </Stack>
              {/* <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Price (Rp)
                </Typography>
                <Typography fontSize={14}>
                  {parseInt(data?.price).toLocaleString("id-ID")}
                </Typography>
              </Stack> */}
              {/* <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Total Price (Rp)
                </Typography>
                <Typography fontSize={14}>
                  {parseInt(data?.total_price).toLocaleString("id-ID")}
                  {(
                    parseFloat(data?.weight) *
                    parseFloat(data?.quantity) *
                    parseFloat(data?.price)
                  ).toLocaleString("id-ID")}
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default InboundDetails;
