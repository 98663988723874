import {
  Grid,
  InputAdornment,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import CustomTextField from "../../components/TextField";
import React, { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Header from "./_components/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UserInvalidModal from "./_components/UserInvalidModal";
import axiosInstance from "../../utils/api/api";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import ConfirmationModal from "components/ConfirmationModal";
import errorSound from "../../assets/audio/denied.mp3";
import moment from "moment";

const LoginPage = (props) => {
  const [form, setForm] = useState({ email: "", password: "" });
  const [changePassword, setChangePassword] = useState({
    existingEmail: "",
    verifiedEmail: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [shown, setShown] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const errorEffect = new Audio(errorSound);

  useEffect(() => {
    if (isSubmiting) {
      if (Object.keys(errors).length === 0) {
        submitLogin();
      } else {
        setIsSubmiting(false);
      }
    }
  }, [errors]);

  function timeStamp() {
    // Current date - 1 day = yesterday +
    const expiryDate = new Date(); // Milliseconds
    const normalDate = moment(expiryDate).format("LL");
    localStorage.setItem("timeStamp", JSON.stringify(normalDate));
  }

  const submitLogin = () => {
    forgotPassword
      ? Swal.fire({
          title: "Apakah Anda yakin ingin merubah password?",
          text: "Anda dapat merubah password Anda kembali sewaktu-waktu.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1365C0",
          cancelButtonColor: "#EA4D54",
          confirmButtonText: "Ya, lanjutkan",
          cancelButtonText: "batal",
        }).then((result) => {
          if (result.isConfirmed) {
            // axiosInstance.delete(`/blogs/${articleId}`, {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // });
            Swal.fire(
              "Selesai!",
              "Mohon periksa email Anda untuk melanjutkan",
              "success",
            );
            setTimeout(() => {
              document.location.reload();
            }, 2000);
          }
        })
      : axiosInstance
          .post(`/api/admins/login`, form)
          .then((res) => {
            // console.log(res.data);
            timeStamp();
            localStorage.setItem("admin_data", JSON.stringify(res.data.admin));
            localStorage.setItem(
              "userToken",
              JSON.stringify(res.data.access_token),
            );
            return window.location.reload();
          })
          .catch((err) => {
            errorEffect.play();
            Swal.fire(
              "Access denied!",
              "Make sure you have the correct email and password.",
              "error",
            );
            setIsSubmiting(false);
          });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errs = validate();
    setErrors(errs);
    setIsSubmiting(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  const handleChangePassword = (e) => {
    const { id, value } = e.target;
    setChangePassword({ ...changePassword, [id]: value });
  };

  const validate = () => {
    let err = {};
    if (!form.email) {
      err.email = "email required";
    }
    if (!form.password) {
      err.password = "password required";
    }
    return err;
  };

  const onConsole = () => {
    setOpenConfirmation(false);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          boxSizing: "",
          margin: 0,
          padding: 0,
          minHeight: "100vh",
          overflow: "hidden",
          whitespace: "nowrap",
        }}
      >
        <UserInvalidModal open={modalOpen} setModalOpen={setModalOpen} />
        <ConfirmationModal
          title={"Forgot password?"}
          message={"Please contact the admin for assistance."}
          action={onConsole}
          actionTitle={"OK"}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          isWarning={false}
        />

        {isSubmiting ? (
          <Box>
            <Grid
              container
              direction={`row`}
              justify={`center`}
              sx={{ minHeight: "100vh" }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Loader />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            width="screen"
            direction={`row`}
            justify={`center`}
            sx={{ height: "100vh" }}
            alignItems="center"
            spacing={2}
          >
            {/* <Grid item xs={12} md={6}>
                <img src={LoginImage} alt="" srcSet="" />
              </Grid> */}
            <div
              style={{
                // backgroundColor: "#222",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(https://ik.imagekit.io/6tebrod5l/erecipe/Left%20Side_MQAkG7n8W.png?updatedAt=1699777171125)",
                backgroundPosition: "center",
                boxSizing: "",
                margin: 0,
                padding: 0,
                minHeight: "110vh",
                minWidth: "50%",
                overflow: "hidden",
                whitespace: "nowrap",
              }}
            />
            <Grid
              item
              xs={12}
              md={6}
              sx={{ backgroundColor: "#FFF", borderRadius: "10px" }}
            >
              <Header forgotPassword={forgotPassword} />
              {/*Form  */}
              <form onSubmit={handleSubmit}>
                {forgotPassword ? (
                  <>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        type="email"
                        name="existingEmail"
                        value={changePassword.existingEmail}
                        onChange={handleChangePassword}
                        label={errors.existingEmail ? errors.existingEmail : ""}
                        error={errors.existingEmail ? true : false}
                        placeholder="Email Anda"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        type="email"
                        name="verifiedEmail"
                        value={changePassword.verifiedEmail}
                        onChange={handleChangePassword}
                        label={errors.verifiedEmail ? errors.verifiedEmail : ""}
                        error={errors.verifiedEmail ? true : false}
                        placeholder="Verifikasi email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}
                  </>
                ) : (
                  <>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                        label={errors.email ? errors.email : "Email"}
                        error={errors.email ? true : false}
                        placeholder="Input your registered email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        value={form.password}
                        name="password"
                        onChange={handleChange}
                        placeholder="Input your password"
                        error={errors.password ? true : false}
                        label={errors.password ? errors.password : "Password"}
                        type={shown ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {shown ? (
                                <VisibilityIcon
                                  onClick={() => setShown(false)}
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShown(true)}
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "24px",
                    textAlign: "right",
                  }}
                  px={14}
                  py={2}
                >
                  <Typography
                    sx={{
                      color: "#222",
                      fontSize: "16px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    // onClick={() => setForgotPassword(!forgotPassword)}
                    onClick={() => setOpenConfirmation(true)}
                  >
                    {/* {forgotPassword
                      ? "Sudah punya akun? Silahkan masuk"
                      : "Lupa password?"} */}
                    Forgot password?
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1} mb={2} px={14}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#222",
                      width: "100%",
                      borderRadius: "2px",
                      padding: 1,
                      ":hover": {
                        backgroundColor: "#444",
                        color: "#fff",
                      },
                    }}
                  >
                    {forgotPassword ? "Kirim" : "Login"}
                  </Button>
                </Grid>
              </form>
              {/* end Form */}
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  height: "fit-content",
                  padding: "15px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                }}
                mt={22}
              >
                <Typography>PT Perajutan Sahabat</Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default LoginPage;
