import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const AddFabric = ({ open, setModalOpen, getFabrics }) => {
  const [knittingType, setKnittingType] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [thickness, setThickness] = useState("");
  const [gramation, setGramation] = useState("");
  const [width, setWidth] = useState("");
  const [fabricCode, setFabricCode] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const handleClose = () => {
    setKnittingType("");
    setName("");
    setType("");
    setThickness("");
    setGramation("");
    setWidth("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      gramation: gramation,
      knit: knittingType,
      name: name,
      thickness: thickness,
      type: type,
      width: width,
      fabric_code: fabricCode,
    };

    axiosInstance
      .post("/api/fabrics", data, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("New fabric data added!");
        getFabrics();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Fabric
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        <form onSubmit={handleSubmit}>
          <Box flex="1">
            <Stack paddingX={2} paddingY={2}>
              <Stack gap={2}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="knitting"
                      label="Knitting"
                      placeholder="Input the type of the knitting"
                      onChange={(e) => setKnittingType(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      placeholder="Input the name of the fabric"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="type"
                      label="Type"
                      placeholder="Input the type of the fabric"
                      onChange={(e) => setType(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="thickness"
                      label="Thickness"
                      placeholder="Input the thickness"
                      onChange={(e) => setThickness(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="gramation"
                      label="Gramation"
                      placeholder="Input the gramation"
                      onChange={(e) => setGramation(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="width"
                      label="Width (inch)"
                      // type="number"
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="fabric_code"
                      label="Fabric code"
                      placeholder="Input the code of the fabric"
                      onChange={(e) => setFabricCode(e.target.value)}
                    />
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: "#f6f6f6" }}>
            <Box
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "6px",
                    backgroundColor: "#FFF",
                    color: "#222",
                    border: "1px solid #222",
                    ":hover": {
                      backgroundColor: "#FFF",
                      border: "1px solid #222",
                    },
                  }}
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  disabled={
                    name === "" ||
                    knittingType === "" ||
                    type === "" ||
                    thickness === "" ||
                    gramation === "" ||
                    width === "" ||
                    fabricCode === ""
                      ? true
                      : false
                  }
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      </ModalCustom>
    </>
  );
};

export default AddFabric;
