import React from "react";
import CustomerList from "../pages/Customers";
// import CustomerDetails from "../pages/Customers/_components/CustomerDetails";
import ChemicalList from "pages/Chemical";
import ProcessList from "pages/Process";
import FabricList from "pages/Fabric";
import MachineList from "pages/Machine";
import SupplierList from "pages/Supplier";

const root = "/master";

const master = [
  {
    path: `${root}/customers`,
    component: <CustomerList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/suppliers`,
    component: <SupplierList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/chemicals`,
    component: <ChemicalList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/processes`,
    component: <ProcessList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/fabrics`,
    component: <FabricList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/machines`,
    component: <MachineList />,
    sidebar: true,
    navbar: true,
  },
];

export default master;
