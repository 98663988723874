import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  ClickAwayListener,
  Fade,
  Popper,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ConfirmationModal from "components/ConfirmationModal";
import toast, { Toaster } from "react-hot-toast";
import EditProduction from "./EditProduction";
import Renderer from "./PDFRenderer";
import CustomTextField from "components/TextField";
import axiosInstance from "utils/api/api";
import moment from "moment";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import confirmationSound from "../../../assets/audio/confirmation.mp3";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const ProductionDetails = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [isScouring, setIsScouring] = useState(true);
  const [isFdye, setIsFdye] = useState(true);
  const [isFdip, setIsFdip] = useState(true);
  const [isIsSdye, setIsSdye] = useState(true);
  const [isSdip, setIsSdip] = useState(true);
  const [isWash, setIsWash] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRenderer, setIsRenderer] = useState(false);
  const [data, setData] = useState([]);
  const { prodId } = useParams();
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [holdRemarks, setHoldRemarks] = useState("");
  const [remarks, setRemarks] = useState("-");

  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);
  const confirmationEffect = new Audio(confirmationSound);

  const today = new Date();
  const todayDate = moment(today).format("DD/MM/YYYY");
  const holdingTime = moment(today).format("hh:mm");

  const getProduction = async () => {
    await axiosInstance
      .get(`/api/production_lists/${prodId}`, authentication)
      .then((res) => {
        // console.log(res?.data);
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProduction = async () => {
    await axiosInstance
      .delete(`/api/production_lists/${prodId}`, authentication)
      .then(() => {
        // console.log(res.data);
        successEffect.play();
        toast.success("The production has been deleted!");
        handleBack();
      })
      .catch((error) => {
        errorEffect.play();
        toast.error("Oops! Something's wrong. Please try again.");
        console.log(error);
      });
  };

  useEffect(() => {
    getProduction();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleBack = () => {
    navigate("/productions/list");
    // setIsReview(false);
  };

  const onDelete = () => {
    confirmationEffect.play();
    setOpenModal(true);
  };

  const onUpdate = (type) => {
    setIsUpdate(true);
    setUpdateType(type);
    // toast.success(`Production's status has been change to: ${status}`);
  };

  const onCancel = () => {
    setIsUpdate(false);
    setUpdateType("type");
  };

  const handleUpdate = async () => {
    const data = {
      date: updateType === "Hold" ? todayDate : date,
      time: updateType === "Hold" ? holdingTime : time,
      status: updateType,
      remark: updateType === "Hold" ? holdRemarks : remarks,
    };

    const planningData = {
      date: todayDate,
      time: holdingTime,
      status: updateType,
      remark: data.remark,
    };

    // console.log(data);
    await axiosInstance
      .put(
        `/api/production_lists/${prodId}/update-status`,
        updateType === "Planning" ? planningData : data,
        authentication,
      )
      .then(() => {
        successEffect.play();
        toast.success(`Production's status has been change to: ${updateType}`);
        // console.log(res.data);
        setIsUpdate(false);
        getProduction();
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error(`Oops! Something is wrong. Please try again.`);
      });
  };

  return (
    <>
      <Box flex="1" sx={{ backgroundColor: "#F6F6F6" }}>
        <Renderer open={isRenderer} setModalOpen={setIsRenderer} data={data} />
        <Toaster position="top-center" reverseOrder={false} />
        <ConfirmationModal
          open={openModal}
          setOpen={setOpenModal}
          title={"Delete?"}
          message={"You're about to delete this production data. Proceed?"}
          actionTitle={"Proceed"}
          action={() => deleteProduction(false)}
          isWarning={true}
        />
        <EditProduction
          open={editModal}
          setModalOpen={setEditModal}
          data={data}
          getProductions={getProduction}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={2}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={2}
          sx={{ backgroundColor: "#F6F6F6" }}
          //   paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Production Details
          </Typography>
          {user.role === "SuperAdmin" && (
            <Stack direction={"row"} gap={1}>
              {data?.status === "Planning" || data?.status === "Hold" ? (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "11px",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#FFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => onDelete()}
                >
                  Delete Production
                </Button>
              ) : null}
              {data?.status === "Planning" || data?.status === "Hold" ? (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "11px",
                    backgroundColor: "#222",
                    color: "#FFF",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#FFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => setEditModal(true)}
                >
                  Edit Production
                </Button>
              ) : null}
            </Stack>
          )}
          {user.role === "Admin Product" && data?.status !== "Done" && (
            <Stack direction={"row"} gap={1}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "11px",
                  backgroundColor: "#CC3A30",
                  color: "#FFF",
                  ":hover": {
                    backgroundColor: "#444",
                    color: "#FFF",
                  },
                }}
                variant="contained"
                onClick={() => onDelete()}
              >
                Delete Production
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "11px",
                  backgroundColor: "#222",
                  color: "#FFF",
                  ":hover": {
                    backgroundColor: "#444",
                    color: "#FFF",
                  },
                }}
                variant="contained"
                onClick={() => setEditModal(true)}
              >
                Edit Production
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            margin: 2,
            backgroundColor: "#FFF",
            borderRadius: "15px",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"right"}
            paddingX={2}
            gap={2}
            mt={2}
          >
            <button
              style={{
                color: "#444",
                fontWeight: "600",
                fontSize: "14px",
                padding: "6px 10px",
                border: "1px solid #C8C8C8",
                borderRadius: "8px",
                backgroundColor: "#FFF",
                width: "fit-content",
                cursor: "pointer",
              }}
              onClick={() => setIsRenderer(true)}
            >
              Preview PDF
            </button>
            {user.role === "SuperAdmin" ||
            (user.role === "Admin Product" && data?.status !== "Done") ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                width={"auto"}
                // sx={{ width: "300px" }}
                gap={1}
                justifyContent={"flex-end"}
              >
                <Typography fontSize={14}>Change status</Typography>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    backgroundColor: "#222",
                    color: "#FFF",
                    width: "140px",
                    textAlign: "left",
                    justifyContent: "space-between",
                    ":hover": {
                      backgroundColor: "#444",
                      color: "#FFF",
                    },
                  }}
                  disabled={updateType === "Done" ? true : false}
                  variant="contained"
                  aria-describedby={id}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {data?.status}
                </Button>
                <Popper id={id} open={open} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Fade {...TransitionProps} timeout={350}>
                        <Stack direction={"row"} gap={1} px={2}>
                          {isUpdate === true &&
                            (updateType === "Hold" ? (
                              <Stack
                                sx={{
                                  p: 1,
                                  mt: 1,
                                  height: "fit-content",
                                  width: "300px",
                                  borderRadius: "10px",
                                  bgcolor: "background.paper",
                                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                                }}
                                // direction={"row"}
                              >
                                <Grid>
                                  <CustomTextField
                                    label={
                                      "Remarks / Reasons for holding this production:"
                                    }
                                    multiline
                                    rows={4}
                                    onChange={(e) =>
                                      updateType === "Hold"
                                        ? setHoldRemarks(e.target.value)
                                        : setRemarks(e.target.value)
                                    }
                                    placeholder={data?.remark}
                                    inputProps={{ maxLength: 300 }}
                                  />
                                </Grid>
                                <Stack
                                  direction={"row"}
                                  gap={1}
                                  pt={1}
                                  justifyContent={"space-between"}
                                >
                                  <Typography fontSize={10}>
                                    {holdRemarks?.length} / 300
                                  </Typography>
                                  <Stack direction={"row"} gap={1}>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      width={"fit-content"}
                                      onClick={() => onCancel()}
                                      sx={{
                                        textTransform: "capitalize",
                                        ":hover": {
                                          backgroundColor: "#444",
                                          color: "#FFF",
                                        },
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      width={"fit-content"}
                                      sx={{
                                        textTransform: "capitalize",
                                        backgroundColor: "#222",
                                        ":hover": {
                                          backgroundColor: "#444",
                                          color: "#FFF",
                                        },
                                      }}
                                      onClick={() => handleUpdate()}
                                    >
                                      Update
                                    </Button>
                                  </Stack>
                                </Stack>
                              </Stack>
                            ) : updateType === "Planning" ? (
                              <Stack
                                sx={{
                                  p: 1,
                                  mt: 1,
                                  height: "fit-content",
                                  width: "300px",
                                  borderRadius: "10px",
                                  bgcolor: "background.paper",
                                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                                }}
                                // direction={"row"}
                              >
                                <Grid mb={1}>
                                  <Typography fontSize={15}>
                                    Put this production back to Planning phase?
                                  </Typography>
                                </Grid>
                                <Stack
                                  direction={"row"}
                                  gap={1}
                                  pt={1}
                                  justifyContent={"flex-end"}
                                >
                                  <Stack direction={"row"} gap={1}>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      width={"fit-content"}
                                      onClick={() => onCancel()}
                                      sx={{
                                        textTransform: "capitalize",
                                        ":hover": {
                                          backgroundColor: "#444",
                                          color: "#FFF",
                                        },
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      width={"fit-content"}
                                      sx={{
                                        textTransform: "capitalize",
                                        backgroundColor: "#222",
                                        ":hover": {
                                          backgroundColor: "#444",
                                          color: "#FFF",
                                        },
                                      }}
                                      onClick={() => handleUpdate()}
                                    >
                                      Yes, proceed
                                    </Button>
                                  </Stack>
                                </Stack>
                              </Stack>
                            ) : (
                              <Stack
                                sx={{
                                  p: 1,
                                  mt: 1,
                                  height: "fit-content",
                                  width: "300px",
                                  borderRadius: "10px",
                                  bgcolor: "background.paper",
                                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                                }}
                                // direction={"row"}
                              >
                                <Stack
                                  gap={1}
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                >
                                  <Grid>
                                    <CustomTextField
                                      label={"Date"}
                                      type="date"
                                      onChange={(e) =>
                                        setDate(
                                          moment(e.target.value).format(
                                            "DD/MM/YYYY",
                                          ),
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid>
                                    <CustomTextField
                                      label={
                                        updateType === "In Progress"
                                          ? "Start time"
                                          : "Finish time"
                                      }
                                      type="time"
                                      onChange={(e) => setTime(e.target.value)}
                                    />
                                  </Grid>
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  gap={1}
                                  pt={1}
                                  justifyContent={"flex-end"}
                                >
                                  <Button
                                    variant="contained"
                                    color="error"
                                    width={"fit-content"}
                                    onClick={() => onCancel()}
                                    sx={{
                                      textTransform: "capitalize",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    width={"fit-content"}
                                    sx={{
                                      textTransform: "capitalize",
                                      backgroundColor: "#222",
                                      ":hover": {
                                        backgroundColor: "#444",
                                        color: "#FFF",
                                      },
                                    }}
                                    onClick={() => handleUpdate()}
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </Stack>
                            ))}
                          <Stack
                            sx={{
                              p: 1,
                              // mr: 2,
                              mt: 1,
                              // width: "fit-content",
                              borderRadius: "10px",
                              bgcolor: "background.paper",
                              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                              height: "fit-content",
                            }}
                            gap={"2px"}
                          >
                            {data?.status === "Planning" && (
                              <Button
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "5px",
                                  backgroundColor: `${
                                    updateType === "In Progress"
                                      ? "#222"
                                      : "#fff"
                                  }`,
                                  marginTop: "2px",
                                  textAlign: "left",
                                  justifyContent: "start",
                                  border: "1px solid #222",
                                  color: `${
                                    updateType === "In Progress"
                                      ? "#FFF"
                                      : "#222"
                                  }`,
                                  boxShadow: "none",
                                  width: "140px",
                                  ":hover": {
                                    backgroundColor: "#222",
                                    color: "#FFF",
                                    boxShadow: "none",
                                  },
                                }}
                                variant="contained"
                                onClick={() => onUpdate("In Progress")}
                                startIcon={
                                  updateType === "In Progress" ? (
                                    <ArrowLeftIcon />
                                  ) : null
                                }
                              >
                                In Progress
                              </Button>
                            )}
                            {data?.status === "Planning" && (
                              <Button
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "5px",
                                  backgroundColor: `${
                                    updateType === "Hold" ? "#222" : "#fff"
                                  }`,
                                  marginTop: "2px",
                                  textAlign: "left",
                                  justifyContent: "start",
                                  border: "1px solid #222",
                                  color: `${
                                    updateType === "Hold" ? "#fff" : "#222"
                                  }`,
                                  boxShadow: "none",
                                  width: "140px",
                                  ":hover": {
                                    backgroundColor: "#222",
                                    color: "#FFF",
                                    boxShadow: "none",
                                  },
                                }}
                                variant="contained"
                                onClick={() => onUpdate("Hold")}
                                startIcon={
                                  updateType === "Hold" ? (
                                    <ArrowLeftIcon />
                                  ) : null
                                }
                              >
                                Hold
                              </Button>
                            )}
                            {data?.status === "In Progress" && (
                              <Button
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "5px",
                                  backgroundColor: "#fff",
                                  marginTop: "2px",
                                  textAlign: "left",
                                  justifyContent: "start",
                                  border: "1px solid #222",
                                  color: "#222",
                                  boxShadow: "none",
                                  width: "120px",
                                  ":hover": {
                                    backgroundColor: "#222",
                                    color: "#FFF",
                                    boxShadow: "none",
                                  },
                                }}
                                variant="contained"
                                onClick={() => onUpdate("Done")}
                                startIcon={
                                  updateType === "Done" ? (
                                    <ArrowLeftIcon />
                                  ) : null
                                }
                              >
                                Done
                              </Button>
                            )}
                            {data?.status === "Hold" && (
                              <Button
                                sx={{
                                  textTransform: "capitalize",
                                  borderRadius: "5px",
                                  backgroundColor: `${
                                    updateType === "In Progress"
                                      ? "#222"
                                      : "#fff"
                                  }`,
                                  marginTop: "2px",
                                  textAlign: "left",
                                  justifyContent: "start",
                                  border: "1px solid #222",
                                  color: `${
                                    updateType === "In Progress"
                                      ? "#FFF"
                                      : "#222"
                                  }`,
                                  boxShadow: "none",
                                  width: "140px",
                                  ":hover": {
                                    backgroundColor: "#222",
                                    color: "#FFF",
                                    boxShadow: "none",
                                  },
                                }}
                                variant="contained"
                                onClick={() => onUpdate("Planning")}
                                startIcon={
                                  updateType === "Planning" ? (
                                    <ArrowLeftIcon />
                                  ) : null
                                }
                              >
                                Planning
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </Fade>
                    </ClickAwayListener>
                  )}
                </Popper>
              </Stack>
            ) : null}
          </Stack>
          <Divider sx={{ margin: 2 }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            paddingX={2}
            gap={2}
          >
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Input Date
                </Typography>
                <Typography fontSize={14}>
                  {moment(data?.date).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Production Code
                </Typography>
                <Typography fontSize={14}>{data?.code_production}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Customer
                </Typography>
                <Typography fontSize={14}>{data?.customer_name}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Start Date
                </Typography>
                <Typography fontSize={14}>
                  {data?.start_date !== ""
                    ? moment(data?.start_date).format("DD/MM/YYYY")
                    : "Not started"}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Start Time
                </Typography>
                <Typography fontSize={14}>
                  {data?.start_time !== "" ? data.start_time : "Not started"}
                </Typography>
              </Stack>
            </Stack>
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Status
                </Typography>
                {data?.status === "Planning" ? (
                  <button
                    style={{
                      color: "#734011",
                      fontFamily: "Murecho",
                      fontWeight: "600",
                      fontSize: "12px",
                      border: "1px solid #EECEB0",
                      borderRadius: "5px",
                      backgroundColor: "#FFF9F2",
                      width: "fit-content",
                    }}
                  >
                    {data?.status}
                  </button>
                ) : data?.status === "In Progress" ? (
                  <button
                    style={{
                      color: "#3267E3",
                      fontFamily: "Murecho",
                      fontWeight: "600",
                      fontSize: "12px",
                      border: "1px solid #B1C5F6",
                      borderRadius: "5px",
                      backgroundColor: "#F0F3FF",
                      width: "fit-content",
                    }}
                  >
                    {data?.status}
                  </button>
                ) : data?.status === "Done" ? (
                  <button
                    style={{
                      color: "#21573D",
                      fontFamily: "Murecho",
                      fontWeight: "600",
                      fontSize: "12px",
                      border: "1px solid #B8DBCA",
                      borderRadius: "5px",
                      backgroundColor: "#F7F7F7",
                      width: "fit-content",
                    }}
                  >
                    {data?.status}
                  </button>
                ) : (
                  <button
                    style={{
                      color: "#CB3A31",
                      fontFamily: "Murecho",
                      fontWeight: "600",
                      fontSize: "12px",
                      border: "1px solid #EEB4B0",
                      borderRadius: "5px",
                      backgroundColor: "#FFF4F2",
                      width: "fit-content",
                    }}
                  >
                    {data?.status}
                  </button>
                )}
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Fabric code
                </Typography>
                <Typography fontSize={14}>
                  {data?.fabric_code === "" ? "-" : data?.fabric_code}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Purchase Order
                </Typography>
                <Typography fontSize={14}>{data?.purchase_order}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Finish Date
                </Typography>
                <Typography fontSize={14}>
                  {data?.finish_date !== ""
                    ? moment(data?.finish_date).format("DD/MM/YYYY")
                    : "Unfinished"}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Finish Time
                </Typography>
                <Typography fontSize={14}>
                  {data?.finish_time !== "" ? data.finish_time : "Unfinished"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ margin: 2 }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            paddingX={2}
            gap={2}
          >
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Recipe Code
                </Typography>
                <Typography fontSize={14}>{data?.code_recipe}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Weight
                </Typography>
                <Typography fontSize={14}>{data?.weight}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Scouring vlot
                </Typography>
                <Typography fontSize={14}>
                  {data?.vlot_scouring?.toLocaleString("id-ID", {
                    maximumFractionDigits: 3,
                  })}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Dyeing vlot
                </Typography>
                <Typography fontSize={14}>
                  {data?.vlot_dyeing?.toLocaleString("id-ID", {
                    maximumFractionDigits: 3,
                  })}
                </Typography>
              </Stack>
            </Stack>
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Roll
                </Typography>
                <Typography fontSize={14}>{data?.roll}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Machine
                </Typography>
                <Typography fontSize={14}>{data?.machine_name}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ margin: 2 }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            paddingX={2}
            gap={2}
          >
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Process
                </Typography>
                <Typography fontSize={14}>{data?.process_name}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Pantone
                </Typography>
                <Typography fontSize={14}>{data?.pantone}</Typography>
              </Stack>
            </Stack>
            <Stack padding={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack direction={"row"}>
                <Stack>
                  <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                    Color
                  </Typography>
                  <Typography fontSize={14}>{data?.color}</Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Input Person
                </Typography>
                <Typography fontSize={14}>{data?.author?.full_name}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ margin: 2 }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            paddingX={2}
            gap={2}
          >
            <Stack paddingY={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
              <Stack>
                <Typography fontSize={12} fontWeight={500} color={"#535252"}>
                  Remarks
                </Typography>
                <Typography fontSize={14}>
                  {data?.remark ? data?.remark : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ margin: 2 }} />
          <Stack paddingX={2}>
            {/* SCOURING TABS */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsScouring(!isScouring)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  Scouring
                </Typography>
                <Typography>
                  {isScouring ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              </Stack>
              {isScouring && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Scouring vlot
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (kg)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.scoring?.length !== 0
                    ? data?.scoring?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"25%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase).toLocaleString(
                                  "id-ID",
                                  { maximumFractionDigits: 10 },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(data?.vlot_scouring).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  (parseFloat(item.persentase) *
                                    data?.vlot_scouring) /
                                  1000
                                ).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* SCOURING TABS - END */}

            {/* FIRST DYE TABS */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsFdye(!isFdye)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  First Dyestuff
                </Typography>
                <Typography>
                  {isFdye ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
              </Stack>
              {isFdye && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Percentage (%)
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Fabric weight (kg)
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (gr)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.dyestuff_first?.length !== 0
                    ? data?.dyestuff_first?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"25%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase)?.toLocaleString(
                                  "id-ID",
                                  {
                                    maximumFractionDigits: 10,
                                  },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {data?.weight}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  data?.weight *
                                  (item.persentase / 100) *
                                  1000
                                )?.toLocaleString("id-ID", {
                                  maximumFractionDigits: 0,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* FIRST DYE TABS - END */}

            {/* FIRST DIP TABS */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsFdip(!isFdip)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  First Chemical Dip
                </Typography>
                <Typography>
                  {isFdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
              </Stack>
              {isFdip && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Dyeing vlot
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (kg)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.chemical_first_dip?.length !== 0
                    ? data?.chemical_first_dip?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"25%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase).toLocaleString(
                                  "id-ID",
                                  {
                                    maximumFractionDigits: 10,
                                  },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(data?.vlot_dyeing).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  (item.persentase * data?.vlot_dyeing) /
                                  1000
                                ).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* FIRST DIP TABS - END */}

            {/* SECOND DYE TABS */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsSdye(!isIsSdye)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  Second Dyestuff
                </Typography>
                <Typography>
                  {isIsSdye ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              </Stack>
              {isIsSdye && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Percentage (%)
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Fabric weight (kg)
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (gr)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.dyestuff_second?.length !== 0
                    ? data?.dyestuff_second?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"25%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase).toLocaleString(
                                  "id-ID",
                                  { maximumFractionDigits: 10 },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {data?.weight}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  data?.weight *
                                  (item.persentase / 100) *
                                  1000
                                ).toLocaleString("id-ID", {
                                  maximumFractionDigits: 0,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* SECOND DYE TABS - END */}

            {/* SECOND DIP TABS */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsSdip(!isSdip)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  Second Chemical Dip
                </Typography>
                <Typography>
                  {isSdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
              </Stack>
              {isSdip && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Dyeing vlot
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (kg)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.chemical_second_dip?.length !== 0
                    ? data?.chemical_second_dip?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"25%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase).toLocaleString(
                                  "id-ID",
                                  { maximumFractionDigits: 10 },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(data?.vlot_dyeing).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                            <Stack width={"25%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  parseFloat(
                                    item.persentase * data?.vlot_dyeing,
                                  ) / 1000
                                ).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* SECOND DIP TABS - END */}

            {/* WASH TAB */}
            <Stack paddingY={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                onClick={() => setIsWash(!isWash)}
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={20} fontWeight={500}>
                  Washing / Neutralization
                </Typography>
                <Typography>
                  {isWash ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
              </Stack>
              {isWash && (
                <Stack gap={2} width={"100%"}>
                  <Stack direction={"rows"} gap={2}>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Chemical
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        gr/L
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Dyeing vlot
                      </Typography>
                    </Stack>
                    <Stack width={"25%"}>
                      <Typography fontWeight={500} fontSize={14}>
                        Weight (kg)
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  {data?.neutralization?.length !== 0
                    ? data?.neutralization?.map((item) => (
                        <>
                          <Stack direction={"rows"} gap={2} key={item._id}>
                            <Stack width={"50%"}>
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                textTransform={"uppercase"}
                              >
                                {item.chemical}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {parseFloat(item.persentase).toLocaleString(
                                  "id-ID",
                                  { maximumFractionDigits: 10 },
                                )}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(data?.vlot_dyeing).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                            <Stack width={"50%"}>
                              <Typography fontWeight={400} fontSize={12}>
                                {(
                                  data?.vlot_dyeing *
                                  (item.persentase / 1000)
                                ).toLocaleString("id-ID", {
                                  maximumFractionDigits: 1,
                                })}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      ))
                    : "-"}
                </Stack>
              )}
            </Stack>
            {/* WASH TAB - END */}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default ProductionDetails;
