import React, {
  useState,
  // useEffect
} from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const MachineDetails = ({ open, setModalOpen, data, getMachines }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [temperature, setTemperature] = useState("");
  const [model, setModel] = useState("");
  const [chamber, setChamber] = useState("");
  const [capacity, setCapacity] = useState("");
  const [year, setYear] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [dailyTarget, setDailyTarget] = useState("");
  const [maximumProduction, setMaximumProduction] = useState("");
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);
  // console.log(data);

  const handleSubmit = () => {
    const edited = {
      capacity: capacity !== "" ? capacity : data.capacity,
      chamber: chamber !== "" ? chamber : data.chamber,
      machine_type: model !== "" ? model : data.machine_type,
      brand: manufacturer !== "" ? manufacturer : data.brand,
      name: name !== "" ? name : data.name,
      temperature_max: temperature !== "" ? temperature : data.temperature_max,
      year: year !== "" ? year : data.year,
      daily_target: dailyTarget !== "" ? dailyTarget : data.daily_target,
      objective: maximumProduction !== "" ? maximumProduction : data.objective,
      status: data?.status,
    };

    // console.log(edited);

    axiosInstance
      .put(`/api/machines/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Machine data updated!");
        getMachines();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleStatus = () => {
    const edited = {
      capacity: data.capacity,
      chamber: data.chamber,
      machine_type: data.machine_type,
      brand: data.brand,
      name: data.name,
      temperature_max: data.temperature_max,
      year: data.year,
      objective: data.objective,
      status: !data.status,
    };

    axiosInstance
      .put(`/api/machines/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Machine status updated!");
        getMachines();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  const onBack = () => {
    setIsEdit(false);
    setModalOpen(false);
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/machines/${data._id}`, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Machine has been deleted!");
        getMachines();
        setModalOpen(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        title={"Delete ?"}
        action={handleDelete}
        actionTitle={"Delete"}
        message={"This machine data is about to be deleted. Are you sure?"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Machine Details
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={isEdit ? "100%" : "50%"}>
                    <CustomTextField
                      name="name"
                      label="Machine name"
                      placeholder={data?.name}
                      onChange={(e) => setName(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Machine name
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      sx={{ textTransform: "uppercase" }}
                    >
                      {data?.name}
                    </Typography>
                  </Grid>
                )}
                {user.role !== "SuperAdmin" ? null : isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
                {user.role !== "Admin Product" ? null : isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="temperature"
                      label="Max. temperature"
                      placeholder={data?.temperature_max}
                      onChange={(e) => setTemperature(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Max. temperature
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.temperature_max}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="model"
                      label="Model"
                      placeholder={data?.machine_type}
                      onChange={(e) => setModel(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Model
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      sx={{ textTransform: "uppercase" }}
                    >
                      {data?.machine_type}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="chamber"
                      label="Chamber"
                      placeholder={data?.chamber}
                      onChange={(e) => setChamber(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Chamber
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.chamber}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="capacity"
                      label="Capacity"
                      placeholder={data?.capacity}
                      onChange={(e) => setCapacity(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Capacity
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.capacity}
                    </Typography>
                  </Grid>
                )}
              </Stack>

              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="daily_target"
                      label="Daily target (kg)"
                      placeholder={data?.daily_target}
                      onChange={(e) => setDailyTarget(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Daily target (kg)
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.daily_target}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="maximum_production"
                      label="Maximum production (kg)"
                      placeholder={data?.objective}
                      onChange={(e) => setMaximumProduction(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Maximum production (kg)
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.objective}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="manufacturer"
                      label="Manufacturer"
                      placeholder={data?.brand}
                      onChange={(e) => setManufacturer(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Manufacturer
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.brand}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="year"
                      label="Year"
                      placeholder={data?.year}
                      onChange={(e) => setYear(e.target.value)}
                      disabled={user.role !== "SuperAdmin" ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Year
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.year}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>

              {user.role === "Admin Product" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" ||
                (user.role === "Manager" && (
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#222",
                      color: "#FFF",
                      borderRadius: "6px",
                      ":hover": {
                        backgroundColor: "#444",
                      },
                    }}
                    variant="contained"
                    onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                  >
                    {isEdit ? "Save" : "Edit"}
                  </Button>
                ))}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default MachineDetails;
