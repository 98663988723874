import React, {
  useState,
  // useEffect
} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  InputAdornment,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import toast, { Toaster } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const DetailAdmin = ({ open, setModalOpen, data, getAdmin }) => {
  // console.log(data);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [adminData, setAdminData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [editedPassword, setEditedPassword] = useState("");
  const [editedPhone, setEditedPhone] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const successEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const getPassword = async () => {
    await axiosInstance
      .get(`/api/admins/${data._id}`, authentication)
      .then((res) => {
        successEffect.play();
        setAdminData(res.data);
        // console.log(res.data);
        setShowPassword(true);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const updateAdmin = async () => {
    const edited = {
      phone_number: editedPhone !== "" ? editedPhone : data?.phone_number,
      password: editedPassword !== "" ? editedPassword : data?.password,
      status: data?.status,
    };
    await axiosInstance
      .put(`/api/admins/${data._id}`, edited, authentication)
      .then(() => {
        toast.success("Admin data has been changed!");
        setShowPassword(false);
        getAdmin();
        handleBack();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleBack = () => {
    setIsEdit(false);
    setModalOpen(false);
    setShowPassword(false);
  };

  const handleStatus = () => {
    const edited = {
      phone_number: data?.phone_number,
      password: data?.password,
      status: !data?.status,
    };

    axiosInstance
      .put(`/api/admins/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        toast.success("Admin status updated!");
        getAdmin();
        setModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Admin Detail
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      disabled
                      value={data?.full_name}
                      sx={{ backgroundColor: "#222" }}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Name
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.full_name}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="role"
                      label="Role"
                      disabled
                      value={data?.role}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Role
                    </Typography>
                    {data?.role === "SuperAdmin" ? (
                      <button
                        style={{
                          color: "#222",
                          fontFamily: "Murecho",
                          fontWeight: "500",
                          fontSize: "12px",
                          border: "1px solid #222",
                          borderRadius: "6px",
                          backgroundColor: "#fff",
                        }}
                      >
                        {data?.role}
                      </button>
                    ) : data?.role === "Manager" ? (
                      <button
                        style={{
                          color: "#CB3A31",
                          fontFamily: "Murecho",
                          fontWeight: "500",
                          fontSize: "12px",
                          border: "1px solid #EEB4B0",
                          borderRadius: "6px",
                          backgroundColor: "#FFF4F2",
                        }}
                      >
                        {data?.role}
                      </button>
                    ) : data?.role === "Admin Product" ? (
                      <button
                        style={{
                          color: "#20573D",
                          fontFamily: "Murecho",
                          fontWeight: "500",
                          fontSize: "12px",
                          border: "1px solid #B8DBCA",
                          borderRadius: "6px",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        Production Admin
                      </button>
                    ) : data?.role === "Admin Warehouse" ? (
                      <button
                        style={{
                          color: "#3267E3",
                          fontFamily: "Murecho",
                          fontWeight: "500",
                          fontSize: "12px",
                          border: "1px solid #B1C5F6",
                          borderRadius: "6px",
                          backgroundColor: "#F0F3FF",
                        }}
                      >
                        Warehouse Admin
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#155C33",
                          fontFamily: "Murecho",
                          fontWeight: "500",
                          fontSize: "12px",
                          border: "none",
                          borderRadius: "6px",
                          backgroundColor: "#DEF8E9",
                        }}
                      >
                        {data?.role}
                      </button>
                    )}
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="email"
                      label="Email"
                      disabled
                      value={data?.email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Email
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.email}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="phone"
                      label="Phone"
                      placeholder={data?.phone_number}
                      //   disabled
                      // value={data?.phone_number}
                      onChange={(e) => setEditedPhone(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Phone
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.phone_number}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Grid
                  item
                  width={"50%"}
                  sx={{ display: isEdit ? "none" : "block" }}
                >
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Status
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <StatusSwitch
                      status={data?.status}
                      changeStatus={() => handleStatus()}
                    />
                    {data?.status ? (
                      <button
                        style={{
                          color: "#21573D",
                          fontFamily: "Murecho",
                          fontWeight: "600",
                          fontSize: "12px",
                          border: "1px solid #B8DBCA",
                          borderRadius: "5px",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        Active
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#222",
                          fontFamily: "Murecho",
                          fontWeight: "600",
                          fontSize: "12px",
                          border: "1px solid #222",
                          borderRadius: "5px",
                          backgroundColor: "#FFF",
                        }}
                      >
                        Non-active
                      </button>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  width={"50%"}
                  pl={isEdit ? 0 : 0.5}
                  // sx={{ display: isEdit ? "none" : "block" }}
                >
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Password
                  </Typography>
                  {showPassword ? (
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <Typography>{adminData?.enc_password}</Typography>
                    </Stack>
                  ) : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#222",
                        color: "#FFF",
                        borderRadius: "6px",
                        fontSize: "12px",
                        padding: 0.5,
                        width: "fit-content",
                        ":hover": {
                          backgroundColor: "#444",
                        },
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() => getPassword()}
                    >
                      See Password
                    </Button>
                  )}
                </Grid>
              </Stack>
              {isEdit === true && (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="password"
                      label="New password"
                      type={isPassword ? "password" : "text"}
                      onChange={(e) => setEditedPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isPassword ? (
                              <VisibilityIcon
                                onClick={() => setIsPassword(false)}
                                sx={{ cursor: "pointer", marginLeft: 0.5 }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setIsPassword(true)}
                                sx={{ cursor: "pointer", marginLeft: 0.5 }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleBack()}
              >
                Back
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                type="submit"
                variant="contained"
                onClick={() => (isEdit ? updateAdmin() : setIsEdit(!isEdit))}
              >
                {isEdit ? "Save" : "Edit"}
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default DetailAdmin;
