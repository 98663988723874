import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import CustomNoRowsOverlay from "../../components/NoRows";
// import CustomTextField from "components/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Renderer from "./_components/PDFRenderer";
import moment from "moment";
import toast from "react-hot-toast";
import successSound from "../../assets/audio/success.mp3";
import errorSound from "../../assets/audio/error.mp3";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "machine_name",
    headerClassName: "header",
    headerName: "Machine",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "customer_name",
    headerClassName: "header",
    headerName: "Customer",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "fabric_code",
    headerClassName: "header",
    headerName: "Fabric Code",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "purchase_order",
    headerClassName: "header",
    headerName: "PO",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "code_production",
    headerClassName: "header",
    headerName: "Production Code",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "color",
    headerClassName: "header",
    headerName: "Color",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "code_recipe",
    headerClassName: "header",
    headerName: "Recipe Code",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "roll",
    headerClassName: "header",
    headerName: "Roll",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "weight",
    headerClassName: "header",
    headerName: "Fabric Weight (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 1,
        })}
      </Typography>
    ),
  },
  {
    field: "process_name",
    headerClassName: "header",
    headerName: "Process Name",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "start_date",
    headerClassName: "header",
    headerName: "Start Date",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {moment(value).format("DD/MM/YYYY")}
      </Typography>
    ),
  },
  {
    field: "start_time",
    headerClassName: "header",
    headerName: "Time",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "finish_date",
    headerClassName: "header",
    headerName: "Finish Date",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {moment(value).format("DD/MM/YYYY")}
      </Typography>
    ),
  },
  {
    field: "finish_time",
    headerClassName: "header",
    headerName: "Time",
    width: 100,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
];

const ProductionReport = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  // const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cleanStartDate, setCleanStartDate] = useState("");
  const [cleanEndDate, setCleanEndDate] = useState("");
  const todayDate = new Date();
  const formatDate = moment(todayDate).format("DD/MM/YYYY");
  const [customers, setCustomers] = useState([]);
  // console.log(formatDate);

  const limit = moment(todayDate).add(0, "days");
  const formattedLimit = moment(limit).format("YYYY-MM-DD");

  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);

  const getReports = async () => {
    await axiosInstance
      .get(
        `/api/production_lists/reports?start_date=${formatDate}&finish_date=${formatDate}`,
        authentication,
      )
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCustomers = () => {
    axiosInstance
      .get("/api/customers", authentication)
      .then((res) => {
        // console.log(res.data);
        setCustomers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fnEnd = (e) => {
    setEndDate(moment(e).format("DD/MM/YYYY"));
    setCleanEndDate(moment(e).format("L"));
  };

  const fnStart = (e) => {
    setStartDate(moment(e).format("DD/MM/YYYY"));
    setCleanStartDate(moment(e).format("L"));
  };

  const getDataByPeriod = async () => {
    await axiosInstance
      .get(
        `/api/production_lists/reports?start_date=${startDate}&finish_date=${endDate}`,
        authentication,
      )
      .then((res) => {
        // console.log(res.data);
        if (res?.data.length === 0) {
          errorEffect.play();
          toast.error("No data found");
        } else {
          successEffect.play();
          toast.success(`${res?.data.length} data found`);
        }
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  useEffect(() => {
    getReports();
    getCustomers();
  }, []);

  return (
    <Box flex="1" sx={{ backgroundColor: "#f6f6f6" }}>
      <Renderer
        open={modalOpen}
        setModalOpen={setModalOpen}
        data={data}
        startDate={startDate}
        endDate={endDate}
        today={formatDate}
        startPeriod={cleanStartDate}
        endPeriod={cleanEndDate}
        customers={customers}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Production Report
        </Typography>
      </Stack>
      <Stack
        paddingX={3}
        gap={2}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
          <Stack gap={1}>
            <Typography fontSize={14}>Start date</Typography>
            <Stack sx={{ backgroundColor: "#FFF", width: "full" }}>
              {/* <CustomTextField
                name="date"
                type="date"
                onChange={(e) => fnStart(e.target.value)}
                sx={{ backgroundColor: "#FFF" }}
              /> */}
              <input
                name="date"
                type="date"
                onChange={(e) => fnStart(e.target.value)}
                style={{
                  backgroundColor: "#FFF",
                  height: "28px",
                  width: "150px",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  border: "1px solid #c8c8c8",
                  borderRadius: "5px",
                  padding: "5px 12px",
                }}
                max={formattedLimit}
              />
            </Stack>
          </Stack>
          <Stack gap={1}>
            <Typography fontSize={14}>End date</Typography>
            <Stack sx={{ backgroundColor: "#FFFF" }}>
              <input
                name="date"
                type="date"
                onChange={(e) => fnEnd(e.target.value)}
                style={{
                  backgroundColor: "#FFF",
                  height: "28px",
                  width: "150px",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  border: "1px solid #c8c8c8",
                  borderRadius: "5px",
                  padding: "5px 12px",
                }}
                max={formattedLimit}
              />
            </Stack>
          </Stack>
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              height: "40px",
              color: "#FFF",
              bottom: 0,
              marginTop: "28px",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            disabled={startDate === "" || endDate === "" ? true : false}
            onClick={() => getDataByPeriod()}
          >
            Get Data
          </Button>
        </Stack>
        <Stack>
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#FFF",
              height: "40px",
              color: "#222",
              bottom: 0,
              marginTop: "28px",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={() => setModalOpen(true)}
            disabled={data.length !== 0 ? false : true}
          >
            Preview PDF
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#FFF",
              color: "#222",
            },
            "& .header-font": {
              backgroundColor: "#F6F6F6",
              color: "#F6F6F6",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            // onRowClick={(rows) => navigate(`/master/customers/${rows.id}`)}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "none",
              color: "#535252",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ProductionReport;
