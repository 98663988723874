import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import WestIcon from "@mui/icons-material/West";
import CustomTextField from "components/TextField";
import steps from "assets/dummy/steps.json";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import RecipeSummary from "./RecipeSummary";
import moment from "moment";
import { useParams } from "react-router-dom";

const ITEM_HEIGHT = 50;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditRecipe = () => {
  const { id } = useParams();
  const [recipeData, setRecipeData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [isSummary, setIsSummary] = useState(false);
  const navigate = useNavigate();
  const [scouringItems, setScouringItems] = useState([]);
  const [firstDyeItems, setFirstDyeItems] = useState([]);
  const [firstDipItems, setFirstDipItems] = useState([]);
  const [secondDyeItems, setSecondDyeItems] = useState([]);
  const [secondDipItems, setSecondDipItems] = useState([]);
  const [washingItems, setWashingItems] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("Choose a process");
  const [processId, setProcessId] = useState("");
  const [processCode, setProcessCode] = useState(
    recipeData?.process?.process_code,
  );
  const [processList, setProcessList] = useState([]);
  const [aux, setAux] = useState([]);
  const [dye, setDye] = useState([]);
  const [year, setYear] = useState("");
  const [fullDate, setFullDate] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [color, setColor] = useState("");
  const [pantone, setPantone] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const user = JSON.parse(localStorage.getItem("admin_data"));

  const [existingScouring, setExistingScouring] = useState([]);

  const getProcesses = () => {
    axiosInstance
      .get("/api/processes", authentication)
      .then((res) => {
        // console.log(res.data);
        setProcessList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecipe = async () => {
    await axiosInstance
      .get(`/api/recipes/${id}`, authentication)
      .then((res) => {
        // console.log(res.data);
        setRecipeData(res.data);
        existings();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        // console.log(res.data);
        setAux(res.data.filter((chm) => chm.category === "Auxiliaries"));
        setDye(res.data.filter((chm) => chm.category === "Dyestuff"));
        // console.log(aux);
        // console.log(dye);
        // setProcessList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const codeChecking = async () => {
    const data = {
      code_recipe: `${year}
      ${processId}
      ${orderNo}`,
    };
    await axiosInstance
      .post("/api/recipes/check-code", data, authentication)
      .then(() => {
        // console.log(res.data);
        setIsCodeValid(true);
        toast.success("Recipe code is valid. You may proceed with this code.");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "This recipe code is taken. Please use another set of code.",
        );
      });
  };

  useEffect(() => {
    getProcesses();
    getChemicals();
    getRecipe();
  }, []);

  const existings = () => {
    recipeData?.scoring?.map((scr) => {
      existingScouring.push(scr.chemical);
    });
    // console.log(existingScouring);
  };

  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={scouringItems}
            setItems={setScouringItems}
          />
        );
      case 1:
        return (
          <SelectChemical
            data={dye}
            step={step}
            items={firstDyeItems}
            setItems={setFirstDyeItems}
          />
        );
      case 2:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={firstDipItems}
            setItems={setFirstDipItems}
          />
        );
      case 3:
        return (
          <SelectChemical
            data={dye}
            step={step}
            items={secondDyeItems}
            setItems={setSecondDyeItems}
          />
        );
      case 4:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={secondDipItems}
            setItems={setSecondDipItems}
          />
        );
      case 5:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={washingItems}
            setItems={setWashingItems}
          />
        );
      default:
        return <p>Please proceed by clicking the "Save" button.</p>;
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function HorizontalStepper() {
    return (
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.data.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Stack py={5}>{renderStepContent(activeStep)}</Stack>
        <Stack direction={"row"} justifyContent={"end"} gap={1}>
          {activeStep !== 0 && (
            <button
              style={{
                background: "#FFF",
                color: "#222",
                padding: "10px 20px 10px 20px",
                borderRadius: "3px",
                border: "1px solid #CCC",
                cursor: "pointer",
              }}
              onClick={handleBack}
            >
              Back
            </button>
          )}
          {activeStep !== 6 && (
            <button
              style={{
                background: "#222",
                color: "#FFF",
                padding: "10px 20px 10px 20px",
                borderRadius: "3px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Next
            </button>
          )}
          {activeStep === 6 && (
            <button
              style={{
                background: "#222",
                color: "#FFF",
                padding: "10px 20px 10px 20px",
                borderRadius: "3px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setIsSummary(!isSummary)}
            >
              Save
            </button>
          )}
        </Stack>{" "}
      </Box>
    );
  }

  const onProcess = (dt) => {
    setSelectedProcess(dt.process_name);
    setProcessCode(dt.process_code);
    setProcessId(dt._id);
  };

  const getYear = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setFullDate(formattedDate);
    // console.log(formattedDate);
    const extractYear = date.toString().slice(2, 4);
    setYear(extractYear);
  };

  function SelectChemical({ data, step, items }) {
    const [scouring, setScouring] = useState([]);
    const [firstDye, setFirstDye] = useState([]);
    const [firstDip, setFirstDip] = useState([]);
    const [secondDye, setSecondDye] = useState([]);
    const [secondDip, setSecondDip] = useState([]);
    const [washing, setWashing] = useState([]);
    const [amount, setAmount] = useState("");

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      step === 0
        ? setScouring(typeof value === "string" ? value.split(",") : value)
        : step === 1
        ? setFirstDye(typeof value === "string" ? value.split(",") : value)
        : step === 2
        ? setFirstDip(typeof value === "string" ? value.split(",") : value)
        : step === 3
        ? setSecondDye(typeof value === "string" ? value.split(",") : value)
        : step === 4
        ? setSecondDip(typeof value === "string" ? value.split(",") : value)
        : setWashing(typeof value === "string" ? value.split(",") : value);
    };

    const addAmount = (c) => {
      const obj = { chemical: c, persentase: amount };
      items.push(obj);
      toast.success(`${c} has been added successfully!`);
      console.log(items);
    };

    return (
      <>
        <div>
          <Toaster position="top-center" reverseOrder={false} />
          <FormControl sx={{ my: 1, width: "100%" }}>
            <Select
              id="demo-multiple-checkbox"
              multiple
              value={
                // selectedItems
                step === 0
                  ? existingScouring
                  : step === 1
                  ? firstDye
                  : step === 2
                  ? firstDip
                  : step === 3
                  ? secondDye
                  : step === 4
                  ? secondDip
                  : washing
              }
              onChange={handleChange}
              input={<CustomTextField select label={"Choose chemicals"} />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {data?.map((item) => (
                <MenuItem key={item?._id} value={item?.chemical_name}>
                  <Checkbox
                    checked={
                      step === 0
                        ? scouring?.indexOf(item.chemical_name) > -1
                        : step === 1
                        ? firstDye.indexOf(item.chemical_name) > -1
                        : step === 2
                        ? firstDip.indexOf(item.chemical_name) > -1
                        : step === 3
                        ? secondDye.indexOf(item.chemical_name) > -1
                        : step === 4
                        ? secondDip.indexOf(item.chemical_name) > -1
                        : washing.indexOf(item.chemical_name) > -1
                    }
                  />
                  <ListItemText primary={item.chemical_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {step === 0 && (
          <Stack>
            {recipeData?.scoring?.length !== 0 &&
              recipeData?.scoring?.map((c) => (
                <Stack
                  direction={"row"}
                  gap={2}
                  mt={2}
                  alignItems={"center"}
                  key={c._id}
                >
                  <CustomTextField value={c.chemical} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder={c.persentase}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 1 && (
          <Stack>
            {firstDye.length !== 0 &&
              firstDye?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>%</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 2 && (
          <Stack>
            {firstDip.length !== 0 &&
              firstDip?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 3 && (
          <Stack>
            {secondDye.length !== 0 &&
              secondDye?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>%</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 4 && (
          <Stack>
            {secondDip.length !== 0 &&
              secondDip?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 5 && (
          <Stack>
            {washing.length !== 0 &&
              washing?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "10px 20px 10px 20px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                    disabled={items.find((it) =>
                      it.chemical === c ? true : false,
                    )}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
      </>
    );
  }

  // Selecting chemicals ends here.

  return (
    <>
      {isSummary ? (
        <RecipeSummary
          scouring={scouringItems}
          fdye={firstDyeItems}
          fdip={firstDipItems}
          sdye={secondDyeItems}
          sdip={secondDipItems}
          wash={washingItems}
          isReview={isSummary}
          setIsReview={setIsSummary}
          color={color}
          pantone={pantone}
          code={`${year}${processCode}${orderNo}`}
          process={selectedProcess}
          processId={processId}
          inputPerson={user.full_name}
          fullDate={fullDate}
          orderNo={orderNo}
        />
      ) : (
        <Box
          flex="1"
          sx={{ backgroundColor: "#F6F6F6", height: "screen" }}
          paddingY={2}
        >
          <Stack
            direction="row"
            justifyContent="start"
            paddingX={1}
            paddingY={2}
            alignItems={"center"}
          >
            <Button
              // startIcon={<WestIcon />}
              sx={{
                textTransform: "capitalize",
                color: "#6D7688",
                fontWeight: "400",
                width: "fit-content",
              }}
              onClick={() => navigate("/recipes/list")}
            >
              <WestIcon />
            </Button>
            <Typography variant="h4" fontSize={"24px"} fontWeight="500">
              {recipeData?.code_recipe}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            // sx={{ backgroundColor: "#FFF" }}
            paddingY={1}
            gap={2}
            mb={4}
          >
            <Stack
              padding={3}
              sx={{
                backgroundColor: "#FFF",
                width: "20%",
                borderRadius: "15px",
              }}
              gap={2}
              mb={4}
            >
              <Stack>
                <label
                  htmlFor="year"
                  style={{ fontWeight: 400, color: "#666" }}
                >
                  Date
                </label>
                <input
                  id="year"
                  type="date"
                  min="2001-01-01"
                  max="2050-12-30"
                  onChange={(e) => getYear(e.target.value)}
                  placeholder="Input the order number"
                  style={{
                    marginTop: 7,
                    padding: "6px 15px",
                    border: "1px solid #c8c8c8",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontFamily: "Inter",
                  }}
                />
              </Stack>
              {/* <Stack>
                <CustomTextField
                  name="process"
                  label="Process"
                  placeholder="Choose a process"
                  select
                  value={selectedProcess}
                  onChange={(e) => setSelectedProcess(e.target.value)}
                >
                  <MenuItem value={"Choose a process"}>
                    Choose a process
                  </MenuItem>
                  {processList.map((dt) =>
                    dt.status ? (
                      <MenuItem
                        key={dt._id}
                        value={dt.process_name}
                        onClick={() => onProcess(dt)}
                      >
                        {dt.process_name}
                      </MenuItem>
                    ) : null,
                  )}
                </CustomTextField>
              </Stack> */}
              <Stack>
                {/* <label
                  htmlFor="year"
                  style={{ fontWeight: 400, color: "#666" }}
                >
                  Color Picker
                </label> */}
                <CustomTextField
                  id="color"
                  type="color"
                  label={"Color picker"}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="color"
                  label="Color Name"
                  placeholder={recipeData?.color}
                  sx={{ height: "50px", width: "50px" }}
                  onChange={(e) => setColor(e.target.value)}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="color"
                  label="Pantone"
                  placeholder={recipeData?.pantone}
                  onChange={(e) => setPantone(e.target.value)}
                />
              </Stack>
              {/* <Stack>
                <CustomTextField
                  name="process_code"
                  label="Process code"
                  value={
                    processCode !== "" ? processCode : "Choose a process first"
                  }
                  disabled
                />
              </Stack> */}
              {/* <Stack>
                <CustomTextField
                  name="no"
                  label="Number"
                  placeholder="Input the order number"
                  type="number"
                  min="0"
                  onChange={(e) => setOrderNo(e.target.value)}
                />
                <label
                  htmlFor="order_number"
                  style={{ fontWeight: 400, color: "#666" }}
                >
                  Order Number
                </label>
                <input
                  id="order_number"
                  type="number"
                  min={0}
                  max={999}
                  maxLength={3}
                  placeholder="Input the order number"
                  style={{
                    marginTop: 7,
                    padding: "10px 15px",
                    border: "1px solid #c8c8c8",
                    borderRadius: "4px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => setOrderNo(e.target.value)}
                />
              </Stack> */}
              {/* <Stack>
                <Typography variant="h7" fontWeight={600}>
                  Recipe Code
                </Typography>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    mt={1}
                    color={
                      processCode === "" || orderNo === "" ? "#d1d1d1" : "#222"
                    }
                  >
                    {year !== "" ? year : null}
                    {processCode}
                    {orderNo}
                  </Typography>
                  {year === "" ||
                  processCode === "" ||
                  orderNo === "" ? null : (
                    <button
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "4px",
                        border: "none",
                        fontFamily: "Inter",
                        height: "30px",
                        padding: "5px 10px",
                        backgroundColor: "#222",
                        cursor: "pointer",
                        color: "#FFF",
                        marginTop: "8px",
                        ":hover": {
                          backgroundColor: "#444",
                          color: "#FFF",
                        },
                      }}
                      onClick={() => codeChecking()}
                    >
                      Check code
                    </button>
                  )}
                </Stack>
              </Stack> */}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              paddingX={3}
              sx={{ backgroundColor: "#FFF", borderRadius: "15px" }}
              paddingY={4}
              gap={2}
              width={"75%"}
              height={"fit-content"}
            >
              <HorizontalStepper
                isComplete={isSummary}
                setIsComplete={setIsSummary}
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default EditRecipe;
