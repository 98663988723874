import { Grid, Stack } from "@mui/material";
import React from "react";
import logo from "../../../assets/erecipe_logo.png";

function Header({ forgotPassword, passwordToken }) {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        mb={6}
        mt={2}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          // style={{
          //   width: "100%",
          //   textAlign: "center",
          //   flexDirection: "row",
          //   justifyContent: "flex-start",
          // }}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          gap={1}
        >
          <img
            // src={
            //   "https://ik.imagekit.io/6tebrod5l/erecipe/product%20logo_Mxl9icN78.png?updatedAt=1699616666202"
            // }
            src={logo}
            width={30}
            height="100%"
            alt="E-Recipe"
            srcSet=""
          />
          <p
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Inter",
            }}
          >
            E-recipe Dashboard
          </p>
        </Stack>
      </Grid>
      {/* <Stack direction="row" justifyContent="center">
        <Typography
          mx={1}
          sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}
        >
          Selamat Datang
        </Typography>
      </Stack>
      <Grid item xs={12} sx={{ textAlign: "center" }} mb={4}>
        <Typography
          sx={{ color: "#292C33", fontSize: "16px", fontWeight: "400" }}
        >
          {forgotPassword
            ? "Mohon masukkan email terdaftar Anda"
            : passwordToken
            ? "Silahkan masukkan password baru Anda"
            : "Gunakan email dan password Anda untuk masuk"}
        </Typography>
      </Grid> */}
    </Grid>
  );
}

export default Header;
