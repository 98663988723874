import React, { useState } from "react";
import {
  Stack,
  Box,
  Button,
  Avatar,
  Typography,
  ClickAwayListener,
  Fade,
  Popper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ConfirmationModal from "./ConfirmationModal";
import logo from "../assets/erecipe_logo.png";
import confirmationSound from "../assets/audio/confirmation.mp3";

function Navbar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const roboUrl = "https://robohash.org/";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const confirmationEffect = new Audio(confirmationSound);

  const Logout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
    localStorage.removeItem("timeStamp");
    return window.location.reload();
  };

  const onLogout = () => {
    confirmationEffect.play();
    setOpenModal(true);
  };

  return (
    <>
      <ConfirmationModal
        open={openModal}
        setOpen={setOpenModal}
        action={Logout}
        title={"Logout"}
        message={"You are about to exit the app. Proceed?"}
        actionTitle={"Yes"}
        isWarning={false}
      />
      <Stack
        height="56px"
        width="100%"
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "fixed",
          top: 0,
          color: "#10448E",
          backgroundColor: "#FFF",
          zIndex: "100",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Box
            width="240px"
            paddingX="16px"
            sx={{ backgroundColor: "#FFF", borderTopRightRadius: "10px" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              height="100%"
              spacing={1}
            >
              <Avatar
                src={logo}
                sx={{ borderRadius: 0, height: "30px", width: "30px" }}
              />
              <Typography fontSize="16px" color="#222" fontWeight="600">
                E-Recipe Dashboard
              </Typography>
            </Stack>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingRight="54px"
          >
            <Avatar
              src={`${roboUrl}${user.full_name}`}
              sx={{
                background: "#222",
                cursor: "pointer",
              }}
              aria-describedby={id}
              onClick={handleClick}
            />
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Box
                      sx={{
                        p: 2,
                        mr: 2,
                        mt: 1,
                        width: "200px",
                        borderRadius: "10px",
                        bgcolor: "background.paper",
                        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                      }}
                    >
                      <Stack>
                        <Stack>
                          <Stack direction="row" alignItems={"center"}>
                            <Stack mr={1}>
                              <Avatar
                                src={`${roboUrl}${user?.full_name}`}
                                sx={{
                                  backgroundColor: `#222`,
                                  width: "40px",
                                  height: "40px",
                                }}
                                aria-describedby={id}
                                onClick={handleClick}
                              />
                            </Stack>
                            <Stack>
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                              >
                                {user.full_name}
                              </Typography>
                              {user.role === "Manager" ? (
                                <button
                                  style={{
                                    color: "#CB3A31",
                                    fontFamily: "Murecho",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    border: "1px solid #EEB4B0",
                                    borderRadius: "6px",
                                    backgroundColor: "#FFF4F2",
                                  }}
                                >
                                  <span>{user.role}</span>
                                </button>
                              ) : user.role === "Admin Product" ? (
                                <button
                                  style={{
                                    color: "#20573D",
                                    fontFamily: "Murecho",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    border: "1px solid #B8DBCA",
                                    borderRadius: "6px",
                                    backgroundColor: "#F7F7F7",
                                  }}
                                >
                                  <span>Production Admin</span>
                                </button>
                              ) : user.role === "SuperAdmin" ? (
                                <button
                                  style={{
                                    color: "#222",
                                    fontFamily: "Murecho",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    border: "1px solid #222",
                                    borderRadius: "6px",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <span>{user.role}</span>
                                </button>
                              ) : user.role === "Admin Warehouse" ? (
                                <button
                                  style={{
                                    color: "#3267E3",
                                    fontFamily: "Murecho",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    border: "1px solid #B1C5F6",
                                    borderRadius: "6px",
                                    backgroundColor: "#F0F3FF",
                                  }}
                                >
                                  <span>Warehouse Admin</span>
                                </button>
                              ) : (
                                <button
                                  style={{
                                    color: "#155C33",
                                    fontFamily: "Murecho",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    border: "none",
                                    borderRadius: "6px",
                                    backgroundColor: "#DEF8E9",
                                  }}
                                >
                                  <span>{user.role}</span>
                                </button>
                              )}
                            </Stack>
                          </Stack>
                          <Divider sx={{ my: 2 }} />
                          <Stack>
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                background: "#222",
                                color: "#FFF",
                                outline: "none",
                                borderRadius: "10px",
                                ":hover": { background: "#444" },
                              }}
                              onClick={() => onLogout()}
                              startIcon={<LogoutIcon />}
                            >
                              Logout
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default Navbar;
