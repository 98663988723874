import React, {
  useState,
  // useEffect
} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  // MenuItem,
  Typography,
  Stack,
  Autocomplete,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const types = [
  {
    _id: "0",
    name: "REACTIVE",
    value: "REACTIVE",
  },
  {
    _id: "1",
    name: "DISPERSE",
    value: "DISPERSE",
  },
];

const categories = [
  {
    _id: "0",
    name: "Auxiliaries",
    value: "Auxiliaries",
  },
  {
    _id: "1",
    name: "Dyestuff",
    value: "Dyestuff",
  },
];

const packagings = [
  {
    _id: "0",
    name: "BOX",
    value: "BOX",
  },
  {
    _id: "1",
    name: "TANK",
    value: "TANK",
  },
  {
    _id: "2",
    name: "SACK",
    value: "SACK",
  },
  {
    _id: "3",
    name: "BARREL",
    value: "BARREL",
  },
  {
    _id: "4",
    name: "DRUM",
    value: "DRUM",
  },
];

const forms = [
  {
    _id: "0",
    name: "POWDER",
    value: "POWDER",
  },
  {
    _id: "1",
    name: "LIQUID",
    value: "LIQUID",
  },
  {
    _id: "2",
    name: "CRYSTAL",
    value: "CRYSTAL",
  },
  {
    _id: "3",
    name: "FLAKE",
    value: "FLAKE",
  },
];

const ChemicalDetails = ({ open, setModalOpen, data, getChemicals }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState(`${data?.type}`);
  const [packaging, setPackaging] = useState("Box");
  const [form, setForm] = useState("Choose form");
  const [isEdit, setIsEdit] = useState(false);
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);
  // console.log(data?.type);

  const handleStatus = () => {
    const edited = {
      chemical_name: name !== "" ? name : data.chemical_name,
      amount: weight !== "" ? weight : data.amount,
      type: type !== "" ? type : data.type,
      category: category !== "" ? category : data.category,
      packaging: packaging !== "" ? packaging : data.packaging,
      shape: form !== "" ? form : data.shape,
      status: !data.status,
    };

    axiosInstance
      .put(`/api/chemicals/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Chemical status updated!");
        getChemicals();
        setModalOpen(false);
        setIsEdit(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleSubmit = () => {
    const edited = {
      chemical_name: name !== "" ? name : data.chemical_name,
      amount: weight !== "" ? weight : data.amount,
      type: type !== "" ? type : data.type,
      category: category !== "" ? category : data.category,
      packaging: packaging !== "" ? packaging : data.packaging,
      shape: form !== "" ? form : data.shape,
      status: data.status,
    };

    axiosInstance
      .put(`/api/chemicals/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Chemical data has been successfully updated!");
        getChemicals();
        setModalOpen(false);
        setIsEdit(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onBack = () => {
    setIsEdit(false);
    setModalOpen(false);
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/chemicals/${data._id}`, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("This chemical has been deleted!");
        getChemicals();
        setModalOpen(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        action={handleDelete}
        actionTitle={"Delete"}
        title={"Delete ?"}
        message={"This chemical is about to be deleted. Are you sure?"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Chemical Detail
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="chemical_name"
                      label="Chemical Name"
                      placeholder={data?.chemical_name}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Chemical Name
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.chemical_name}
                    </Typography>
                  </Grid>
                )}
                {user.role === "SuperAdmin" ||
                user.role === "Admin Warehouse" ? (
                  isEdit ? (
                    <Grid item width={"50%"}>
                      <Autocomplete
                        options={categories?.map((ct) => ct.name)}
                        onChange={(event, newValue) => {
                          categories.filter((ct) =>
                            `${ct.value}` === newValue
                              ? setCategory(ct.value)
                              : null,
                          );
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            placeholder={`${data?.category}`}
                            label="Category"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item width={"50%"}>
                      <Typography fontSize={12} color={"#444"} fontWeight={500}>
                        Status
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <StatusSwitch
                          status={data?.status}
                          changeStatus={() => handleStatus()}
                        />
                        {data?.status ? (
                          <button
                            style={{
                              color: "#21573D",
                              fontFamily: "Murecho",
                              fontWeight: "600",
                              fontSize: "12px",
                              border: "1px solid #B8DBCA",
                              borderRadius: "5px",
                              backgroundColor: "#F7F7F7",
                            }}
                          >
                            Active
                          </button>
                        ) : (
                          <button
                            style={{
                              color: "#222",
                              fontFamily: "Murecho",
                              fontWeight: "600",
                              fontSize: "12px",
                              border: "1px solid #222",
                              borderRadius: "5px",
                              backgroundColor: "#FFF",
                            }}
                          >
                            Non-active
                          </button>
                        )}
                      </Stack>
                    </Grid>
                  )
                ) : null}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    {/* <CustomTextField
                      name="packaging"
                      label="Packaging"
                      placeholder="Choose the type of the packaging"
                      onChange={(e) => setPackaging(e.target.value)}
                      value={packaging}
                      select
                    >
                      {packagings.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField> */}
                    <Autocomplete
                      options={packagings?.map((pk) => pk.name)}
                      onChange={(event, newValue) => {
                        packagings.filter((pk) =>
                          `${pk.value}` === newValue
                            ? setPackaging(pk.value)
                            : null,
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder={`${data?.packaging}`}
                          label="Packaging"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Packaging
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      textTransform={"uppercase"}
                    >
                      {data?.packaging}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    {/* <CustomTextField
                      name="form"
                      label="Form"
                      placeholder="Choose the form of the chemical"
                      onChange={(e) => setForm(e.target.value)}
                      value={form}
                      select
                    >
                      <MenuItem value={"Choose form"}>Choose form</MenuItem>
                      {forms.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField> */}
                    <Autocomplete
                      options={forms?.map((fm) => fm.name)}
                      onChange={(event, newValue) => {
                        forms.filter((fm) =>
                          `${fm.value}` === newValue ? setForm(fm.value) : null,
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder={`${data?.shape}`}
                          label="Form"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Form
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.shape}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit === true && data.category === "Dyestuff" ? (
                  <Grid item width={"50%"}>
                    {/* <CustomTextField
                      name="type"
                      label="Type"
                      placeholder="Choose the type of the chemical"
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                      select
                    >
                      <MenuItem value={"Choose type"}>Choose type</MenuItem>
                      {types.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField> */}
                    <Autocomplete
                      options={types?.map((ty) => ty.name)}
                      onChange={(event, newValue) => {
                        types.filter((ty) =>
                          `${ty.value}` === newValue ? setType(ty.value) : null,
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder={`${data?.type}`}
                          label="Type"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) : data?.category === "Dyestuff" ? (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Type
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.type}
                    </Typography>
                  </Grid>
                ) : null}
                {isEdit ? (
                  <Grid
                    item
                    width={data?.category === "Dyestuff" ? "50%" : "100%"}
                  >
                    <CustomTextField
                      name="weight"
                      label="Content Weight (kg)"
                      placeholder={data?.amount}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Content Weight (kg)
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.amount}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "Admin Warehouse" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default ChemicalDetails;
