import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import WestIcon from "@mui/icons-material/West";
import CustomTextField from "components/TextField";
import steps from "assets/dummy/steps.json";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import RecipeSummary from "./RecipeSummary";
import moment from "moment";
import errorSound from "../../../assets/audio/error.mp3";
import successSound from "../../../assets/audio/success.mp3";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import addedSound from "../../../assets/audio/added.mp3";
import updateSound from "../../../assets/audio/update.mp3";
import confirmationSound from "../../../assets/audio/confirmation.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const addedEffect = new Audio(addedSound);
const updateEffect = new Audio(updateSound);
const confirmationEffect = new Audio(confirmationSound);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const ITEM_HEIGHT = 50;

// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const AddRecipe = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [validCode, setValidCode] = useState("");
  const isLastStep = activeStep === steps.length - 1;
  const [isSummary, setIsSummary] = useState(false);
  const navigate = useNavigate();
  const [scouringItems, setScouringItems] = useState([]);
  const [firstDyeItems, setFirstDyeItems] = useState([]);
  const [firstDipItems, setFirstDipItems] = useState([]);
  const [secondDyeItems, setSecondDyeItems] = useState([]);
  const [secondDipItems, setSecondDipItems] = useState([]);
  const [washingItems, setWashingItems] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("Choose a process");
  const [processId, setProcessId] = useState("");
  const [processCode, setProcessCode] = useState("");
  const [processList, setProcessList] = useState([]);
  const [aux, setAux] = useState([]);
  const [dye, setDye] = useState([]);
  const [year, setYear] = useState("");
  const [fullDate, setFullDate] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [color, setColor] = useState("");
  const [hexCode, setHexCode] = useState("");
  const [pantone, setPantone] = useState("");
  const [unique, setUnique] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const errorEffect = new Audio(errorSound);
  const successEffect = new Audio(successSound);

  const getProcesses = () => {
    axiosInstance
      .get("/api/processes", authentication)
      .then((res) => {
        // console.log(res.data);
        setProcessList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        setAux(res.data.filter((chm) => chm.category === "Auxiliaries"));
        setDye(res.data.filter((chm) => chm.category === "Dyestuff"));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const codeChecking = async () => {
    const data = [
      `${year}`,
      `${processCode}`,
      `${orderNo}`,
      `${unique.toUpperCase()}`,
    ];
    const joinedData = data.join(``);
    // console.log(joinedData);

    // const data = {
    //   code_recipe: `${year}
    //   ${processCode}
    //   ${orderNo}`,
    // };

    await axiosInstance
      .post(
        "/api/recipes/check-code",
        { code_recipe: joinedData },
        authentication,
      )
      .then(() => {
        // console.log(res.data);
        successEffect.play();
        setIsCodeValid(true);
        setValidCode(`${year}${processCode}${orderNo}${unique.toUpperCase()}`);
        toast.success("Recipe code is valid. You may proceed with this code.");
      })
      .catch(() => {
        errorEffect.play();
        toast.error(
          "This recipe code is taken. Please use another set of code.",
        );
      });
  };

  useEffect(() => {
    getProcesses();
    getChemicals();
  }, []);

  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={scouringItems}
            setItems={setScouringItems}
          />
        );
      case 1:
        return (
          <SelectChemical
            data={dye}
            step={step}
            items={firstDyeItems}
            setItems={setFirstDyeItems}
          />
        );
      case 2:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={firstDipItems}
            setItems={setFirstDipItems}
          />
        );
      case 3:
        return (
          <SelectChemical
            data={dye}
            step={step}
            items={secondDyeItems}
            setItems={setSecondDyeItems}
          />
        );
      case 4:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={secondDipItems}
            setItems={setSecondDipItems}
          />
        );
      case 5:
        return (
          <SelectChemical
            data={aux}
            step={step}
            items={washingItems}
            setItems={setWashingItems}
          />
        );
      default:
        return <p>Please proceed by clicking the "Save" button.</p>;
    }
  }

  // function handleBack() {
  //   setActiveStep(activeStep - 1);
  // }

  const HorizontalStepper = () => {
    const [openModal, setOpenModal] = useState(false);
    const onProceed = () => {
      confirmationEffect.play();
      setOpenModal(true);
    };
    return (
      <>
        <ConfirmationModal
          open={openModal}
          setOpen={setOpenModal}
          action={() => setActiveStep(activeStep + 1)}
          actionTitle={"Proceed"}
          title={"Proceed to the next process?"}
          message={
            "Please check all the chemicals in this process before continue."
          }
        />
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.data.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack py={5}>{renderStepContent(activeStep)}</Stack>
          <Stack direction={"row"} justifyContent={"end"} gap={1}>
            {/* {activeStep !== 0 && (
              <button
                style={{
                  background: "#FFF",
                  color: "#222",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "3px",
                  border: "1px solid #CCC",
                  cursor: "pointer",
                }}
                onClick={handleBack}
              >
                Back
              </button>
            )} */}
            {activeStep !== 6 && (
              <button
                style={{
                  background: "#222",
                  color: "#FFF",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "3px",
                  border: "none",
                  cursor: "pointer",
                }}
                // onClick={() => setActiveStep(activeStep + 1)}
                onClick={() => onProceed()}
              >
                Next
              </button>
            )}
            {activeStep === 6 && (
              <button
                style={{
                  background: "#222",
                  color: "#FFF",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "3px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => setIsSummary(!isSummary)}
              >
                Save
              </button>
            )}
          </Stack>{" "}
        </Box>
      </>
    );
  };

  const onProcess = (dt) => {
    setSelectedProcess(dt.process_name);
    setProcessCode(dt.process_code);
    setProcessId(dt._id);
  };

  const getYear = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setFullDate(formattedDate);
    const extractYear = date.toString().slice(2, 4);
    setYear(extractYear);
  };

  function SelectChemical({ data, step, items }) {
    const [scouring, setScouring] = useState([]);
    const [firstDye, setFirstDye] = useState([]);
    const [firstDip, setFirstDip] = useState([]);
    const [secondDye, setSecondDye] = useState([]);
    const [secondDip, setSecondDip] = useState([]);
    const [washing, setWashing] = useState([]);
    const [amount, setAmount] = useState("");

    const addAmount = (c) => {
      const obj = { chemical: c, persentase: amount };
      function doCheck() {
        let res = items.some(({ chemical }) => chemical === c);
        return res;
      }
      // console.log(items);
      if (doCheck() === true) {
        items.map((obj) => {
          if (obj.chemical === c) obj.persentase = amount;
          return obj;
        });
        updateEffect.play();
        toast.success(`${c} amount is updated!`);
      } else {
        addedEffect.play();
        items.push(obj);
        toast.success(`${c} has been added successfully!`);
      }
    };

    return (
      <>
        <div>
          <Toaster position="top-center" reverseOrder={false} />
          <FormControl sx={{ my: 1, width: "100%" }}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={data}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                const objv = newValue.map((v) => v.chemical_name);
                // console.log(objv);
                step === 0
                  ? setScouring(objv)
                  : step === 1
                  ? setFirstDye(objv)
                  : step === 2
                  ? setFirstDip(objv)
                  : step === 3
                  ? setSecondDye(objv)
                  : step === 4
                  ? setSecondDip(objv)
                  : setWashing(objv);
              }}
              getOptionLabel={(option) => option.chemical_name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.chemical_name}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      // border: "1px solid #222",
                      color: "#666",
                      fontWeight: 600,
                    }}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    backgroundColor: selected ? "#222" : "#fff",
                    color: selected ? "#fff" : "#222",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{
                      marginRight: 8,
                      color: selected ? "#fff" : "#222",
                    }}
                    checked={selected}
                  />
                  {option.chemical_name}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Chemicals"
                  placeholder="Select chemical"
                  style={{ outline: "none" }}
                />
              )}
            />
          </FormControl>
        </div>
        {step === 0 && (
          <Stack>
            {scouring?.length !== 0 &&
              scouring?.map((c, i) => (
                <Stack
                  direction={"row"}
                  gap={2}
                  mt={2}
                  alignItems={"center"}
                  key={i}
                >
                  <CustomTextField value={c} disabled />
                  <CustomTextField
                    key={c}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 1 && (
          <Stack>
            {firstDye.length !== 0 &&
              firstDye?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>%</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 2 && (
          <Stack>
            {firstDip.length !== 0 &&
              firstDip?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 3 && (
          <Stack>
            {secondDye.length !== 0 &&
              secondDye?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>%</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 4 && (
          <Stack>
            {secondDip.length !== 0 &&
              secondDip?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c._id}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
        {step === 5 && (
          <Stack>
            {washing.length !== 0 &&
              washing?.map((c) => (
                <Stack direction={"row"} gap={2} mt={2} alignItems={"center"}>
                  <CustomTextField value={c} key={c._id} disabled />
                  <CustomTextField
                    key={c}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Typography>gr/L</Typography>
                  <Button
                    sx={{
                      background: "#222",
                      color: "#FFF",
                      padding: "10px 20px 10px 20px",
                      borderRadius: "3px",
                      border: "none",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#666",
                      },
                    }}
                    variant="contained"
                    onClick={() => addAmount(c)}
                  >
                    Add
                  </Button>
                </Stack>
              ))}
          </Stack>
        )}
      </>
    );
  }

  // Selecting chemicals ends here.

  return (
    <>
      {isSummary ? (
        <RecipeSummary
          scouring={scouringItems}
          fdye={firstDyeItems}
          fdip={firstDipItems}
          sdye={secondDyeItems}
          sdip={secondDipItems}
          wash={washingItems}
          isReview={isSummary}
          setIsReview={setIsSummary}
          color={color}
          pantone={pantone}
          code={validCode}
          process={selectedProcess}
          processId={processId}
          inputPerson={user.full_name}
          fullDate={fullDate}
          orderNo={orderNo}
          hex={hexCode}
          special={unique}
        />
      ) : (
        <Box
          flex="1"
          sx={{ backgroundColor: "#F6F6F6", height: "screen" }}
          paddingY={2}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={1}
            paddingY={2}
            alignItems={"center"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Button
                // startIcon={<WestIcon />}
                sx={{
                  textTransform: "capitalize",
                  color: "#6D7688",
                  fontWeight: "400",
                  width: "fit-content",
                }}
                onClick={() => navigate("/recipes/list")}
              >
                <WestIcon />
              </Button>
              <Typography variant="h4" fontSize={"24px"} fontWeight="500">
                Create Recipe
              </Typography>
            </Stack>
            <Stack px={2}>
              <Typography
                variant="h4"
                fontSize={"30px"}
                fontWeight="500"
                color={"#777"}
              >
                {validCode !== "" ? validCode : "------"}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            // sx={{ backgroundColor: "#FFF" }}
            paddingY={1}
            gap={2}
            mb={4}
          >
            <Stack
              padding={3}
              sx={{
                backgroundColor: "#FFF",
                width: "20%",
                borderRadius: "15px",
              }}
              gap={2}
              mb={4}
            >
              <Stack>
                <label
                  htmlFor="year"
                  style={{ fontWeight: 400, color: "#666" }}
                >
                  Date
                </label>
                <input
                  id="year"
                  type="date"
                  min="2001-01-01"
                  max="2050-12-30"
                  onChange={(e) => getYear(e.target.value)}
                  placeholder="Input the order number"
                  style={{
                    marginTop: 7,
                    padding: "6px 15px",
                    border: "1px solid #c8c8c8",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontFamily: "Inter",
                  }}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="name"
                  label="Process"
                  placeholder="Choose a process"
                  select
                  value={selectedProcess}
                  onChange={(e) => setSelectedProcess(e.target.value)}
                >
                  <MenuItem value={"Choose a process"}>
                    Choose a process
                  </MenuItem>
                  {processList.map((dt) =>
                    dt.status ? (
                      <MenuItem
                        key={dt._id}
                        value={dt.process_name}
                        onClick={() => onProcess(dt)}
                      >
                        {dt.process_name}
                      </MenuItem>
                    ) : null,
                  )}
                </CustomTextField>
              </Stack>
              <Stack>
                <CustomTextField
                  id="color"
                  type="color"
                  label={"Color picker"}
                  onChange={(e) => setHexCode(e.target.value?.toUpperCase())}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="color"
                  label="Color name"
                  placeholder="Input a color"
                  sx={{
                    height: "50px",
                    width: "50px",
                  }}
                  onChange={(e) => setColor(e.target.value?.toUpperCase())}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="color"
                  label="Pantone"
                  placeholder="Input a pantone code"
                  onChange={(e) => setPantone(e.target.value?.toUpperCase())}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="process_code"
                  label="Process code"
                  value={
                    processCode !== "" ? processCode : "Choose a process first"
                  }
                  disabled
                />
              </Stack>
              <Stack>
                <label
                  htmlFor="order_number"
                  style={{ fontWeight: 400, color: "#666" }}
                >
                  Recipe no.
                </label>
                <input
                  id="order_number"
                  min={0}
                  max={999}
                  maxLength={3}
                  placeholder="Input the order number"
                  style={{
                    marginTop: 7,
                    padding: "10px 15px",
                    border: "1px solid #c8c8c8",
                    borderRadius: "4px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => setOrderNo(e.target.value)}
                />
              </Stack>
              <Stack>
                <CustomTextField
                  name="unique"
                  label="Unique code"
                  value={unique}
                  onChange={(e) => setUnique(e.target.value)}
                  placeholder={"Please input a unique code if necessary"}
                  disabled={
                    year === "" ||
                    processCode === "" ||
                    orderNo === "" ||
                    orderNo.length < 3
                      ? true
                      : false
                  }
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              paddingX={3}
              sx={{ backgroundColor: "#FFF", borderRadius: "15px" }}
              paddingY={4}
              gap={2}
              width={"75%"}
              height={"fit-content"}
            >
              {isCodeValid ? (
                <HorizontalStepper
                  isComplete={isSummary}
                  setIsComplete={setIsSummary}
                />
              ) : (
                <Stack sx={{ width: "100%" }}>
                  <Toaster position="top-center" reverseOrder={false} />
                  <Stack
                    my={1}
                    width={"100%"}
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    <Stack justifyContent={"center"} alignContent={"center"}>
                      <Typography variant="h7" fontWeight={600}>
                        Recipe Code
                      </Typography>
                      <Typography
                        variant="h5"
                        fontWeight={400}
                        mt={1}
                        fontSize={"44px"}
                        textTransform={"uppercase"}
                        color={
                          processCode === "" ||
                          orderNo === "" ||
                          orderNo.length < 3
                            ? "#d1d1d1"
                            : "#222"
                        }
                      >
                        {year !== "" ? year : "--"}
                        {processCode !== "" ? processCode : "-"}
                        {orderNo !== "" ? orderNo : "---"}
                        {unique}
                      </Typography>
                    </Stack>
                    <Grid mb={2}>
                      {year === "" ||
                      processCode === "" ||
                      orderNo === "" ||
                      orderNo.length < 3 ? null : (
                        <Button
                          sx={{
                            width: "fit-content",
                            textTransform: "capitalize",
                            borderRadius: "4px",
                            border: "none",
                            fontFamily: "Inter",
                            height: "30px",
                            padding: "5px 10px",
                            backgroundColor: "#222",
                            cursor: "pointer",
                            color: "#FFF",
                            marginTop: "8px",
                            ":hover": {
                              backgroundColor: "#444",
                              color: "#FFF",
                            },
                          }}
                          onClick={() => codeChecking()}
                        >
                          Check code
                        </Button>
                      )}
                    </Grid>
                  </Stack>
                  {fullDate !== "" ? null : (
                    <Typography
                      fontSize={"12px"}
                      color={"#CC3A30"}
                      fontWeight={500}
                    >
                      Please choose the date of the recipe.
                    </Typography>
                  )}
                  {processCode !== "" ? null : (
                    <Typography
                      fontSize={"12px"}
                      color={"#CC3A30"}
                      fontWeight={500}
                    >
                      Please choose a process.
                    </Typography>
                  )}
                  {orderNo !== "" ? null : (
                    <Typography
                      fontSize={"12px"}
                      color={"#CC3A30"}
                      fontWeight={500}
                    >
                      Please input the order number.
                    </Typography>
                  )}
                  <Typography
                    fontSize={"12px"}
                    color={"#CC3A30"}
                    fontWeight={500}
                  >
                    Please check the code first. You may continue to the recipe
                    making steps only when the code is valid.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default AddRecipe;
