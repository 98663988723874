import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import ConfirmationModal from "components/ConfirmationModal";
import confirmationSound from "../../../assets/audio/confirmation.mp3";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const EditProduction = ({ open, setModalOpen, getProductions, data }) => {
  // console.log(data);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const initialDate = moment(data?.date).format("YYYY-MM-DD");
  // console.log(initialDate);
  const [weight, setWeight] = useState("");
  const [roll, setRoll] = useState("");
  const [recipe, setRecipe] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [machines, setMachines] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [recipes, setRecipes] = useState([]);
  // const [customer, setCustomer] = useState("Choose a customer");
  // const [machine, setMachine] = useState("Choose a machine");
  const [recipeId, setRecipeId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [fabricId, setFabricId] = useState("");
  const [machineId, setMachineId] = useState("");
  const [scouringVlot, setScouringVlot] = useState("");
  const [dyeingVlot, setDyeingVlot] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [productionCode, setProductionCode] = useState("");
  const [productionDate, setProductionDate] = useState("");
  const [date, setDate] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [editedRemarks, setEditedRemarks] = useState("");
  const [initialMachine, setInitialMachine] = useState([]);
  const [initialCustomer, setInitialCustomer] = useState([]);
  const [initialRecipe, setInitialRecipe] = useState([]);
  const [initialFabric, setInitialFabric] = useState([]);
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const confirmationEffect = new Audio(confirmationSound);

  const handleClose = () => {
    setModalOpen(false);
    setDate("");
  };

  const onCustomer = (ct) => {
    setCustomerId(ct.name);
  };

  const onRecipe = (rc) => {
    setRecipe(rc);
    setRecipeId(rc._id);
  };

  const onFabric = (fb) => {
    setFabricId(fb.fabric_code);
  };

  const onMachine = (mc) => {
    setMachineId(mc.name);
  };

  const getCustomers = () => {
    axiosInstance
      .get("/api/customers", authentication)
      .then((res) => {
        // const response = res?.data;
        setCustomers(res?.data);
        setInitialCustomer(
          res?.data?.filter((rs) => rs.name === data?.customer_name && rs._id),
        );
        // console.log(initialCustomer);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMachines = () => {
    axiosInstance
      .get("/api/machines", authentication)
      .then((res) => {
        // const response = res?.data;
        // console.log(res?.data);
        setMachines(res?.data);
        setInitialMachine(
          res?.data?.filter((rs) => rs.name === data?.machine_name && rs._id),
        );
        // console.log(initialMachine);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFabrics = () => {
    axiosInstance
      .get("/api/fabrics", authentication)
      .then((res) => {
        // console.log(res?.data);
        // const response = res?.data;
        // console.log(response);
        setFabrics(res?.data);
        setInitialFabric(
          res?.data?.filter(
            (rs) => rs.fabric_code === data?.fabric_code && rs._id,
          ),
        );
        // console.log(initialFabric);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecipes = () => {
    axiosInstance
      .get("/api/recipes/", authentication)
      .then((res) => {
        // const response = res?.data;
        // console.log(res?.data);
        setRecipes(res?.data);
        setInitialRecipe(
          res?.data?.filter(
            (rs) => rs.code_recipe === data?.code_recipe && rs._id,
          ),
        );
        // console.log(initialRecipe);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editProductionData = async () => {
    const editedData = {
      code_production:
        productionCode !== "" ? productionCode : data?.code_production,
      color: recipe !== null ? recipe?.color : data?.color,
      recipe: recipeId !== "" ? recipeId : data?.code_recipe,
      date: productionDate !== "" ? productionDate : data?.date,
      customer_name: customerId !== "" ? customerId : data?.customer_name,
      fabric_code: fabricId !== "" ? fabricId : data?.fabric_code,
      machine_name: machineId !== "" ? machineId : data?.machine_name,
      vlot_scouring: scouringVlot !== "" ? scouringVlot : data?.vlot_scouring,
      vlot_dyeing: dyeingVlot !== "" ? dyeingVlot : data?.vlot_dyeing,
      purchase_order:
        purchaseOrder !== "" ? purchaseOrder : data?.purchase_order,
      weight: weight !== "" ? weight : data?.weight,
      roll: roll !== "" ? roll : data?.roll,
      remark: editedRemarks !== "" ? editedRemarks : data?.remark,
      status: data?.status,
    };

    await axiosInstance
      .put(`/api/production_lists/${data?._id}`, editedData, authentication)
      .then(() => {
        succesEffect.play();
        setOpenConfirmation(false);
        setModalOpen(false);
        getProductions();
        toast.success("Production has been successfully updated");
        // setRecipes(res?.data);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  const selectDate = (date) => {
    // console.log(date);
    const formattedDate = moment(date).format("DD/MM/YYYY");
    const selectedDate = moment(date).format("YYYY-MM-DD");
    setDate(selectedDate);
    setProductionDate(formattedDate);
  };

  useEffect(() => {
    getCustomers();
    getMachines();
    getFabrics();
    getRecipes();
  }, []);

  const initiateUpdate = () => {
    confirmationEffect.play();
    setOpenConfirmation(true);
  };

  return (
    <>
      <ConfirmationModal
        title={"Update production details?"}
        message={
          "You are about to update the information of this production data. Proceed?"
        }
        actionTitle={"Proceed"}
        action={() => editProductionData()}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Edit Production Data
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="production_code"
                    label="Production code"
                    placeholder={data?.code_production}
                    onChange={(e) => setProductionCode(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <Stack>
                    <CustomTextField
                      id="date"
                      type="date"
                      label={"Production date"}
                      value={date !== "" ? date : initialDate}
                      onChange={(e) => selectDate(e.target.value)}
                      placeholder="Input the job order date"
                      InputProps={{
                        inputProps: { min: "2023-12-01", max: "2050-12-30" },
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={recipes?.map(
                      (r) => `${r.code_recipe} - ${r.color}`,
                    )}
                    disabled
                    onChange={(event, newValue) => {
                      recipes.filter((rc) =>
                        `${rc.code_recipe} - ${rc.color}` === newValue
                          ? onRecipe(rc)
                          : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data.code_recipe} - ${data.color}`}
                        label="Recipe code"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="color"
                    label="Color"
                    placeholder="The color will appear here."
                    disabled
                    value={recipe !== null ? recipe?.color : data.color}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="scouring_vlot"
                    label="Scouring vlot"
                    placeholder={data.vlot_scouring}
                    onChange={(e) => setScouringVlot(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="dyeing_vlot"
                    label="Dyeing vlot"
                    placeholder={`${data?.vlot_dyeing}`}
                    onChange={(e) => setDyeingVlot(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={fabrics?.map((fb) =>
                      fb.fabric_code !== "" ? `${fb.fabric_code}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      fabrics.filter((fb) =>
                        `${fb.fabric_code}` === newValue ? onFabric(fb) : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data?.fabric_code}`}
                        label="Fabric code"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="fabric_weight"
                    label="Fabric weight (kg)"
                    type="number"
                    placeholder={data.weight}
                    onChange={(e) => {
                      setWeight(e.target.value);
                      setRoll(Math.ceil(e.target.value / 25));
                    }}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={customers?.map((ctm) =>
                      ctm.name !== "" ? `${ctm.name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      customers.filter((ct) =>
                        `${ct.name}` === newValue ? onCustomer(ct) : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data?.customer_name}`}
                        label="Customer"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  {/* <CustomTextField
                    name="customer"
                    label="Customer"
                    placeholder="Choose a customer"
                    onChange={(e) => setCustomer(e.target.value)}
                    select
                    value={customer}
                  >
                    <MenuItem value={"Choose a customer"}>
                      {data?.customer?.name}
                    </MenuItem>
                    {customers.map((ct) =>
                      ct.status === true && ct.name !== data?.customer?.name ? (
                        <MenuItem
                          key={ct._id}
                          value={ct.name}
                          onClick={() => onCustomer(ct)}
                        >
                          {ct.name}
                        </MenuItem>
                      ) : null,
                    )}
                  </CustomTextField> */}
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="roll"
                    label="Roll"
                    placeholder="Input the fabric roll"
                    value={data?.roll}
                    disabled
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="purchase_order"
                    label="Purchase order"
                    placeholder={data.purchase_order}
                    onChange={(e) => setPurchaseOrder(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={machines?.map((mc) =>
                      mc.name !== "" ? `${mc.name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      machines.filter((mc) =>
                        `${mc.name}` === newValue ? onMachine(mc) : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder={`${data?.machine_name}`}
                        label="Machine"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  {/* <CustomTextField
                    name="machine"
                    label="Machine"
                    // placeholder={data.machine.name}
                    select
                    value={machine}
                    onChange={(e) => setMachine(e.target.value)}
                  >
                    <MenuItem value={"Choose a machine"}>
                      {data?.machine?.name}
                    </MenuItem>
                    {machines.map((mc) =>
                      mc.status === true && mc.name !== data?.machine?.name ? (
                        <MenuItem
                          key={mc._id}
                          value={mc.name}
                          sx={{ textTransform: "uppercase" }}
                          onClick={() => onMachine(mc)}
                        >
                          {mc.name}
                        </MenuItem>
                      ) : null,
                    )}
                  </CustomTextField> */}
                </Grid>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                // justifyContent={"space-between"}
                width={"100%"}
              >
                <Grid item width={"100%"}>
                  <CustomTextField
                    name="remarks"
                    label="Remarks"
                    multiline
                    rows={4}
                    placeholder={data?.remark}
                    onChange={(e) => setEditedRemarks(e.target.value)}
                  />
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                type="submit"
                variant="contained"
                onClick={() => initiateUpdate()}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default EditProduction;
