import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

const ConfirmationModal = ({
  open,
  setOpen,
  action,
  title,
  message,
  actionTitle,
  isWarning,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
    borderRadius: "5px",
    // p: 2,
    color: "#222",
    background: "#FFF",
    zIndex: "100",
    textAlign: "left",
    width: "350px",
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Stack height={60} p={2}>
            <Typography sx={{ mb: 1, fontWeight: 600 }}>{title}</Typography>
            <Typography sx={{ mb: 1 }} fontSize={12}>
              {message}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            gap={1}
            sx={{
              width: "full",
              justifyContent: "flex-end",
              display: "flex",
              backgroundColor: "#f7f7f7",
              padding: "10px 15px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#fff",
                color: "#222",
                textTransform: "capitalize",
                height: "30px",
                borderRadius: "8px",
                border: "1px solid #222",
                fontSize: "12px",
                ":hover": {
                  backgroundColor: "#444",
                  color: "#fff",
                  border: "1px solid #222",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: isWarning ? "#CC3A30" : "#222",
                color: "#fff",
                textTransform: "capitalize",
                height: "30px",
                borderRadius: "8px",
                fontSize: "12px",
                border: `1px solid ${isWarning ? "#CC3A30" : "#222"} `,
                ":hover": {
                  backgroundColor: "#444",
                  border: "1px solid #444",
                },
              }}
              onClick={() => action()}
            >
              {actionTitle}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
