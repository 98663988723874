import React from "react";
import { Stack, Typography } from "@mui/material";
import empty from "../assets/noData.png";

export default function CustomNoRowsOverlay() {
  return (
    <Stack sx={{ pt: 1, width: "100%" }} alignItems={"center"}>
      <img src={empty} alt="Empty" width="5%" height="auto" />
      <Typography
        mt={1}
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color: "#7E7E7E",
          fontStyle: "italic",
        }}
      >
        No data available
      </Typography>
    </Stack>
  );
}
