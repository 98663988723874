import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const categories = [
  {
    _id: "0",
    name: "Dyestuff",
    value: "Dyestuff",
  },
  {
    _id: "1",
    name: "Auxiliaries",
    value: "Auxiliaries",
  },
];

const types = [
  {
    _id: "0",
    name: "Reactive",
    value: "Reactive",
  },
  {
    _id: "1",
    name: "Disperse",
    value: "Disperse",
  },
];

const packagings = [
  {
    _id: "0",
    name: "Box",
    value: "Box",
  },
  {
    _id: "1",
    name: "Tank",
    value: "Tank",
  },
  {
    _id: "2",
    name: "Sack",
    value: "Sack",
  },
  {
    _id: "3",
    name: "Barrel",
    value: "Barrel",
  },
  {
    _id: "4",
    name: "Jerry Can",
    value: "Jerry Can",
  },
];

const forms = [
  {
    _id: "0",
    name: "Powder",
    value: "Powder",
  },
  {
    _id: "1",
    name: "Liquid",
    value: "Liquid",
  },
  {
    _id: "2",
    name: "Crystal",
    value: "Crystal",
  },
  {
    _id: "3",
    name: "Flake",
    value: "Flake",
  },
];

const AddChemical = ({ open, setModalOpen, getChemicals }) => {
  const [name, setName] = useState("");
  const [weight, setWeight] = useState(0);
  const [category, setCategory] = useState("Choose category");
  const [type, setType] = useState("Choose type");
  const [packaging, setPackaging] = useState("Choose packaging");
  const [form, setForm] = useState("Choose form");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      chemical_name: name,
      amount: weight,
      type: category === "Dyestuff" ? type : "-",
      category: category,
      packaging: packaging,
      shape: form,
    };

    axiosInstance
      .post("/api/chemicals", data, authentication)
      .then(() => {
        // console.log(res.data);
        successEffect.play();
        toast.success("New chemical data added!");
        getChemicals();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleClose = () => {
    setName("");
    setWeight(0);
    setCategory("Choose category");
    setType("Choose type");
    setPackaging("Choose packaging");
    setForm("Choose form");
    setModalOpen(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Chemical
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        <form onSubmit={handleSubmit}>
          <Box flex="1">
            <Stack paddingX={2} paddingY={2}>
              <Stack gap={2}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="name"
                      label="Chemical Name"
                      placeholder="Input the name of the chemical"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="category"
                      label="Category"
                      placeholder="Choose category"
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                      select
                    >
                      <MenuItem value={"Choose category"}>
                        Choose category
                      </MenuItem>
                      {categories.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="packaging"
                      label="Packaging"
                      placeholder="Choose the type of the packaging"
                      onChange={(e) => setPackaging(e.target.value)}
                      value={packaging}
                      select
                    >
                      <MenuItem value={"Choose packaging"}>
                        Choose packaging
                      </MenuItem>
                      {packagings.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="form"
                      label="Form"
                      placeholder="Choose the form of the chemical"
                      onChange={(e) => setForm(e.target.value)}
                      value={form}
                      select
                    >
                      <MenuItem value={"Choose form"}>Choose form</MenuItem>
                      {forms.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  {category === "Dyestuff" ? (
                    <Grid item width={"50%"}>
                      <CustomTextField
                        name="type"
                        label="Type"
                        placeholder="Choose the type of the chemical"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                        select
                      >
                        <MenuItem value={"Choose type"}>Choose type</MenuItem>
                        {types.map((dt) => (
                          <MenuItem key={dt._id} value={dt.value}>
                            {dt.name}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </Grid>
                  ) : null}

                  <Grid item width={category === "Dyestuff" ? "50%" : "100%"}>
                    <CustomTextField
                      name="content_weight"
                      label="Content Weight (kg)"
                      type="number"
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: "#f6f6f6" }}>
            <Box
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "6px",
                    backgroundColor: "#FFF",
                    color: "#222",
                    border: "1px solid #222",
                    ":hover": {
                      backgroundColor: "#FFF",
                      border: "1px solid #222",
                    },
                  }}
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  disabled={name === "" || weight === 0 ? true : false}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      </ModalCustom>
    </>
  );
};

export default AddChemical;
