import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  // MenuItem,
  Autocomplete,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import StatusSwitch from "components/Switch";
import CustomTextField from "components/TextField";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const SupplierDetail = ({ open, setOpenDetail, data, getSuppliers }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isEdit, setIsEdit] = useState(false);
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  // const [category, setCategory] = useState(data?.category);
  const [newCategory, setNewCategory] = useState("");
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);
  // const [openModal, setOpenModal] = useState(false);

  const type = [
    {
      _id: "0",
      name: "Dyestuff",
      value: "Dyestuff",
    },
    {
      _id: "1",
      name: "Auxiliaries",
      value: "Auxiliaries",
    },
    {
      _id: "2",
      name: "Dyestuff & Auxiliaries",
      value: "Dyestuff & Auxiliaries",
    },
  ];

  const onBack = () => {
    setIsEdit(false);
    setOpenDetail(false);
  };

  const handleChange = () => {
    const edited = {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      address: data.address,
      city: data.city,
      category: data.category,
      status: !data?.status,
    };

    axiosInstance
      .put(`/api/suppliers/${data._id}`, edited, authentication)
      .then(() => {
        succesEffect.play();
        toast.success("Supplier status updated!");
        getSuppliers();
        setOpenDetail(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleSubmit = () => {
    const edited = {
      name: name !== "" ? name : data.name,
      email: email !== "" ? email : data.email,
      phone_number: phone !== "" ? phone : data.phone_number,
      address: address !== "" ? address : data.address,
      city: city !== "" ? city : data.city,
      category: newCategory !== "" ? newCategory : data.category,
      status: data?.status,
    };

    axiosInstance
      .put(`/api/suppliers/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Supplier data has been updated!");
        getSuppliers();
        setOpenDetail(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/suppliers/${data._id}`, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Supplier data has been deleted!");
        getSuppliers();
        setOpenDetail(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        action={handleDelete}
        actionTitle={"Delete"}
        title={"Delete?"}
        message={"This supplier data is about to be deleted. Are you sure?"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => onBack()}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Supplier Detail
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              {isEdit ? (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    {/* <CustomTextField
                      name="category"
                      label="Category"
                      placeholder="Choose category"
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                      select
                    >
                      {type.map((dt) => (
                        <MenuItem key={dt._id} value={dt.value}>
                          {dt.name}
                        </MenuItem>
                      ))}
                    </CustomTextField> */}
                    <Autocomplete
                      options={type?.map((ty) => ty.name)}
                      onChange={(event, newValue) => {
                        type.filter((ty) =>
                          `${ty.value}` === newValue
                            ? setNewCategory(ty.value)
                            : null,
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder={`${data?.category}`}
                          label="Category"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Stack>
              ) : (
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Category
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.category}
                  </Typography>
                </Grid>
              )}
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={isEdit ? "100%" : "50%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      placeholder={data?.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Name
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.name}
                    </Typography>
                  </Grid>
                )}
                {user.role !== "Admin Product" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleChange()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}

                {user.role !== "SuperAdmin" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleChange()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
                {user.role !== "Admin Warehouse" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleChange()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="email"
                      label="Email"
                      placeholder={data?.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Email
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.email}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="phone"
                      label="Phone"
                      placeholder={data?.phone_number}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Phone
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.phone_number}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              {isEdit && (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="city"
                      label="City"
                      placeholder={data?.city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                </Stack>
              )}
              {isEdit ? null : (
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid item width={"100%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      City
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.city}
                    </Typography>
                  </Grid>
                </Stack>
              )}
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="address"
                      label="Address"
                      type="text"
                      multiline
                      rows={4}
                      placeholder={data?.address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"100%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Address
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      width={"95%"}
                    >
                      {data?.address}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  // marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    // marginLeft: "16px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "Admin Product" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "Admin Warehouse" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default SupplierDetail;
