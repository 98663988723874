import React from "react";
import { Box, Stack, Typography, Grid } from "@mui/material";
import moment from "moment";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const today = moment().format("LL");

  return (
    <Box flex="1" sx={{ backgroundColor: "#FFF" }}>
      <Stack
        // direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
        mb={3}
      >
        <Typography fontSize="24px" fontWeight="600">
          Dashboard E-Recipe
        </Typography>
        <Typography fontSize="14px" color="#B1B1B1" fontWeight="600">
          {today}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={2}
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            backgroundColor: "#F6F6F6",
            paddingBottom: "20px",
          }}
        >
          <Typography variant="h5" color="#222" fontWeight="600" mb={1}>
            Hi, {user.full_name}!
          </Typography>
          <Typography variant="h6" color="#222" fontWeight="400">
            Welcome to <strong>E-Recipe Dashboard</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
