import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import axiosInstance from "../../../utils/api/api";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const DetailModal = ({ open, setModalOpen, data, getCustomers }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);

  const handleStatus = () => {
    const edited = {
      name: name !== "" ? name : data.name,
      email: email === "" ? "-" : data.email,
      phone_number: phone !== "" ? phone : data.phone_number,
      address: address !== "" ? address : data.address,
      city: city !== "" ? city : data.city,
      status: !data?.status,
    };

    axiosInstance
      .put(`/api/customers/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Customer status updated!");
        getCustomers();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleSubmit = () => {
    const edited = {
      name: name !== "" ? name : data.name,
      email: email !== "" ? email : data.email,
      phone_number: phone !== "" ? phone : data.phone_number,
      address: address !== "" ? address : data.address,
      city: city !== "" ? city : data.city,
      status: data?.status,
    };

    axiosInstance
      .put(`/api/customers/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Customer data has been updated!");
        getCustomers();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/customers/${data._id}`, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Customer has been deleted!");
        getCustomers();
        setModalOpen(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  const onBack = () => {
    setIsEdit(false);
    setModalOpen(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        action={handleDelete}
        title={"Delete?"}
        message={
          "This customer data is about to be permanently deleted. Are you sure?"
        }
        actionTitle={"Delete"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Customer Detail
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={isEdit ? "100%" : "50%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      placeholder={data?.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Name
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      textTransform={"uppercase"}
                    >
                      {data?.name}
                    </Typography>
                  </Grid>
                )}

                {user.role !== "Admin Product" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
                {user.role !== "SuperAdmin" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="email"
                      label="Email"
                      placeholder={data?.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Email
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.email === "" ? "-" : data?.email}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="phone"
                      label="Phone"
                      placeholder={data?.phone_number}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Phone
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.phone_number}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="city"
                      label="City"
                      placeholder={data?.city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"0%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      City
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      textTransform={"uppercase"}
                    >
                      {data?.city}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"100%"}>
                    <CustomTextField
                      name="address"
                      label="Address"
                      type="text"
                      multiline
                      rows={4}
                      placeholder={data?.address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"100%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Address
                    </Typography>
                    <Typography
                      fontSize={16}
                      color={"#222"}
                      fontWeight={500}
                      width={"95%"}
                      textTransform={"uppercase"}
                    >
                      {data?.address}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "Admin Product" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </ModalCustom>
    </>
  );
};

export default DetailModal;
