import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import logo from "../../../assets/erecipe_logo.png";
import CustomTextField from "components/TextField";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

const Renderer = ({ open, setModalOpen, data }) => {
  // console.log(data);
  const sizes = [
    {
      _id: 0,
      name: "Legal",
      value: "8in 14in",
    },
    {
      _id: 1,
      name: "Letter",
      value: "8.5in 11in",
    },
    {
      _id: 2,
      name: "US Fanfold",
      value: "11in 14.1in",
    },
  ];

  const [pageSize, setPageSize] = useState("11in 14.1in");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${data?.code_production}`,
    pageStyle: `@page { size: ${pageSize} }`,
  });

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ width: 1000, height: "700px" }}
      >
        <Stack
          sx={{
            padding: 2,
            overflow: "scroll",
          }}
          ref={componentRef}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            mb={1}
          >
            <Stack direction="row" alignItems="center" height="auto" gap={1}>
              <Avatar
                src={logo}
                sx={{ borderRadius: 0, height: "15px", width: "15px" }}
              />
              <Typography
                sx={{ fontWeight: 500, color: "#222", textAlign: "left" }}
                fontSize={14}
              >
                E-Recipe Dashboard
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography
                sx={{ fontWeight: 500, color: "#222", textAlign: "left" }}
                fontSize={14}
              >
                PT Perajutan Sahabat
              </Typography>
            </Stack>
          </Stack>
          {/* <Divider /> */}
          <Stack
            width={"auto"}
            gap={1}
            backgroundColor={"#FFF"}
            justifyContent={"space-between"}
            direction={"row"}
            border={"1px solid #C8C8C8"}
            borderRadius={"5px"}
            padding={1}
            mb={1}
          >
            <Stack width={"20%"} gap={1}>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Date
                </Typography>
                <Typography fontSize={14}>
                  {moment(data?.date).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Fabric code
                </Typography>
                <Typography fontSize={14}>{data?.fabric_code}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Purchase order
                </Typography>
                <Typography fontSize={14}>{data?.purchase_order}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Pantone
                </Typography>
                <Typography fontSize={14}>{data?.pantone}</Typography>
              </Stack>
            </Stack>
            <Stack width={"20%"} gap={1}>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Production code
                </Typography>
                <Typography fontSize={14}>{data?.code_production}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Customer
                </Typography>
                <Typography fontSize={14}>{data?.customer_name}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Recipe code
                </Typography>
                <Typography fontSize={14}>{data?.code_recipe}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Color
                </Typography>
                <Typography fontSize={14} textTransform={"capitalize"}>
                  {data?.color}
                </Typography>
              </Stack>
            </Stack>
            <Stack width={"20%"} gap={1}>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Weight
                </Typography>
                <Typography fontSize={14}>{data?.weight}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Scouring vlot
                </Typography>
                <Typography fontSize={14}>{data?.vlot_scouring}</Typography>
              </Stack>
              <Stack width={"50%"}>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Dyeing vlot
                </Typography>
                <Typography fontSize={14}>{data?.vlot_dyeing}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Input person
                </Typography>
                <Typography fontSize={14}>{data?.author?.full_name}</Typography>
              </Stack>
            </Stack>
            <Stack width={"20%"} gap={1}>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Roll
                </Typography>
                <Typography fontSize={14}>{data?.roll}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Machine
                </Typography>
                <Typography fontSize={14}>{data?.machine_name}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize={14} fontWeight={600} color={"#000"}>
                  Process
                </Typography>
                <Typography fontSize={14}>{data?.process_name}</Typography>
              </Stack>
            </Stack>
            <Stack width={"20%"} gap={1}>
              <Typography fontSize={12} fontWeight={600} color={"#000"}>
                Disetujui oleh:
              </Typography>
              <Stack
                height={50}
                sx={{ border: "1px solid #C8C8C8" }}
                textAlign={"center"}
              >
                <Stack height={30} />
                <Typography fontSize={12} sx={{ bottom: 0 }}>
                  Kepala Produksi
                </Typography>
              </Stack>
              <Stack height={50} sx={{ border: "1px solid #C8C8C8" }}>
                <Stack height={30} />
                <Stack textAlign={"center"}>
                  <Typography fontSize={12}>Kepala Gudang</Typography>
                </Stack>
              </Stack>
              <Stack height={50} sx={{ border: "1px solid #C8C8C8" }}>
                <Stack height={30} />
                <Stack textAlign={"center"}>
                  <Typography fontSize={12}>Menimbang & Menyiapkan</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            width={"auto"}
            gap={1}
            backgroundColor={"#FFF"}
            justifyContent={"space-between"}
            direction={"row"}
            border={"1px solid #C8C8C8"}
            borderRadius={"5px"}
            padding={1}
          >
            <Stack>
              <Typography fontSize={14} fontWeight={600} color={"#000"}>
                Remark
              </Typography>
              <Typography fontSize={14}>
                {data?.remark ? data?.remark : "-"}
              </Typography>
            </Stack>
          </Stack>
          {/* <Divider /> */}
          {/* SCOURING */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              Scouring
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  gr/L
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Scouring vlot
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (kg)
                </Typography>
              </Stack>
              <Stack
                width={"30%"}
                textAlign={"center"}
                // borderLeft={"1px solid #e0e0e0"}
              >
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.scoring?.length !== 0
              ? data?.scoring?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 10,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {data.vlot_scouring.toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            parseFloat(item.persentase * data?.vlot_scouring) /
                            1000
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* SCOURING - END */}
          {/* FIRST DYESTUFF */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              First Dyestuff
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Percentage (%)
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Fabric weight (kg)
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (gr)
                </Typography>
              </Stack>
              <Stack width={"30%"} textAlign={"center"}>
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.dyestuff_first?.length !== 0
              ? data?.dyestuff_first?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 10,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {data?.weight}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            data?.weight *
                            (item.persentase / 100) *
                            1000
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* FIRST DYESTUFF - END */}
          {/* FIRST CHEMICAL DIP */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              First Chemical Dip
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  gr/L
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Dyeing vlot
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (kg)
                </Typography>
              </Stack>
              <Stack width={"30%"} textAlign={"center"}>
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.chemical_first_dip?.length !== 0
              ? data?.chemical_first_dip?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 10,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {(data?.vlot_dyeing).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            (item.persentase * data?.vlot_dyeing) /
                            1000
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* FIRST CHEMICAL DIP - END */}
          {/* SECOND DYESTUFF */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              Second Dyestuff
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Percentage (%)
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Fabric weight (kg)
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (gr)
                </Typography>
              </Stack>
              <Stack width={"30%"} textAlign={"center"}>
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.dyestuff_second?.length !== 0
              ? data?.dyestuff_second?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                          borderBottom={"1px solid #e0e0e0"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 10,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {data?.weight}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            data?.weight *
                            (item.persentase / 100) *
                            1000
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* SECOND DYESTUFF - END */}
          {/* SECOND CHEMICAL DIP */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              Second Chemical Dip
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  gr/L
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Dyeing vlot
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (kg)
                </Typography>
              </Stack>
              <Stack width={"30%"} textAlign={"center"}>
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.chemical_second_dip?.length !== 0
              ? data?.chemical_second_dip?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 10,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {(data?.vlot_dyeing).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            parseFloat(item.persentase * data?.vlot_dyeing) /
                            1000
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* SECOND CHEMICAL DIP - END */}
          {/* WASHING */}
          <Stack
            // gap={2}
            mt={1}
            width={"auto"}
            border={"1px solid #C8C8C8"}
            borderRadius={"4px"}
            padding={1}
          >
            <Typography fontSize={14} fontWeight={600} mb={1}>
              Washing / Neutralization
            </Typography>
            <Divider />
            <Stack direction={"rows"} gap={1}>
              <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Chemical
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  gr/L
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Dyeing vlot
                </Typography>
              </Stack>
              <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                <Typography fontWeight={500} fontSize={14}>
                  Weight (kg)
                </Typography>
              </Stack>
              <Stack width={"30%"} textAlign={"center"}>
                <Typography fontWeight={500} fontSize={14}>
                  Remarks
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            {data?.neutralization?.length !== 0
              ? data?.neutralization?.map((item) => (
                  <>
                    <Stack
                      direction={"rows"}
                      gap={1}
                      key={item._id}
                      borderBottom={"1px solid #e0e0e0"}
                    >
                      <Stack width={"25%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          textTransform={"uppercase"}
                        >
                          {item.chemical}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {parseFloat(item.persentase).toLocaleString("id-ID", {
                            maximumFractionDigits: 4,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={300} fontSize={14}>
                          {(data?.vlot_dyeing).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"15%"} borderRight={"1px solid #e0e0e0"}>
                        <Typography fontWeight={600} fontSize={14}>
                          {(
                            data?.vlot_dyeing *
                            (item.persentase / 1000)
                          ).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Stack>
                      <Stack width={"30%"}></Stack>
                    </Stack>
                    {/* <Divider /> */}
                  </>
                ))
              : "-"}
          </Stack>
          {/* <Stack
            paddingY={2}
            gap={2}
            mt={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={14}>
              Job order ini telah disetujui dan ditandatangani oleh:
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"space-around"}
              mt={0}
              gap={2}
            >
              <Stack
                height={0}
                width={150}
                sx={{ borderBottom: "1px solid #999" }}
              >
                <Stack p={1} textAlign={"center"}>
                  <Typography fontSize={14}>Kepala Produksi</Typography>
                </Stack>
              </Stack>
              <Stack
                height={0}
                width={150}
                sx={{ borderBottom: "1px solid #999" }}
              >
                <Stack p={1} textAlign={"center"}>
                  <Typography fontSize={14}>Kepala Gudang</Typography>
                </Stack>
              </Stack>
              <Stack
                height={0}
                width={200}
                sx={{ borderBottom: "1px solid #999" }}
              >
                <Stack p={1} textAlign={"center"}>
                  <Typography fontSize={14}>Menimbang & Menyiapkan</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack> */}
        </Stack>
        <Stack
          padding={2}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          alignItems={"center"}
        >
          <Stack width={"70%"}>
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "5px",
                backgroundColor: "#222",
                padding: "8px",
                color: "#FFF",
                ":hover": {
                  backgroundColor: "#444",
                  color: "#FFF",
                },
              }}
              variant="contained"
              onClick={handlePrint}
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          </Stack>
          <Stack width={"30%"}>
            <CustomTextField
              select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {sizes.map((sz) => (
                <MenuItem
                  key={sz._id}
                  value={sz.value}
                  onClick={() =>
                    toast.success(`Page size selected: ${sz.name}`)
                  }
                >
                  {sz.name}
                </MenuItem>
              ))}
            </CustomTextField>
          </Stack>
        </Stack>
      </ModalCustom>
    </>
  );
};

export default Renderer;
