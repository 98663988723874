import React from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
// import CustomTextField from "components/TextField";
// import toast, { Toaster } from "react-hot-toast";
// import axiosInstance from "../../../utils/api/api";
import moment from "moment";

const OutbondDetails = ({ open, setModalOpen, data, getProcesses }) => {
  //   const token = JSON.parse(localStorage.getItem("userToken"));
  //   const user = JSON.parse(localStorage.getItem("admin_data"));
  //   const authentication = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  const onBack = () => {
    setModalOpen(false);
  };

  return (
    <>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Outbound Details
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setModalOpen(false)}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Date
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {moment(data?.date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Customer
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.customer_name}
                  </Typography>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Production Code
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.code_production}
                  </Typography>
                </Grid>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Recipe Code
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.code_recipe}
                  </Typography>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Fabric Weight
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.fabric_weight}
                  </Typography>
                </Grid>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Color
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.color}
                  </Typography>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Chemical Name
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {data?.chemical_name}
                  </Typography>
                </Grid>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Weight (kg)
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {parseFloat(data?.weight_kg).toLocaleString("id-ID", {
                      maximumFractionDigits: 1,
                    })}
                  </Typography>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <Typography fontSize={12} color={"#444"} fontWeight={500}>
                    Weight (gr)
                  </Typography>
                  <Typography fontSize={16} color={"#222"} fontWeight={500}>
                    {parseFloat(data?.weight_gram).toLocaleString("id-ID", {
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default OutbondDetails;
