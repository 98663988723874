import React, { useState } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
// import Swal from "sweetalert2";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosInstance from "utils/api/api";
import { toast, Toaster } from "react-hot-toast";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const RecipeSummary = ({
  scouring,
  fdye,
  fdip,
  sdye,
  sdip,
  wash,
  setIsReview,
  color,
  pantone,
  code,
  process,
  processId,
  inputPerson,
  fullDate,
  orderNo,
  hex,
  special,
}) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [isScouring, setIsScouring] = useState(true);
  const [isFdye, setIsFdye] = useState(true);
  const [isFdip, setIsFdip] = useState(true);
  const [isIsSdye, setIsSdye] = useState(true);
  const [isSdip, setIsSdip] = useState(true);
  const [isWash, setIsWash] = useState(true);

  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);

  const handleBack = () => {
    navigate("/recipes/list");
    setIsReview(false);
  };

  const saveRecipe = async () => {
    const data = {
      code_recipe: code,
      color: color,
      pantone: pantone,
      year: fullDate,
      process: processId,
      serial_number: orderNo,
      scoring: scouring,
      dyestuff_first: fdye,
      chemical_first_dip: fdip,
      dyestuff_second: sdye,
      chemical_second_dip: sdip,
      neutralization: wash,
      hex_code: hex,
      special_code: special,
    };
    // console.log(data);
    await axiosInstance
      .post("/api/recipes", data, authentication)
      .then(() => {
        toast.success("Recipe created successfully!");
        successEffect.play();
        setTimeout(() => {
          handleBack();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        errorEffect.play();
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Box flex="1" sx={{ backgroundColor: "#F6F6F6" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            {code}
          </Typography>
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => saveRecipe()}
          >
            Save Recipe Code
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          paddingX={2}
          gap={2}
          mt={2}
        >
          <Stack padding={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
            <Stack gap={1}>
              <Typography variant="h7" fontWeight={600}>
                Process
              </Typography>
              <Typography fontSize={14}>{process}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h7" fontWeight={600}>
                Date
              </Typography>
              <Typography fontSize={14}>{fullDate}</Typography>
            </Stack>
          </Stack>
          <Stack padding={2} width={"50%"} gap={2} backgroundColor={"#FFF"}>
            <Stack direction={"row"}>
              <Stack gap={1} width={"50%"}>
                <Typography variant="h7" fontWeight={600}>
                  Color
                </Typography>
                <Typography fontSize={14}>{color}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography variant="h7" fontWeight={600}>
                  Pantone
                </Typography>
                <Typography fontSize={14}>{pantone}</Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Stack gap={1} width={"50%"}>
                <Typography variant="h7" fontWeight={600}>
                  Hex Color Code
                </Typography>
                <Typography fontSize={14}>{hex}</Typography>
              </Stack>
              <Stack gap={1} width={"50%"}>
                <Typography variant="h7" fontWeight={600}>
                  Input Person
                </Typography>
                <Typography fontSize={14}>{inputPerson}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          // direction={"row"}
          // justifyContent={"space-between"}
          paddingX={2}
          paddingY={2}
          // gap={2}
          mt={2}
        >
          {/* SCOURING TABS */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">Scouring</Typography>
              <Typography
                onClick={() => setIsScouring(!isScouring)}
                sx={{ cursor: "pointer" }}
              >
                {isScouring ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Typography>
            </Stack>
            {isScouring && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>gr/L</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {scouring.length !== 0
                  ? scouring.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* SCOURING TABS - END */}

          {/* FIRST DYE TABS */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">First Dyestuff</Typography>
              <Typography
                onClick={() => setIsFdye(!isFdye)}
                sx={{ cursor: "pointer" }}
              >
                {isFdye ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isFdye && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Percentage (%)</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {fdye.length !== 0
                  ? fdye.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* FIRST DYE TABS - END */}

          {/* FIRST DIP TABS */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">First Chemical Dip</Typography>
              <Typography
                onClick={() => setIsFdip(!isFdip)}
                sx={{ cursor: "pointer" }}
              >
                {isFdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isFdip && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>gr/L</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {fdip.length !== 0
                  ? fdip.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* FIRST DIP TABS - END */}

          {/* SECOND DYE TABS */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">Second Dyestuff</Typography>
              <Typography
                onClick={() => setIsSdye(!isIsSdye)}
                sx={{ cursor: "pointer" }}
              >
                {isIsSdye ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isIsSdye && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Percentage (%)</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {sdye.length !== 0
                  ? sdye.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* SECOND DYE TABS - END */}

          {/* SECOND DIP TABS */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">Second Chemical Dip</Typography>
              <Typography
                onClick={() => setIsSdip(!isSdip)}
                sx={{ cursor: "pointer" }}
              >
                {isSdip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isSdip && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>gr/L</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {sdip.length !== 0
                  ? sdip.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* SECOND DIP TABS - END */}

          {/* WASH TAB */}
          <Stack padding={2} gap={2} backgroundColor={"#FFF"} width={"auto"}>
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography variant="h6">Washing / Neutralization</Typography>
              <Typography
                onClick={() => setIsWash(!isWash)}
                sx={{ cursor: "pointer" }}
              >
                {isWash ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Stack>
            {isWash && (
              <Stack gap={2} width={"100%"}>
                <Stack direction={"rows"} gap={2}>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>Chemical</Typography>
                  </Stack>
                  <Stack width={"50%"}>
                    <Typography fontWeight={500}>gr/L</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {wash.length !== 0
                  ? wash.map((item) => (
                      <>
                        <Stack direction={"rows"} gap={2} key={item.chemical}>
                          <Stack width={"50%"}>
                            <Typography fontWeight={500}>
                              {item.chemical}
                            </Typography>
                          </Stack>
                          <Stack width={"50%"}>
                            <Typography fontWeight={300}>
                              {item.persentase}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </>
                    ))
                  : "-"}
              </Stack>
            )}
          </Stack>
          {/* WASH TAB - END */}
        </Stack>
      </Box>
    </>
  );
};

export default RecipeSummary;
