import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import CustomNoRowsOverlay from "../../components/NoRows";
import AddProcess from "./_components/AddProcess";
import ProcessDetails from "./_components/ProcessDetails";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "process_code",
    headerClassName: "header",
    headerName: "Process Code",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "process_name",
    headerClassName: "header",
    headerName: "Process Name",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <button
          style={{
            color: "#21573D",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #B8DBCA",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Active
        </button>
      ) : (
        <button
          style={{
            color: "#444",
            fontFamily: "Murecho",
            fontWeight: "600",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "5px",
            backgroundColor: "#F7F7F7",
          }}
        >
          Non-active
        </button>
      ),
  },
  {
    field: "_id",
    headerClassName: "header",
    headerName: "Action",
    width: 100,
    flex: 1,
    renderCell: () => (
      <button
        style={{
          color: "#fff",
          // fontFamily: "Murecho",
          fontWeight: "600",
          fontSize: "12px",
          border: "1px solid #222",
          borderRadius: "4px",
          backgroundColor: "#222",
          padding: "5px",
          cursor: "pointer",
        }}
      >
        See Details
      </button>
    ),
  },
];

const ProcessList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  // const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [processList, setProcessList] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [openModal, setOpenModal] = useState(false);
  const [process, setProcess] = useState(null);

  const getProcesses = () => {
    axiosInstance
      .get("/api/processes", authentication)
      .then((res) => {
        setProcessList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProcesses();
  }, []);

  const onDetail = (dt) => {
    setProcess(dt);
    setOpenModal(true);
  };

  return (
    <Box flex="1" sx={{ backgroundColor: "#f6f6f6" }}>
      <AddProcess
        open={modalOpen}
        setModalOpen={setModalOpen}
        getProcesses={getProcesses}
      />
      <ProcessDetails
        open={openModal}
        setModalOpen={setOpenModal}
        data={process}
        getProcesses={getProcesses}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Processes
        </Typography>
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Process
          </Button>
        )}
        {user.role === "Admin Product" && (
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              color: "#FFF",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Create Process
          </Button>
        )}
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#FFF",
              color: "#222",
            },
            "& .header-font": {
              backgroundColor: "#222",
              color: "#F6F6F6",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={processList}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(rows) => onDetail(rows.row)}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "none",
              color: "#535252",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ProcessList;
