import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import axiosInstance from "../../utils/api/api";
import CustomNoRowsOverlay from "../../components/NoRows";
// import Details from "./_components/DetailsModal";
import moment from "moment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploader from "components/FileUploader";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "chemical_name",
    headerClassName: "header",
    headerName: "Chemical Name",
    width: 300,
    // flex: 1.5,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "average_price",
    headerClassName: "header",
    headerName: "Average Price (Rp/gram)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "initial_stock",
    headerClassName: "header",
    headerName: "Initial (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "initial_stock_gram",
    headerClassName: "header",
    headerName: "Initial (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "initial_stock_price",
    headerClassName: "header",
    headerName: "Initial (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID")}
      </Typography>
    ),
  },
  {
    field: "incoming",
    headerClassName: "header",
    headerName: "Inbounds (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "incoming_gram",
    headerClassName: "header",
    headerName: "Inbounds (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "incoming_price",
    headerClassName: "header",
    headerName: "Inbounds (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "supplies",
    headerClassName: "header",
    headerName: "Supplies (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "supplies_gram",
    headerClassName: "header",
    headerName: "Supplies (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "supplies_price",
    headerClassName: "header",
    headerName: "Supplies (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "expending",
    headerClassName: "header",
    headerName: "Outbounds (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "expending_gram",
    headerClassName: "header",
    headerName: "Outbounds (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "expending_price",
    headerClassName: "header",
    headerName: "Outbounds (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "final_stock",
    headerClassName: "header",
    headerName: "Final (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "final_stock_gram",
    headerClassName: "header",
    headerName: "Final (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "final_stock_price",
    headerClassName: "header",
    headerName: "Final (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "audit_stock",
    headerClassName: "header",
    headerName: "Opname (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "audit_stock_gram",
    headerClassName: "header",
    headerName: "Opname (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "audit_stock_price",
    headerClassName: "header",
    headerName: "Opname (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "difference",
    headerClassName: "header",
    headerName: "Difference (kg)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "difference_gram",
    headerClassName: "header",
    headerName: "Difference (gr)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },
  {
    field: "difference_price",
    headerClassName: "header",
    headerName: "Difference (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {parseFloat(value).toLocaleString("id-ID", {
          maximumFractionDigits: 2,
        })}
      </Typography>
    ),
  },

  // {
  //   field: "_id",
  //   headerClassName: "header",
  //   headerName: "Action",
  //   width: 100,
  //   // flex: 1,
  //   renderCell: () => (
  //     <button
  //       style={{
  //         color: "#fff",
  //         // fontFamily: "Murecho",
  //         fontWeight: "600",
  //         fontSize: "12px",
  //         border: "1px solid #222",
  //         borderRadius: "4px",
  //         backgroundColor: "#222",
  //         padding: "5px",
  //         cursor: "pointer",
  //       }}
  //     >
  //       See Details
  //     </button>
  //   ),
  // },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const WarehouseReport = () => {
  const [pageSize, setPageSize] = useState(10);
  // const [modalOpen, setModalOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [value, setValue] = useState(0);
  const [dyestuff, setDyestuff] = useState([]);
  const [auxiliaries, setAuxiliaries] = useState([]);
  // const [item, setItem] = useState(null);
  const todayDate = new Date();
  const inn = moment(todayDate).format("yyyy-MM");
  const limit = moment(todayDate).add(0, "days");
  const formattedLimit = moment(limit).format("YYYY-MM-DD");
  const month = moment(todayDate).format("MM/YYYY");
  const [initialTime, setInitialTime] = useState(inn);
  const [period, setPeriod] = useState("");
  const [openUploader, setOpenUploader] = useState(false);

  const separateChemical = (chemicals) => {
    setDyestuff(chemicals?.filter((c) => c.category === "Dyestuff"));
    setAuxiliaries(chemicals?.filter((c) => c.category === "Auxiliaries"));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const onDetail = (dt) => {
  //   setItem(dt);
  //   setModalOpen(true);
  // };

  const getStocks = async () => {
    await axiosInstance
      .get(`/api/stocks?date=${month}`, authentication)
      .then((res) => {
        // console.log(res.data);
        separateChemical(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDataByPeriod = async () => {
    await axiosInstance
      .get(`/api/stocks?date=${period}`, authentication)
      .then((res) => {
        // console.log(res.data);
        separateChemical(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStocks();
  }, []);

  return (
    <Box flex="1" sx={{ backgroundColor: "#f6f6f6" }}>
      {/* <Details
        open={modalOpen}
        setModalOpen={setModalOpen}
        data={item}
        action={getStocks}
      /> */}
      <FileUploader
        open={openUploader}
        setOpen={setOpenUploader}
        title={"Upload File"}
        message={"Please select an EXCEL file only."}
        actionTitle={"Upload"}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F6F6F6" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Stock Report
        </Typography>
        {/* {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#222",
              borderRadius: "5px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Data Pelanggan
          </Button>
        )} */}
      </Stack>
      <Stack
        paddingX={3}
        gap={2}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={1}>
          <Stack gap={1}>
            <Typography fontSize={14}>Get data by month:</Typography>
            <Stack sx={{ backgroundColor: "#FFF", width: "full" }}>
              <input
                name="date"
                type="Month"
                value={initialTime}
                onChange={(e) => {
                  setPeriod(moment(e.target.value).format("MM/YYYY"));
                  setInitialTime(e.target.value);
                }}
                style={{
                  backgroundColor: "#FFF",
                  height: "28px",
                  width: "150px",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  border: "1px solid #c8c8c8",
                  borderRadius: "5px",
                  padding: "5px 12px",
                }}
                max={formattedLimit}
              />
            </Stack>
          </Stack>
          <Button
            sx={{
              textTransform: "capitalize",
              borderRadius: "11px",
              backgroundColor: "#222",
              height: "40px",
              color: "#FFF",
              bottom: 0,
              marginTop: "28px",
              ":hover": {
                backgroundColor: "#444",
                color: "#FFF",
              },
            }}
            variant="contained"
            disabled={period === "" ? true : false}
            onClick={() => getDataByPeriod()}
          >
            Get Data
          </Button>
        </Stack>
        {user.role === "Admin Warehouse" && (
          <Stack>
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "11px",
                backgroundColor: "#222",
                height: "40px",
                color: "#FFF",
                bottom: 0,
                marginTop: "28px",
                ":hover": {
                  backgroundColor: "#444",
                  color: "#FFF",
                },
              }}
              variant="contained"
              // disabled={startDate === "" || endDate === "" ? true : false}
              onClick={() => setOpenUploader(true)}
              startIcon={<UploadFileIcon />}
            >
              Upload File
            </Button>
          </Stack>
        )}
        {user.role === "SuperAdmin" && (
          <Stack>
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "11px",
                backgroundColor: "#222",
                height: "40px",
                color: "#FFF",
                bottom: 0,
                marginTop: "28px",
                ":hover": {
                  backgroundColor: "#444",
                  color: "#FFF",
                },
              }}
              variant="contained"
              // disabled={startDate === "" || endDate === "" ? true : false}
              onClick={() => setOpenUploader(true)}
              startIcon={<UploadFileIcon />}
            >
              Upload File
            </Button>
          </Stack>
        )}
      </Stack>

      <Box sx={{ padding: 3 }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Dyestuff"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
            <Tab
              label="Auxiliaries"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", color: "#222" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={dyestuff}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                // onRowClick={(rows) => onDetail(rows.row)}
                // onRowClick={(rows) => navigate(`/master/customers/${rows.id}`)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#FFF",
                  color: "#222",
                },
                "& .header-font": {
                  backgroundColor: "#F6F6F6",
                  color: "#F6F6F6",
                },
              }}
            >
              <DataGrid
                rows={auxiliaries}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                disableSelectionOnClick={true}
                // onRowClick={(rows) => onDetail(rows.row)}
                // onRowClick={(rows) => navigate(`/master/customers/${rows.id}`)}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "none",
                  color: "#535252",
                }}
              />
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default WarehouseReport;
