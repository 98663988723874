import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  Typography,
  Stack,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import logo from "../../../assets/erecipe_logo.png";
import CustomTextField from "components/TextField";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import EastIcon from "@mui/icons-material/East";
import axiosInstance from "utils/api/api";
import { useEffect } from "react";

const Renderer = ({
  open,
  setModalOpen,
  data,
  startDate,
  endDate,
  today,
  startPeriod,
  endPeriod,
  customers,
}) => {
  const [machineList, setMachineList] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // console.log(data);
  // console.log(customers);

  const date1 = new Date(startPeriod);
  const date2 = new Date(endPeriod);

  const period = (date2?.getTime() - date1?.getTime()) / (1000 * 60 * 60 * 24);

  const getMachines = () => {
    axiosInstance
      .get("/api/machines", authentication)
      .then((res) => {
        // console.log(res.data);
        setMachineList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMachines();
  }, []);

  const totalWeight = data?.map((tw) => tw?.weight);
  const totalTarget = machineList?.map((mcl) => mcl?.objective);
  const daily = machineList?.map((mcl) => mcl?.daily_target);
  function sumUp(arr) {
    let res =
      arr.length !== 0
        ? arr
            ?.map(function (elt) {
              return parseFloat(elt);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : null;
    return res;
  }

  function getLoads(name) {
    let total = data?.map((tw) =>
      tw?.machine_name === name ? tw?.weight : "0",
    );
    let res =
      total.length !== 0
        ? total
            ?.map(function (elt) {
              return parseFloat(elt);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : null;
    return res;
  }

  function getCustomerLoads(name) {
    let total = data?.map((tw) =>
      tw?.customer_name === name ? tw?.weight : "0",
    );
    let res =
      total.length !== 0
        ? total
            ?.map(function (elt) {
              return parseFloat(elt);
            })
            .reduce(function (a, b) {
              return a + b;
            })
        : null;
    return res;
  }

  const sizes = [
    {
      _id: 0,
      name: "A4",
      value: "11.7in 8.3in",
    },
    {
      _id: 1,
      name: "Legal",
      value: "14in 8in",
    },
    {
      _id: 2,
      name: "Letter",
      value: "11in 8.5in",
    },
    {
      _id: 3,
      name: "US Fanfold",
      value: "14.1in 11in",
    },
  ];

  const [pageSize, setPageSize] = useState("14.1in 11in");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `E-Recipe Dashboard`,
    pageStyle: `@page { size: ${pageSize} }`,
  });

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ width: 1250, height: "750px" }}
      >
        <Stack
          paddingY={2}
          justifyContent={"flex-end"}
          direction={"row"}
          gap={1}
          borderBottom={"1px solid #c8c8c8"}
          mx={2}
        >
          <Stack width={"150px"}>
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "5px",
                backgroundColor: "#222",
                padding: "8px",
                color: "#FFF",
                ":hover": {
                  backgroundColor: "#444",
                  color: "#FFF",
                },
              }}
              variant="contained"
              onClick={handlePrint}
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          </Stack>
          <Stack width={"150px"}>
            <CustomTextField
              select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {sizes.map((sz) => (
                <MenuItem
                  key={sz._id}
                  value={sz.value}
                  onClick={() =>
                    toast.success(`Page size selected: ${sz.name}`)
                  }
                >
                  {sz.name}
                </MenuItem>
              ))}
            </CustomTextField>
          </Stack>
        </Stack>
        <Stack
          sx={{
            paddingX: 2,
            paddingY: 1,
            overflow: "scroll",
          }}
          ref={componentRef}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            mb={1}
          >
            <Stack direction="row" alignItems="center" height="auto" gap={1}>
              <Avatar
                src={logo}
                sx={{ borderRadius: 0, height: "15px", width: "15px" }}
              />
              <Typography
                sx={{ fontWeight: 500, color: "#222", textAlign: "left" }}
                fontSize={12}
              >
                E-Recipe Dashboard
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography
                sx={{ fontWeight: 500, color: "#222", textAlign: "left" }}
                fontSize={12}
              >
                PT Perajutan Sahabat
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            py={1}
            borderTop={"1px solid #c8c8c8"}
          >
            <Stack>
              <Typography variant="h2" fontSize={28} fontWeight={500}>
                Production Summary
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"} gap={1} direction={"row"}>
              {/* <Stack
                padding={2}
                justifyContent={"space-between"}
                gap={1}
                p={"10px"}
                border={"1px solid #c8c8c8"}
              >
                <Stack width={"150px"}>
                  <CustomTextField
                    select
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    {sizes.map((sz) => (
                      <MenuItem
                        key={sz._id}
                        value={sz.value}
                        onClick={() =>
                          toast.success(`Page size selected: ${sz.name}`)
                        }
                      >
                        {sz.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Stack>
                <Stack>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      backgroundColor: "#222",
                      padding: "8px",
                      color: "#FFF",
                      ":hover": {
                        backgroundColor: "#444",
                        color: "#FFF",
                      },
                    }}
                    variant="contained"
                    onClick={handlePrint}
                    startIcon={<PrintIcon />}
                  >
                    Print
                  </Button>
                </Stack>
              </Stack> */}
              <Stack
                p={"10px"}
                border={"1px solid #c8c8c8"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  gap={2}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Stack>
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={"#535252"}
                    >
                      From:
                    </Typography>
                    <Typography fontSize={12}>
                      {startDate !== "" ? startDate : today}
                    </Typography>
                  </Stack>
                  <EastIcon />
                  <Stack>
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={"#535252"}
                    >
                      To:
                    </Typography>
                    <Typography fontSize={12}>
                      {endDate !== "" ? endDate : today}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  sx={{ borderTop: "1px solid #c8c8c8" }}
                  width={"100%"}
                  mt={1}
                  pt={1}
                  textAlign={"center"}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={600} color={"#535252"} fontSize={12}>
                    Period
                  </Typography>
                  <Typography fontSize={12}>
                    {period === 0
                      ? "Same day"
                      : period > 0
                      ? `${period} day(s)`
                      : "Today"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"flex-start"} py={1}>
            <Stack justifyContent={"flex-start"} gap={1}>
              <Typography variant="h2" fontSize={12} fontWeight={500}>
                Production by machine:
              </Typography>
              <Stack direction={"row"}>
                <Stack width={"fit-content"}>
                  <Grid p={1} border={"1px solid #c8c8c8"}>
                    <Typography fontSize={12} fontWeight={600}>
                      Machine name
                    </Typography>
                  </Grid>
                  <Grid p={1} border={"1px solid #c8c8c8"}>
                    <Typography fontSize={12} fontWeight={600}>
                      Daily production (kg)
                    </Typography>
                  </Grid>
                  {/* Maximum */}
                  {period === 0 ? (
                    <Grid p={1} border={"1px solid #c8c8c8"}>
                      <Typography fontSize={12} fontWeight={600}>
                        Maximum production (kg)
                      </Typography>
                    </Grid>
                  ) : null}
                  {period === 0 ? (
                    <Grid p={1} border={"1px solid #c8c8c8"}>
                      <Typography fontSize={12} fontWeight={600}>
                        Daily prod. - Max. prod.
                      </Typography>
                    </Grid>
                  ) : null}
                  {/* Maximum - END */}
                  {/* Daily Quota */}
                  {period === 0 ? (
                    <Grid p={1} border={"1px solid #c8c8c8"}>
                      <Typography fontSize={12} fontWeight={600}>
                        Daily target (kg)
                      </Typography>
                    </Grid>
                  ) : null}
                  {period === 0 ? (
                    <Grid p={1} border={"1px solid #c8c8c8"}>
                      <Typography fontSize={12} fontWeight={600}>
                        Daily prod. - Daily target
                      </Typography>
                    </Grid>
                  ) : null}
                  {/* Daily Quota - END */}
                </Stack>
                {machineList?.map((machine) => (
                  <Stack width={"100px"} key={machine?._id}>
                    <Grid p={1} border={"1px solid #c8c8c8"}>
                      <Typography fontSize={12} fontWeight={600}>
                        {machine?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      border={"1px solid #c8c8c8"}
                      textAlign={"start"}
                    >
                      <Typography fontSize={12}>
                        {getLoads(machine?.name)?.toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                      </Typography>
                    </Grid>
                    {/* Maximum */}
                    {period === 0 ? (
                      <Grid
                        p={1}
                        border={"1px solid #c8c8c8"}
                        textAlign={"start"}
                      >
                        <Typography fontSize={12}>
                          {(period === 0
                            ? machine?.objective * 1
                            : machine?.objective * period
                          )?.toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Grid>
                    ) : null}
                    {period === 0 ? (
                      <Grid
                        p={1}
                        border={"1px solid #c8c8c8"}
                        textAlign={"start"}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            // border: "1px solid #c8c8c8",
                            textAlign: "left",
                            fontWeight: 600,
                            color:
                              getLoads(machine?.name) - machine?.objective <= 0
                                ? "#c61a09"
                                : "#014421",
                          }}
                        >
                          {(
                            getLoads(machine?.name) - machine?.objective
                          )?.toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Grid>
                    ) : null}
                    {/* Maximum - END */}
                    {/* Daily Quota */}
                    {period === 0 ? (
                      <Grid
                        p={1}
                        border={"1px solid #c8c8c8"}
                        textAlign={"start"}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            // border: "1px solid #c8c8c8",
                            textAlign: "left",
                            // color:
                            //   getLoads(machine?.name) - machine?.objective <= 0
                            //     ? "#c61a09"
                            //     : "#014421",
                          }}
                        >
                          {/* {(
                            getLoads(machine?.name) - machine?.objective
                          )?.toLocaleString()} */}
                          {(machine?.daily_target - 0).toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Grid>
                    ) : null}
                    {period === 0 ? (
                      <Grid
                        p={1}
                        border={"1px solid #c8c8c8"}
                        textAlign={"start"}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            // border: "1px solid #c8c8c8",
                            textAlign: "left",
                            fontWeight: 600,
                            color:
                              getLoads(machine?.name) - machine?.daily_target <=
                              0
                                ? "#c61a09"
                                : "#014421",
                          }}
                        >
                          {(
                            getLoads(machine?.name) - machine?.daily_target
                          )?.toLocaleString("id-ID", {
                            maximumFractionDigits: 1,
                          })}
                        </Typography>
                      </Grid>
                    ) : null}
                    {/* Daily Quota - END */}
                  </Stack>
                ))}
                <Stack width={"fit-content"}>
                  <Grid p={1} border={"1px solid #c8c8c8"}>
                    <Typography fontSize={12} fontWeight={600}>
                      Total / All machine
                    </Typography>
                  </Grid>
                  <Grid p={1} border={"1px solid #c8c8c8"} textAlign={"start"}>
                    <Typography fontSize={12} fontWeight={600}>
                      {sumUp(totalWeight)?.toLocaleString("id-ID", {
                        maximumFractionDigits: 1,
                      })}
                    </Typography>
                  </Grid>
                  {/* Sum - Maximum */}
                  {period === 0 ? (
                    <Grid
                      p={1}
                      border={"1px solid #c8c8c8"}
                      textAlign={"start"}
                    >
                      <Typography fontSize={12} fontWeight={600}>
                        {sumUp(totalTarget)?.toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                      </Typography>
                    </Grid>
                  ) : null}

                  {period === 0 ? (
                    <Grid
                      p={1}
                      border={"1px solid #c8c8c8"}
                      textAlign={"start"}
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{
                          color:
                            sumUp(totalWeight) - sumUp(totalTarget) <= 0
                              ? "#c61a09"
                              : "#014421",
                        }}
                      >
                        {(
                          sumUp(totalWeight) - sumUp(totalTarget)
                        ).toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                      </Typography>
                    </Grid>
                  ) : null}
                  {/* Sum - Maximum - END */}

                  {/* Sum - Daily */}

                  {period === 0 ? (
                    <Grid
                      p={1}
                      border={"1px solid #c8c8c8"}
                      textAlign={"start"}
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        // sx={{
                        //   color:
                        //     sumUp(totalWeight) - sumUp(totalTarget) <= 0
                        //       ? "#c61a09"
                        //       : "#014421",
                        // }}
                      >
                        {/* {(
                          sumUp(totalWeight) - sumUp(totalTarget)
                        ).toLocaleString()} */}
                        {sumUp(daily)?.toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                      </Typography>
                    </Grid>
                  ) : null}
                  {period === 0 ? (
                    <Grid
                      p={1}
                      border={"1px solid #c8c8c8"}
                      textAlign={"start"}
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{
                          color:
                            sumUp(totalWeight) - sumUp(daily) <= 0
                              ? "#c61a09"
                              : "#014421",
                        }}
                      >
                        {(sumUp(totalWeight) - sumUp(daily))?.toLocaleString(
                          "id-ID",
                          {
                            maximumFractionDigits: 1,
                          },
                        )}
                      </Typography>
                    </Grid>
                  ) : null}

                  {/* Sum - Daily - END */}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"flex-start"} py={1}>
            <Stack justifyContent={"flex-start"} gap={1}>
              <Typography variant="h2" fontSize={12} fontWeight={500}>
                Production by customer:
              </Typography>
              <Stack>
                <Stack direction={"row"}>
                  <Grid
                    p={1}
                    border={"1px solid #c8c8c8"}
                    // minHeight={"60px"}
                    width={"200px"}
                  >
                    <Typography fontSize={12} fontWeight={600}>
                      Customer
                    </Typography>
                  </Grid>
                  <Grid p={1} border={"1px solid #c8c8c8"} width={"150px"}>
                    <Typography fontSize={12} fontWeight={600}>
                      Weight (kg)
                    </Typography>
                  </Grid>
                </Stack>
                {customers?.map(
                  (dt) =>
                    getCustomerLoads(dt?.name) !== 0 && (
                      <Stack key={dt?.name} direction={"row"}>
                        <Grid
                          p={1}
                          border={"1px solid #c8c8c8"}
                          // minHeight={"60px"}
                          width={"200px"}
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={600}
                            // minWidth={"100px"}
                          >
                            {dt?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          p={1}
                          border={"1px solid #c8c8c8"}
                          textAlign={"start"}
                          width={"150px"}
                        >
                          {/* <Typography fontSize={12}>
                        {dt?.weight?.toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                        {data?.map((dn) =>
                          dn.customer_name === dt?.name ? dn.weight : 0,
                        )}
                      </Typography> */}
                          <Typography fontSize={12}>
                            {getCustomerLoads(dt?.name)?.toLocaleString(
                              "id-ID",
                              {
                                maximumFractionDigits: 1,
                              },
                            )}
                          </Typography>
                        </Grid>
                      </Stack>
                    ),
                )}
                <Stack direction={"row"}>
                  <Grid p={1} border={"1px solid #c8c8c8"} width={"200px"}>
                    <Typography fontSize={12} fontWeight={600}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid
                    p={1}
                    border={"1px solid #c8c8c8"}
                    textAlign={"start"}
                    width={"150px"}
                  >
                    <Typography fontSize={12} fontWeight={600}>
                      {sumUp(totalWeight)?.toLocaleString("id-ID", {
                        maximumFractionDigits: 1,
                      })}
                    </Typography>
                  </Grid>
                </Stack>
                <Stack width={"fit-content"}></Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack py={2}>
            <TableContainer>
              <Table size="small">
                <TableHead
                  sx={{
                    border: "1px solid #c8c8c8",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      No.
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Date
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Machine
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Fabric Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      PO
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Job Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Color
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Recipe Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Weight (kg)
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Process Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Start
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        border: "1px solid #c8c8c8",
                      }}
                    >
                      Finish
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: "1px solid #c8c8c8" }}>
                  {data?.map((dt, i) => (
                    <TableRow key={dt?._id}>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {i + 1}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {moment(dt?.finish_date).format("DD/MM/YYYY")}
                      </TableCell> */}
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.machine_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.customer_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.fabric_code}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.purchase_order}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.code_production}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.color}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.code_recipe}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {parseFloat(dt?.weight)?.toLocaleString("id-ID", {
                          maximumFractionDigits: 1,
                        })}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {dt?.process_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {moment(dt?.start_date).format("DD/MM/YYYY")}
                        <br />
                        {dt?.start_time}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          border: "1px solid #c8c8c8",
                        }}
                      >
                        {moment(dt?.finish_date).format("DD/MM/YYYY")}
                        <br />
                        {dt?.finish_time}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </ModalCustom>
    </>
  );
};

export default Renderer;
