import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Stack, Typography, Paper } from "@mui/material";
// import axiosInstance from "utils/api/api";
// import DeleteIcon from "@mui/icons-material/Delete";
// import RemoveAdminModal from "../_components/RemoveAdminModal";
// import EditAdminModal from "../_components/EditAdminModal";
// import ChangePasswordModal from "../_components/ChangePasswordModal";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

const AdminDetail = () => {
  //   const [openModal, setOpenModal] = useState(false);
  //   const [openEditModal, setOpenEditModal] = useState(false);
  //   const [openPasswordModal, setOpenPasswordModal] = useState(false);
  //   const [adminDetail, setAdminDetail] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.adminId;
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const user = JSON.parse(localStorage.getItem("admin_data"));

  const roboUrl = "https://robohash.org/";

  //   useEffect(() => {
  //     axiosInstance.get(`/users/admin/${id}`, authentication).then((res) => {
  //       const response = res.data.result;
  //       setAdminDetail(response.data);
  //     });
  //   }, []);

  //   const removeAdmin = (id) => {
  //     axiosInstance.delete(`/users/admin/${id}`, authentication).then((res) => {
  //       toast("Admin berhasil dihapus!");
  //       setOpenModal(false);
  //       setTimeout(() => {
  //         navigate("/admin");
  //       }, 2000);
  //     });
  //   };

  const back = () => {
    navigate("/admin");
  };

  return (
    <>
      {/* <EditAdminModal
        open={openEditModal}
        setOpenEditModal={setOpenEditModal}
        adminDetail={adminDetail}
      />
      <RemoveAdminModal
        open={openModal}
        setOpenModal={setOpenModal}
        data={adminDetail}
        removeAdmin={removeAdmin}
      /> */}
      <Box flex="1" overflow="scroll" maxHeight="100%">
        <Stack
          direction="row"
          justifyContent="flex-start"
          paddingX={3}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Stack>
            <Button
              startIcon={<WestIcon />}
              sx={{
                textTransform: "capitalize",
                color: "#6D7688",
                fontWeight: "400",
              }}
              onClick={back}
            >
              Back
            </Button>
          </Stack>
        </Stack>
        <Paper sx={{ padding: 3, height: "100%" }}>
          <Box paddingTop={2}>
            <Stack direction="row">
              <Stack marginRight={2}>
                <Avatar
                  src={`${roboUrl}${user.full_name}`}
                  sx={{
                    backgroundColor: `#10838E`,
                    width: "200px",
                    height: "100%",
                    borderRadius: ".5rem",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "600px" }} paddingTop={1}>
                <Typography
                  sx={{
                    color: "#0C78BC",
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                  variant="h4"
                >
                  {user.full_name}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "500",
                    }}
                    variant="h5"
                  >
                    Role
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "400",
                    }}
                    variant="h5"
                  >
                    {user.role}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  marginY={2}
                >
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "500",
                    }}
                    variant="h5"
                  >
                    Email address
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "400",
                    }}
                    variant="h5"
                  >
                    {user.email}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "500",
                    }}
                    variant="h5"
                  >
                    Phone number
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6D7688",
                      fontWeight: "400",
                    }}
                    variant="h5"
                  >
                    {user.phone_number}
                  </Typography>
                </Stack>
                {/* <Stack
                direction="row"
                justifyContent="space-between"
                marginTop={2}
              >
                <Typography
                  sx={{  color: "#6D7688", fontWeight: "600" }}
                >
                  Last Login
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", color: "#6D7688", fontWeight: "400" }}
                >
                  HH:MM, DD:MM:YYYY
                </Typography>
              </Stack> */}
                {/* <Stack
                direction="row"
                justifyContent="space-between"
                marginTop={2}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#0C78BC",
                    backgroundColor: "FFF",
                    textTransform: "capitalize",
                    border: "1px solid #0C78BC",
                  }}
                  onClick={() => setOpenPasswordModal(true)}
                >
                  Reset Password
                </Button>
              </Stack> */}
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default AdminDetail;
