import React from "react";
import InboundList from "pages/Inbound";
import OutbondList from "pages/Outbond";
import WarehouseReport from "pages/Reports/warehouse";
import InboundDetails from "pages/Inbound/_components/InboundDetails";

const root = "/warehouse";

const warehouse = [
  {
    path: `${root}/inbound`,
    component: <InboundList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/inbound/detail/:id`,
    component: <InboundDetails />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/outbond`,
    component: <OutbondList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/report`,
    component: <WarehouseReport />,
    sidebar: true,
    navbar: true,
  },
];

export default warehouse;
