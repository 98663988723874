import React, { useState } from "react";
import { Box, Typography, Stack, Paper, Breadcrumbs } from "@mui/material";
import DashboardCard from "components/DashboardCard";
import dashboard from "../../assets/dummy/dashboardData.json";
import moment from "moment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Chart from "react-apexcharts";
const bar = {
  series: [
    {
      name: "Berhasil",
      data: [6, 12, 14, 16, 19],
    },
    {
      name: "Gagal",
      data: [8, 15, 16, 16, 17],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 400,
      stacked: true,
    },
    colors: ["#B870FF", "#EB5757"],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 20,
      title: {
        text: "Age",
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + " Transaction";
        },
      },
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: ["65+", "50-65", "41-50", "31-40", "20-30"],
      title: {
        text: "Transaction",
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        },
      },
    },
  },
};
const Dashboard = () => {
  const today = moment().format("LL");

  const [ringkasanKeuangan, setRingkasanKeuangan] = useState([
    {
      id: 1,
      name: "Omset",
      value: "400.000.000,00",
    },
    {
      id: 2,
      name: "Fee Partner",
      value: "400.000.000,00",
    },
    {
      id: 3,
      name: "Fee Platform",
      value: "400.000.000,00",
    },
  ]);

  const [ringkasanPerusahaan, setRingkasanPerusahaan] = useState([
    {
      id: 1,
      name: "IPLT",
      value: "2",
    },
    {
      id: 2,
      name: "Partner",
      value: "400",
    },
    {
      id: 3,
      name: "Costumer",
      value: "400",
    },
    {
      id: 4,
      name: "Layanan",
      value: "400",
    },
    {
      id: 5,
      name: "Driver",
      value: "400",
    },
    {
      id: 6,
      name: "Fleet",
      value: "400",
    },
    {
      id: 7,
      name: "Volume",
      value: "400",
    },
    {
      id: 8,
      name: "Article",
      value: "200",
    },
  ]);
  const [ringkasanTransaction, setRingkasanTransaction] = useState([
    {
      id: 1,
      name: "Total transaksi berhasil",
      value: "400.000.000,00",
    },
    {
      id: 2,
      name: "Total transaksi gagal",
      value: "400.000.000,00",
    },
  ]);

  const renderCardRingkasanKeuangan = () =>
    ringkasanKeuangan.map((data) => {
      return (
        <Box
          sx={{
            width: 450,
            height: 80,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "primary.white",
              opacity: [0.9, 0.8, 0.7],
            },
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
          key={data.id}
        >
          <AccountBalanceWalletIcon />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <label htmlFor="" style={{ fontSize: "12px" }}>
              {data.name}
            </label>
            <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
              IDR {data.value}
            </p>
          </Box>
        </Box>
      );
    });

  const renderCardRingkasanPerusahaan = () =>
    ringkasanPerusahaan.map((data) => {
      return (
        <Box
          sx={{
            width: 333,
            height: 80,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "primary.white",
              opacity: [0.9, 0.8, 0.7],
            },
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
          key={data.id}
        >
          <AccountBalanceWalletIcon />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <label htmlFor="" style={{ fontSize: "12px" }}>
              {data.name}
            </label>
            <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
              {data.value}
            </p>
          </Box>
        </Box>
      );
    });

  const renderCardTransaction = () =>
    ringkasanTransaction.map((data) => {
      return (
        <Box
          sx={{
            width: 660,
            height: 120,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "primary.white",
              opacity: [0.9, 0.8, 0.7],
            },
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            border: "1px solid #eee",
          }}
          key={data.id}
        >
          <AccountBalanceWalletIcon />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <label htmlFor="" style={{ fontSize: "12px" }}>
              {data.name}
            </label>
            <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
              IDR {data.value}
            </p>
            <p style={{ fontSize: "12px", margin: 0 }}>
              {" "}
              <span style={{ color: "red" }}>-4.5%</span> (vs 17-23 Juni 2023)
            </p>
          </Box>
        </Box>
      );
    });

  return (
    <>
      <Paper
        sx={{
          paddingX: 3,
          paddingY: 1,
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography variant="p" fontWeight="500">
            Home
          </Typography>
          <Typography variant="p" fontWeight="500">
            Overview
          </Typography>
        </Breadcrumbs>
      </Paper>
      <Paper
        sx={{
          paddingX: 3,
          paddingY: 3,
          height: "auto",
          backgroundColor: "#eee",
        }}
      >
        <Typography variant="h4" fontWeight="500" color="#0E78BD">
          Dashboard
        </Typography>
        <Typography variant="h6" fontWeight="500">
          Ringkasan Keuangan
        </Typography>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          sx={{ marginTop: "20px", marginBottom: "20px" }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {renderCardRingkasanKeuangan()}
        </Stack>
        <Typography variant="h6" fontWeight="500">
          Ringkasan Perusahaan
        </Typography>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          sx={{ marginTop: "20px", marginBottom: "20px" }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {renderCardRingkasanPerusahaan()}
        </Stack>
        <Typography variant="h6" fontWeight="500">
          Transaksi
        </Typography>
        <Paper
          sx={{
            paddingX: 3,
            paddingY: 3,
            height: "min-content",
            backgroundColor: "white",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            sx={{ marginTop: "20px", marginBottom: "20px" }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
          >
            {renderCardTransaction()}
          </Stack>
          <Typography variant="h6" fontWeight="500">
            Riwayat Transaksi
          </Typography>
          <Chart
            series={bar.series}
            options={bar.options}
            type="bar"
            height="auto"
            width="100%"
          />
        </Paper>
      </Paper>
      {/* <Box sx={{ flexGrow: 1 }}>
        <Stack
          // direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F6F6F6" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Dashboard
          </Typography>
          <Typography fontSize="14px" color="#B1B1B1" fontWeight="600">
            {today}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          paddingY={2}
          sx={{ gap: 2 }}
        >
          {dashboard.data.map((data) => (
            <DashboardCard data={data} />
          ))}
        </Stack>
      </Box> */}
    </>
  );
};

export default Dashboard;
