import React from "react";
import ProductionList from "pages/Productions";
import ProductionReport from "pages/Reports/production";
import ProductionDetails from "pages/Productions/_components/ProductionDetails";

const root = "/productions";

const productions = [
  {
    path: `${root}/list`,
    component: <ProductionList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/list/detail/:prodId`,
    component: <ProductionDetails />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/report`,
    component: <ProductionReport />,
    sidebar: true,
    navbar: true,
  },
];

export default productions;
