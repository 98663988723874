import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const AddInbound = ({ open, setModalOpen, refetch }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [suppliers, setSuppliers] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [chemical_name, setChemical_name] = useState("");
  const [disposition, setDisposition] = useState("");
  const [date, setDate] = useState("");
  const [quantity, setQuantity] = useState("");
  // const [chemicalAmount, setChemicalAmount] = useState("");
  const [total_weight, setTotal_weight] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("0");
  const [selectedSupplier, setSelectedSupplier] = useState("Choose a supplier");
  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);

  const selectDate = (date) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setDate(formattedDate);
    // console.log(formattedDate);
  };

  const handleClose = () => {
    setModalOpen(false);
    setChemical_name("");
    // setChemicalAmount("");
    setDisposition("");
    setDate("");
    setWeight("");
    setPrice("");
    setQuantity("");
  };

  const getSuppliers = () => {
    axiosInstance
      .get("/api/suppliers", authentication)
      .then((res) => {
        // console.log(res.data);
        setSuppliers(res.data.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChemicals = () => {
    axiosInstance
      .get("/api/chemicals", authentication)
      .then((res) => {
        // console.log(res.data);
        setChemicals(res.data.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    const data = {
      item_name: chemical_name,
      supplier: selectedSupplier,
      quantity: quantity,
      date: date,
      disposition: disposition,
      weight: weight,
      price: price,
    };

    axiosInstance
      .post(`/api/inbounds`, data, authentication)
      .then(() => {
        // console.log(res.data);
        successEffect.play();
        toast.success("Inbound data added!");
        setModalOpen(false);
        refetch();
      })
      .catch((error) => {
        console.log(error);
        errorEffect.play();
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const selectChemical = (ch) => {
    setChemical_name(ch.chemical_name);
    // setChemicalAmount(ch.amount);
  };

  useEffect(() => {
    getSuppliers();
    getChemicals();
  }, []);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalCustom
        open={open}
        handleClose={() => handleClose()}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Inbound Data
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setModalOpen(false)}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    id="date"
                    type="date"
                    label={"Date"}
                    onChange={(e) => selectDate(e.target.value)}
                    placeholder="Input the job order date"
                    InputProps={{
                      inputProps: { min: "2023-12-01", max: "2050-12-30" },
                    }}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="disposition"
                    label="Disposition"
                    placeholder="Input the disposition's serial number"
                    onChange={(e) => setDisposition(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="supplier"
                    label="Supplier"
                    placeholder={"Supplier's name"}
                    select
                    value={selectedSupplier}
                    onChange={(e) => setSelectedSupplier(e.target.value)}
                  >
                    <MenuItem value={"Choose a supplier"}>
                      Choose a supplier
                    </MenuItem>
                    {suppliers.map((sp) => (
                      <MenuItem key={sp?._id} value={sp?._id}>
                        {sp?.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={chemicals?.map((ctm) =>
                      ctm.chemical_name !== "" ? `${ctm.chemical_name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      chemicals.filter((ct) =>
                        `${ct.chemical_name}` === newValue
                          ? selectChemical(ct)
                          : null,
                      );
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Choose a chemical"
                        label="Chemical Name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="qty"
                    label="Quantity"
                    type="text"
                    placeholder={"The quantity of the item"}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                      // setWeight(e.target.value * chemicalAmount);
                    }}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="weight"
                    label="Weight (kg)"
                    type="text"
                    // value={quantity !== "" ? weight : chemicalAmount}
                    // value={
                    //   chemical_name !== ""
                    //     ? chemicalAmount
                    //     : "Please select the chemical"
                    // }
                    placeholder={"Please input the weight of the item"}
                    // disabled
                    onChange={(e) => {
                      setWeight(e.target.value);
                      setTotal_weight(e.target.value * quantity);
                    }}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <CustomTextField
                    name="total_weight"
                    label="Total Weight (kg)"
                    type="text"
                    value={weight !== "" ? total_weight : "0"}
                    placeholder={"Please input the quantity and weight first"}
                    disabled
                  />
                </Grid>
                {/* <Grid item width={"50%"}>
                  <CustomTextField
                    name="price"
                    label="Price (Rp)"
                    type="number"
                    placeholder="Input price"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid> */}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                // type="submit"
                onClick={() => handleSubmit()}
                variant="contained"
                disabled={
                  // chemical_name === "" ||
                  disposition === "" ||
                  date === "" ||
                  quantity === "" ||
                  weight === "" ||
                  price === ""
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default AddInbound;
