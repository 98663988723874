import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import successSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const CreateProduction = ({ open, setModalOpen, getProductions }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [fabric, setFabric] = useState("Choose a fabric");
  const [weight, setWeight] = useState(0);
  const [roll, setRoll] = useState(0);
  const [recipe, setRecipe] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [machines, setMachines] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [recipes, setRecipes] = useState([]);
  // const [customer, setCustomer] = useState("Choose a customer");
  // const [machine, setMachine] = useState("Choose a machine");
  const [recipeId, setRecipeId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [fabricId, setFabricId] = useState("");
  const [machineId, setMachineId] = useState("");
  const [scouringVlot, setScouringVlot] = useState("");
  const [dyeingVlot, setDyeingVlot] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [productionCode, setProductionCode] = useState("");
  const [productionDate, setProductionDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [checked, setChecked] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const successEffect = new Audio(successSound);
  const errorEffect = new Audio(errorSound);

  const handleClose = () => {
    setModalOpen(false);
    setChecked(false);
    setProductionCode("");
  };

  const onCustomer = (ct) => {
    setCustomerId(ct._id);
  };

  const onRecipe = (rc) => {
    setRecipe(rc);
    setRecipeId(rc._id);
  };

  const onFabric = (fb) => {
    setFabricId(fb._id);
    // console.log(fb);
  };

  const onMachine = (mc) => {
    setMachineId(mc._id);
  };

  const getCustomers = () => {
    axiosInstance
      .get("/api/customers", authentication)
      .then((res) => {
        // console.log(res?.data);
        setCustomers(res?.data?.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMachines = () => {
    axiosInstance
      .get("/api/machines", authentication)
      .then((res) => {
        // console.log(res?.data);
        setMachines(res?.data?.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFabrics = () => {
    axiosInstance
      .get("/api/fabrics", authentication)
      .then((res) => {
        // console.log(res?.data);
        setFabrics(res?.data?.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecipes = () => {
    axiosInstance
      .get("/api/recipes/", authentication)
      .then((res) => {
        // console.log(res?.data);
        // setRecipes(res?.data);
        setRecipes(res?.data?.filter((rs) => rs.status === true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheck = () => {
    codeChecking();
  };

  const onProceed = () => {
    setChecked(true);
    setConfirmationModal(false);
  };

  const codeChecking = async () => {
    await axiosInstance
      .post(
        "/api/production_lists/check-code-production",
        { code_production: productionCode },
        authentication,
      )
      .then((res) => {
        // console.log(res);
        successEffect.play();
        toast.success(res?.data?.message);
        setChecked(true);
      })
      .catch(() => {
        errorEffect.play();
        toast.error("Production code has been used");
        setConfirmationModal(true);
      });
  };

  const createProduction = async () => {
    const data = {
      code_production: productionCode,
      color: recipe.color,
      recipe: recipeId,
      date: productionDate,
      customer: customerId,
      fabric: fabricId,
      machine: machineId,
      vlot_scouring: scouringVlot,
      vlot_dyeing: dyeingVlot,
      purchase_order: purchaseOrder,
      weight: weight,
      roll: roll,
      remark: remarks,
    };

    await axiosInstance
      .post("/api/production_lists/", data, authentication)
      .then(() => {
        successEffect.play();
        setModalOpen(false);
        getProductions();
        toast.success("Production has been successfully created!");
        // setRecipes(res?.data);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something is wrong. Please try again.");
      });
  };

  const selectDate = (date) => {
    // console.log(date);
    const formattedDate = moment(date).format("DD/MM/YYYY");
    setProductionDate(formattedDate);
    // console.log(formattedDate);
  };

  useEffect(() => {
    getCustomers();
    getMachines();
    getFabrics();
    getRecipes();
  }, []);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={confirmationModal}
        setOpen={setConfirmationModal}
        title={"Code has been used."}
        message={`Production code of ${productionCode} has already been used. Continue with this code?`}
        actionTitle={"continue"}
        action={() => onProceed()}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 840, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Create Production Data
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Stack
                  item
                  width={"50%"}
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"}>
                    <CustomTextField
                      name="production_code"
                      label="Production code"
                      placeholder="Input a production code"
                      onChange={(e) => setProductionCode(e.target.value)}
                    />
                  </Stack>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "6px",
                      backgroundColor: "#222",
                      height: "40px",
                      color: "#FFF",
                      bottom: 0,
                      marginTop: "28px",
                      ":hover": {
                        backgroundColor: "#444",
                        color: "#FFF",
                      },
                    }}
                    variant="contained"
                    onClick={() => handleCheck()}
                    disabled={productionCode === "" && true}
                  >
                    Check
                  </Button>
                </Stack>
                <Grid item width={"50%"}>
                  <Stack>
                    <CustomTextField
                      id="date"
                      type="date"
                      label={"Production date"}
                      onChange={(e) => selectDate(e.target.value)}
                      placeholder="Input the job order date"
                      InputProps={{
                        inputProps: { min: "2023-12-01", max: "2050-12-30" },
                      }}
                      disabled={checked ? false : true}
                    />
                  </Stack>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="fabric"
                    label="Fabric code"
                    placeholder="Choose a fabric"
                    select
                    value={fabric}
                    onChange={(e) => setFabric(e.target.value)}
                    disabled={checked ? false : true}
                  >
                    <MenuItem value={"Choose a fabric"}>
                      Choose a fabric
                    </MenuItem>
                    {fabrics.map((fb) =>
                      fb.status ? (
                        <MenuItem
                          key={fb._id}
                          value={fb.fabric_code}
                          onClick={() => onFabric(fb)}
                        >
                          {fb.fabric_code ? fb.fabric_code : "-"}
                        </MenuItem>
                      ) : null,
                    )}
                  </CustomTextField>
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="fabric_weight"
                    label="Fabric weight (kg)"
                    type="number"
                    placeholder="Input the weight of the fabric"
                    onChange={(e) => {
                      setWeight(e.target.value);
                      setRoll(Math.ceil(e.target.value / 25));
                    }}
                    disabled={checked ? false : true}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={recipes?.map(
                      (r) =>
                        `${r.code_recipe} - ${r.color} - ${r.process?.process_name}`,
                    )}
                    onChange={(event, newValue) => {
                      recipes.filter((rc) =>
                        `${rc.code_recipe} - ${rc.color} - ${rc.process?.process_name}` ===
                        newValue
                          ? onRecipe(rc)
                          : null,
                      );
                    }}
                    disabled={checked ? false : true}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Choose a recipe code"
                        label="Recipe code"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="color"
                    label="Color"
                    placeholder="The color will appear here."
                    disabled
                    value={
                      recipe !== null
                        ? recipe.color
                        : "Please select a recipe code first"
                    }
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="scouring_vlot"
                    label="Scouring vlot"
                    placeholder="Input the scouring vlot"
                    onChange={(e) => setScouringVlot(e.target.value)}
                    disabled={checked ? false : true}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="dyeing_vlot"
                    label="Dyeing vlot"
                    placeholder="Input the dyeing vlot"
                    onChange={(e) => setDyeingVlot(e.target.value)}
                    disabled={checked ? false : true}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={customers?.map((ctm) =>
                      ctm.name !== "" ? `${ctm.name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      customers.filter((ct) =>
                        `${ct.name}` === newValue ? onCustomer(ct) : null,
                      );
                    }}
                    disabled={checked ? false : true}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Choose a customer"
                        label="Customer"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="roll"
                    label="Roll"
                    placeholder="Input the fabric roll"
                    value={roll}
                    disabled
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="purchase_order"
                    label="Purchase order"
                    placeholder="Input the purchase order"
                    onChange={(e) => setPurchaseOrder(e.target.value)}
                    disabled={checked ? false : true}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <Autocomplete
                    options={machines?.map((mc) =>
                      mc.name !== "" ? `${mc.name}` : "-",
                    )}
                    onChange={(event, newValue) => {
                      machines.filter((mc) =>
                        `${mc.name}` === newValue ? onMachine(mc) : null,
                      );
                    }}
                    disabled={checked ? false : true}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Choose a machine"
                        label="Machine"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"100%"}>
                  <CustomTextField
                    name="remarks"
                    label="Remarks"
                    placeholder="Input remarks here"
                    multiline
                    rows={3}
                    onChange={(e) => setRemarks(e.target.value)}
                    disabled={checked ? false : true}
                  />
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                type="submit"
                variant="contained"
                onClick={() => createProduction()}
                disabled={
                  recipeId === "" ||
                  customerId === "" ||
                  fabricId === "" ||
                  machineId === "" ||
                  scouringVlot === "" ||
                  dyeingVlot === "" ||
                  purchaseOrder === "" ||
                  productionCode === "" ||
                  productionDate === ""
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default CreateProduction;
