import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import toast, { Toaster } from "react-hot-toast";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";

const adminCategory = [
  {
    value: "SuperAdmin",
    label: "Super Admin",
  },
  {
    value: "Manager",
    label: "Manager",
  },
  {
    value: "Admin Product",
    label: "Production Admin",
  },
  {
    value: "Admin Warehouse",
    label: "Warehouse Admin",
  },
];

const manager = [
  {
    value: "Admin Product",
    label: "Production Admin",
  },
  {
    value: "Admin Warehouse",
    label: "Warehouse Admin",
  },
];

const CreateAdmin = ({ open, setModalOpen, getAdmin }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // const [role, setRole] = useState(
  //   user.role === "SuperAdmin" ? "SuperAdmin" : "Admin Product",
  // );
  const [role, setRole] = useState("Choose a role");

  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);

  const handleClose = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPhone("");
    setRole("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      full_name: name,
      email: email,
      phone_number: phone,
      password: password,
      role: role,
      status: true,
    };

    axiosInstance
      .post("/api/admins/register", data, authentication)
      .then(() => {
        // console.log(res);
        succesEffect.play();
        getAdmin();
        setModalOpen(false);
        toast.success("New admin has been created!");
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Create Admin
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="name"
                    label="Name"
                    placeholder="Input a full name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="role"
                    label="Role"
                    placeholder="Please select an admin type"
                    select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value={"Choose a role"}>Choose a role</MenuItem>
                    {user.role !== "SuperAdmin"
                      ? manager.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      : adminCategory.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                  </CustomTextField>
                </Grid>
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="email"
                    label="Email"
                    placeholder="Input an email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item width={"50%"}>
                  <CustomTextField
                    name="phone_number"
                    label="Phone"
                    placeholder="Input a phone number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Input password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#222",
                  color: "#FFF",
                  borderRadius: "6px",
                  ":hover": {
                    backgroundColor: "#444",
                  },
                }}
                type="submit"
                variant="contained"
                disabled={
                  name === "" || email === "" || password === "" || phone === ""
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default CreateAdmin;
