import React from "react";
import "./loader.css";

const InternalLoader = () => {
  return (
    <div
      //   className="middle"
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "auto",
        height: "auto",
        display: "grid",
        gap: "2",
        textAlign: "center",
        fontSize: "12px",
        padding: "30px",
      }}
    >
      <div>
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        <div className="bar bar4"></div>
      </div>
      <p>Uploading file. Please wait...</p>

      {/* <div className="bar bar5"></div>
      <div className="bar bar6"></div>
      <div className="bar bar7"></div>
      <div className="bar bar8"></div>
      <div className="bar bar1"></div>
      <div className="bar bar2"></div>
      <div className="bar bar3"></div>
      <div className="bar bar4"></div>
      <div className="bar bar5"></div>
      <div className="bar bar6"></div>
      <div className="bar bar7"></div> */}
      {/* <div className="bar bar8"></div> */}
    </div>
  );
};

export default InternalLoader;
