import React, { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FactoryIcon from "@mui/icons-material/Factory";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ScienceIcon from "@mui/icons-material/Science";
import MemoryIcon from "@mui/icons-material/Memory";
import CameraRollIcon from "@mui/icons-material/CameraRoll";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import Receipt from "@mui/icons-material/Receipt";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InventoryIcon from "@mui/icons-material/Inventory";

const superAdmin = [
  {
    sectionTitle: "ADMIN MANAGEMENT",
    items: [
      {
        icon: <AdminPanelSettingsIcon />,
        label: "Admin Management",
        navDestination: "/users",
      },
    ],
  },
  {
    sectionTitle: "MASTER DATA",
    items: [
      {
        icon: <Diversity1Icon />,
        label: "Customers",
        navDestination: "/master/customers",
      },
      {
        icon: <InventoryIcon />,
        label: "Suppliers",
        navDestination: "/master/suppliers",
      },
      {
        icon: <ScienceIcon />,
        label: "Chemicals",
        navDestination: "/master/chemicals",
      },
      {
        icon: <MemoryIcon />,
        label: "Processes",
        navDestination: "/master/processes",
      },
      {
        icon: <CameraRollIcon />,
        label: "Fabrics",
        navDestination: "/master/fabrics",
      },
      {
        icon: <PrecisionManufacturingIcon />,
        label: "Machines",
        navDestination: "/master/machines",
      },
    ],
  },
  {
    sectionTitle: "RECIPE",
    items: [
      {
        icon: <Receipt />,
        label: "Recipe Codes",
        navDestination: "/recipes/list",
      },
    ],
  },
  {
    sectionTitle: "PRODUCTION",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Production List",
        navDestination: "/productions/list",
      },
      {
        icon: <SummarizeIcon />,
        label: "Production Report",
        navDestination: "/productions/report",
      },
    ],
  },
  {
    sectionTitle: "CHEMICAL WAREHOUSE",
    items: [
      {
        icon: <LoginIcon />,
        label: "Inbounds",
        navDestination: "/warehouse/inbound",
      },
      {
        icon: <LogoutIcon />,
        label: "Outbounds",
        navDestination: "/warehouse/outbond",
      },
      {
        icon: <SummarizeIcon />,
        label: "Stock Report",
        navDestination: "/warehouse/report",
      },
    ],
  },
];

const manager = [
  {
    sectionTitle: "ADMIN MANAGEMENT",
    items: [
      {
        icon: <AdminPanelSettingsIcon />,
        label: "Admin Management",
        navDestination: "/users",
      },
    ],
  },
  {
    sectionTitle: "MASTER DATA",
    items: [
      {
        icon: <Diversity1Icon />,
        label: "Customers",
        navDestination: "/master/customers",
      },
      {
        icon: <InventoryIcon />,
        label: "Suppliers",
        navDestination: "/master/suppliers",
      },
      {
        icon: <ScienceIcon />,
        label: "Chemicals",
        navDestination: "/master/chemicals",
      },
      {
        icon: <MemoryIcon />,
        label: "Processes",
        navDestination: "/master/processes",
      },
      {
        icon: <CameraRollIcon />,
        label: "Fabrics",
        navDestination: "/master/fabrics",
      },
      {
        icon: <PrecisionManufacturingIcon />,
        label: "Machines",
        navDestination: "/master/machines",
      },
    ],
  },
  {
    sectionTitle: "RECIPE",
    items: [
      {
        icon: <Receipt />,
        label: "Recipe Codes",
        navDestination: "/recipes/list",
      },
    ],
  },
  {
    sectionTitle: "PRODUCTION",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Production List",
        navDestination: "/productions/list",
      },
      {
        icon: <SummarizeIcon />,
        label: "Production Report",
        navDestination: "/productions/report",
      },
    ],
  },
  {
    sectionTitle: "CHEMICAL WAREHOUSE",
    items: [
      {
        icon: <LoginIcon />,
        label: "Inbounds",
        navDestination: "/warehouse/inbound",
      },
      {
        icon: <LogoutIcon />,
        label: "Outbounds",
        navDestination: "/warehouse/outbond",
      },
      {
        icon: <SummarizeIcon />,
        label: "Stock Report",
        navDestination: "/warehouse/report",
      },
    ],
  },
];

const productionAdmin = [
  {
    sectionTitle: "MASTER DATA",
    items: [
      {
        icon: <Diversity1Icon />,
        label: "Customers",
        navDestination: "/master/customers",
      },
      {
        icon: <InventoryIcon />,
        label: "Suppliers",
        navDestination: "/master/suppliers",
      },
      {
        icon: <ScienceIcon />,
        label: "Chemicals",
        navDestination: "/master/chemicals",
      },
      {
        icon: <MemoryIcon />,
        label: "Processes",
        navDestination: "/master/processes",
      },
      {
        icon: <CameraRollIcon />,
        label: "Fabrics",
        navDestination: "/master/fabrics",
      },
      {
        icon: <PrecisionManufacturingIcon />,
        label: "Machines",
        navDestination: "/master/machines",
      },
    ],
  },
  {
    sectionTitle: "RECIPE",
    items: [
      {
        icon: <Receipt />,
        label: "Recipe Codes",
        navDestination: "/recipes/list",
      },
    ],
  },
  {
    sectionTitle: "PRODUCTION",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Production List",
        navDestination: "/productions/list",
      },
      {
        icon: <SummarizeIcon />,
        label: "Production Report",
        navDestination: "/productions/report",
      },
    ],
  },
];

const adminWarehouse = [
  {
    sectionTitle: "MASTER DATA",
    items: [
      {
        icon: <InventoryIcon />,
        label: "Suppliers",
        navDestination: "/master/suppliers",
      },
      {
        icon: <ScienceIcon />,
        label: "Chemicals",
        navDestination: "/master/chemicals",
      },
    ],
  },
  {
    sectionTitle: "PRODUCTION",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Production List",
        navDestination: "/productions/list",
      },
      // {
      //   icon: <SummarizeIcon />,
      //   label: "Production Report",
      //   navDestination: "/productions/report",
      // },
    ],
  },
  {
    sectionTitle: "CHEMICAL WAREHOUSE",
    items: [
      {
        icon: <LoginIcon />,
        label: "Inbounds",
        navDestination: "/warehouse/inbound",
      },
      {
        icon: <LogoutIcon />,
        label: "Outbounds",
        navDestination: "/warehouse/outbond",
      },
      {
        icon: <SummarizeIcon />,
        label: "Stock Report",
        navDestination: "/warehouse/report",
      },
    ],
  },
];

const SidebarSectionTitle = ({ children }) => (
  <Typography
    fontSize="14px"
    fontWeight="600"
    color="#222121"
    marginLeft="16px"
    marginY={".5rem"}
  >
    {children}
  </Typography>
);

const setJalan = (navigate, navDestination, setActive, label) => {
  navigate(navDestination);
  setActive(label);
};

const SidebarSectionItem = ({
  icon,
  label,
  navDestination,
  navigate,
  active,
  setActive,
}) => (
  <Stack
    onClick={() => setJalan(navigate, navDestination, setActive, label)}
    direction="row"
    alignItems="center"
    spacing={1}
    paddingY=".5rem"
    paddingLeft="16px"
    color={active === label ? "#FFF" : "#535252"}
    borderRadius={1}
    marginY={".3rem"}
    sx={{
      ":hover": {
        backgroundColor: "#222121",
        color: "#FFF",
        cursor: "pointer",
      },
      backgroundColor: active === label ? "#222121" : "",
    }}
  >
    {icon}
    <Typography>{label}</Typography>
  </Stack>
);

const Sidebar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isExpand, setIsExpand] = useState(true);

  return (
    <Box
      width={isExpand ? "220px" : "55px"}
      borderRight="1px solid #f9f9f9"
      overflow="scroll"
      paddingTop={10}
      paddingX={1}
      sx={{
        backgroundColor: "#FFF",
        // borderBottomRightRadius: "10px",
        // height: user.role === "SuperAdmin" ? "auto" : "100vh",
        height: "90vh",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isExpand === true && "end"}
        spacing={1}
        // paddingTop=".5rem"
        paddingX="16px"
        borderRadius={1}
        marginTop={"1px"}
      >
        {isExpand ? (
          <>
            {/* <Tooltip title={"Minimize"}> */}
            <KeyboardDoubleArrowLeftIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setIsExpand(!isExpand)}
            />
            {/* </Tooltip> */}
          </>
        ) : (
          // <Tooltip title={"Expand"}>
          <KeyboardDoubleArrowRightIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setIsExpand(!isExpand)}
          />
          // </Tooltip>
        )}
      </Stack>
      {user.role === "SuperAdmin"
        ? superAdmin.map((section, i) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={i}
              >
                {isExpand === true && (
                  <SidebarSectionTitle key={section.sectionTitle}>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={isExpand ? item.icon : null}
                      label={isExpand === true ? item.label : item.icon}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                    />
                  );
                })}
              </Stack>
            );
          })
        : user.role === "Manager"
        ? manager.map((section, i) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={i}
              >
                {isExpand === true && (
                  <SidebarSectionTitle key={section.sectionTitle}>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={isExpand ? item.icon : null}
                      label={isExpand === true ? item.label : item.icon}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                    />
                  );
                })}
              </Stack>
            );
          })
        : user.role === "Admin Warehouse"
        ? adminWarehouse.map((section, i) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={i}
              >
                {isExpand === true && (
                  <SidebarSectionTitle key={section.sectionTitle}>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={isExpand ? item.icon : null}
                      label={isExpand === true ? item.label : item.icon}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                    />
                  );
                })}
              </Stack>
            );
          })
        : productionAdmin.map((section, i) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={i}
              >
                {isExpand === true && (
                  <SidebarSectionTitle key={section.sectionTitle}>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={isExpand ? item.icon : null}
                      label={isExpand === true ? item.label : item.icon}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                    />
                  );
                })}
              </Stack>
            );
          })}
    </Box>
  );
};

export default Sidebar;
