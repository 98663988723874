import React from "react";
import RecipeList from "pages/Recipes";
import AddRecipe from "pages/Recipes/_components/AddRecipe";
import RecipeDetails from "pages/Recipes/_components/RecipeDetails";
import EditRecipe from "pages/Recipes/_components/EditRecipe";

const root = "/recipes";

const recipes = [
  {
    path: `${root}/list`,
    component: <RecipeList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/list/detail/:id`,
    component: <RecipeDetails />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/add-recipe`,
    component: <AddRecipe />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/edit-recipe/:id`,
    component: <EditRecipe />,
    sidebar: true,
    navbar: true,
  },
];

export default recipes;
