import React, {
  useState,
  // useEffect
} from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTextField from "components/TextField";
import StatusSwitch from "components/Switch";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../utils/api/api";
import succesSound from "../../../assets/audio/success.mp3";
import errorSound from "../../../assets/audio/error.mp3";
import ConfirmationModal from "components/ConfirmationModal";

const FabricDetails = ({ open, setModalOpen, data, getFabrics }) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isEdit, setIsEdit] = useState(false);
  const [knittingType, setKnittingType] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [thickness, setThickness] = useState("");
  const [gramation, setGramation] = useState("");
  const [width, setWidth] = useState("");
  // const [openModal, setOpenModal] = useState(false);
  const [fabricCode, setFabricCode] = useState("");
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const succesEffect = new Audio(succesSound);
  const errorEffect = new Audio(errorSound);
  const [isConfirm, setIsConfirm] = useState(false);
  // console.log(data);

  const handleStatus = () => {
    const edited = {
      gramation: data.gramation,
      knit: data.knit,
      name: data.name,
      thickness: data.thickness,
      type: data.type,
      width: data.width,
      status: !data?.status,
      fabric_code: data.fabric_code,
    };

    axiosInstance
      .put(`/api/fabrics/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Fabric status updated!");
        getFabrics();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleSubmit = () => {
    const edited = {
      gramation: gramation !== "" ? gramation : data.gramation,
      knit: knittingType !== "" ? knittingType : data.knit,
      name: name !== "" ? name : data.name,
      thickness: thickness !== "" ? thickness : data.thickness,
      type: type !== "" ? type : data.type,
      width: width !== "" ? width : data.width,
      fabric_code: fabricCode !== "" ? fabricCode : data.fabric_code,
      status: data?.status,
    };

    axiosInstance
      .put(`/api/fabrics/${data._id}`, edited, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Fabric data has been updated!");
        getFabrics();
        setModalOpen(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const onSend = () => {
    handleSubmit();
    setIsEdit(false);
  };

  const onBack = () => {
    setIsEdit(false);
    setModalOpen(false);
  };

  const handleDelete = async () => {
    await axiosInstance
      .delete(`/api/fabrics/${data._id}`, authentication)
      .then(() => {
        // console.log(res.data);
        succesEffect.play();
        toast.success("Fabric has been deleted!");
        getFabrics();
        setModalOpen(false);
        setIsConfirm(false);
      })
      .catch((error) => {
        errorEffect.play();
        console.log(error);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ConfirmationModal
        open={isConfirm}
        setOpen={setIsConfirm}
        title={"Delete?"}
        action={handleDelete}
        actionTitle={"Delete"}
        message={"This fabric data is about to be deleted. Are you sure?"}
        isWarning={true}
      />
      <ModalCustom
        open={open}
        handleClose={() => setModalOpen(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Fabric Details
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {/* Body */}
        {/* <form onSubmit={handleSubmit}> */}
        <Box flex="1">
          <Stack paddingX={2} paddingY={2}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={isEdit ? "100%" : "50%"}>
                    <CustomTextField
                      name="knitwork"
                      label="Knitting"
                      placeholder={data?.knit}
                      onChange={(e) => setKnittingType(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Knitting
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.knit}
                    </Typography>
                  </Grid>
                )}

                {user.role !== "Admin Product" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
                {user.role !== "SuperAdmin" || isEdit ? null : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Status
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <StatusSwitch
                        status={data?.status}
                        changeStatus={() => handleStatus()}
                      />
                      {data?.status ? (
                        <button
                          style={{
                            color: "#21573D",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #B8DBCA",
                            borderRadius: "5px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#222",
                            fontFamily: "Murecho",
                            fontWeight: "600",
                            fontSize: "12px",
                            border: "1px solid #222",
                            borderRadius: "5px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          Non-active
                        </button>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="name"
                      label="Name"
                      placeholder={data?.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Name
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.name}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="fabric_type"
                      label="Type"
                      placeholder={data?.type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Type
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.type}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="thickness"
                      label="Thickness"
                      placeholder={data?.thickness}
                      onChange={(e) => setThickness(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Thickness
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.thickness}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="gramation"
                      label="Gramation"
                      placeholder={data?.gramation}
                      onChange={(e) => setGramation(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Gramation
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.gramation !== "" ? data?.gramation : "-"}
                    </Typography>
                  </Grid>
                )}
              </Stack>
              <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="fabric_code"
                      label="Fabric Code"
                      placeholder={data?.fabric_code}
                      onChange={(e) => setFabricCode(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Fabric code
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.fabric_code !== "" ? data?.fabric_code : "-"}
                    </Typography>
                  </Grid>
                )}
                {isEdit ? (
                  <Grid item width={"50%"}>
                    <CustomTextField
                      name="width"
                      label="Width (inch)"
                      placeholder={data?.width}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </Grid>
                ) : (
                  <Grid item width={"50%"}>
                    <Typography fontSize={12} color={"#444"} fontWeight={500}>
                      Width (inch)
                    </Typography>
                    <Typography fontSize={16} color={"#222"} fontWeight={500}>
                      {data?.width !== "" ? data?.width : "-"}
                    </Typography>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: "#f6f6f6" }}>
          <Box
            sx={{
              px: 3,
              py: 2,
            }}
          >
            <Stack direction="row" justifyContent="right" gap={2}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                  color: "#222",
                  border: "1px solid #222",
                  ":hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #222",
                  },
                }}
                variant="outlined"
                onClick={() => onBack()}
              >
                Back
              </Button>
              {user.role === "Admin Product" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#222",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => (isEdit ? onSend() : setIsEdit(!isEdit))}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
              )}
              {user.role === "SuperAdmin" && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#CC3A30",
                    color: "#FFF",
                    borderRadius: "6px",
                    ":hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => setIsConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        {/* </form> */}
      </ModalCustom>
    </>
  );
};

export default FabricDetails;
